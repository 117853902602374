import { addToast } from "components/common/HIHHToast";
import { isString } from "utils";

type HandleError = (err: any, errorMsg?: string) => void;

const getErrorMessage = (response: any) => {
  if (response?.data?.errors) return response?.data?.errors;

  if (response?.data?.error_message) return response?.data?.error_message;

  if (response?.data?.message) return response?.data?.message;

  if (isString(response?.data?.error?.message)) {
    return response?.data?.error?.message;
  }

  if (isString(response?.data?.error)) return response?.data?.error;

  return "An error ocurred, please try again later";
};

export const handleSuccess = (msg: string) => {
  addToast(msg, "success");
};

export const handleErrorMessage = (msg: string) => {
  addToast(msg, "error");
};

export const handleError: HandleError = (err, errMsg = undefined) => {
  // eslint-disable-next-line no-console
  if (
    (typeof err === "string" && err === "popup_closed_by_user") ||
    (err?.error && err?.error === "popup_closed_by_user")
  )
    return;

  let msg = "Connection Error";

  if (errMsg) msg = errMsg;
  else if (err?.response) {
    switch (err.response.status) {
      case 500:
        msg = "An error has occurred on our server. Please try again later";
        break;
      default:
        msg = getErrorMessage(err.response);
        break;
    }
  }
  addToast(msg, "error");
};
