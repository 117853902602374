import { Typography, Chip, Button } from "@mui/material";
import React, { useCallback } from "react";
import styled from "styled-components";
import colors from "styles/colors";
import { VirtualCareSquad } from "./useVirtualCareSquads";

type Props = {
  item: VirtualCareSquad;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px;
  gap: 24px;
  box-sizing: border-box;
  width: 350px;
  height: auto;

  background: ${colors.WHITE};

  border-top: 8px solid ${colors.PURPLE[5]};
  box-shadow: 0px 0px 12px -4px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  z-index: 1;

  @media all and (min-width: 1200px) {
    width: 384px;
    height: 549px;
  }
`;

const Header = styled.div`
  width: 286px;

  :after {
    content: " ";
    display: block;
    width: 320px;
    height: 1px;
    background: ${colors.GREY[1]};
    margin-top: 16px;
  }

  @media all and (min-width: 1200px) {
    width: 320px;
  }
`;

const Tag = styled(Chip)`
  background-color: ${colors.NUDE[1]} !important;
  color: ${colors.NUDE[8]} !important;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
`;

const HeaderCategories = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 0px;
  margin-top: 16px;
  width: 320px;
  gap: 8px 0;

  @media all and (min-width: 1200px) {
    flex-direction: row;
  }

  ${Tag} {
    margin-right: 8px;
  }
`;

const StyledButton = styled(Button)`
  margin-top: auto !important;
  width: 286px;

  @media all and (min-width: 1200px) {
    width: auto;
    height: 549px;
  }
`;

const StyledSponsorImg = styled.img`
  width: 286px;
  height: 96px;

  @media all and (min-width: 1200px) {
    width: 320px;
  }
`;

const VCSCard = ({ item }: Props) => {
  const handleSignUp = useCallback(() => {
    window.open(item.form_link, "_blank");
  }, []);

  return (
    <Container>
      <Header>
        <Typography variant="mk_h4">{item.title}</Typography>
        <HeaderCategories>
          {item.categories?.map((category) => (
            <Tag key={category.id} label={category.label} />
          ))}
        </HeaderCategories>
      </Header>
      <Typography
        variant="mk_caption"
        color={colors.GREY[6]}
        style={{ lineHeight: "160%" }}
      >
        {item.description}
      </Typography>
      {item.sponsor_image_url && (
        <StyledSponsorImg src={item.sponsor_image_url} />
      )}
      <StyledButton onClick={handleSignUp} variant="contained">
        {item.is_active ? "Sign Up" : "Join the Waitlist"}
      </StyledButton>
    </Container>
  );
};

export default VCSCard;
