import React from "react";

import { UserIcon } from "components/common/icons";
import { yupEmail } from "components/Form/validations";

const forgotPasswordFields = [
  {
    label: "Email",
    name: "email",
    initialValue: "",
    validation: yupEmail,
    icon: {
      StartIcon: (props) => <UserIcon {...props} />,
    },
  },
];

export default forgotPasswordFields;
