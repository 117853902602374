import React, { createContext, useContext, useEffect, useState } from "react";

import api from "api";
import { addToast } from "components/common/HIHHToast";
import { useUserContext } from "components/UserContextProvider";
import { triggerUserChangedSubscription } from "utils/analytics";
import { PlanTypes, SubscriptionTypes } from "./types";

export type Coupon = {
  id: number;
  amount_off_cents: number;
  code: string;
  created_at: string;
  percent_off?: number;
};

type State = {
  coupon: Coupon | null;
  currentSubscription: null | SubscriptionTypes;
  currentVCSSubscription: null;
  currentVCSSubscriptions: [] | SubscriptionTypes[];
  isCancelModalOpen: boolean;
  isLoading: boolean;
  paymentMethods: any;
  plans: PlanTypes[];
  getCoupon: (couponCode: string) => void;
  closeCancelationModal: () => void;
  createSubscription: (priceId: number, couponId?: string) => void;
  createVCSSubscription: (virtualCareSquadId, priceId: number) => void;
  getCurrentVCSSubscriptions: () => void;
  openCancelationModal: () => void;
  refreshCurrentSubscription: () => void;
  refreshPaymentMethods: () => void;
  removeCoupon: () => void;
  setCurrentVCSSubscription: (value) => void;
  startProcessLoading: () => void;
  stopProcessLoading: () => void;
  updateSubscriptionStatus: (newStatus: "cancel" | "renew") => void;
};

const SubscriptionContext = createContext<State>({} as State);

export const SubscriptionContextProvider: React.FC = ({ children }) => {
  const [currentSubscription, setCurrentSubscription] =
    useState<SubscriptionTypes | null>(null);
  const [currentVCSSubscription, setCurrentVCSSubscription] = useState(null);
  const [currentVCSSubscriptions, setCurrentVCSSubscriptions] = useState<
    SubscriptionTypes[] | []
  >([]);
  const [coupon, setCoupon] = useState(null);

  const [paymentMethods, setPaymentMethods] = useState<any>(null);
  const [plans, setPlans] = useState<any>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);

  const { user } = useUserContext();

  const getPaymentMethods = async () => {
    setIsLoading(true);
    const {
      data: { data: paymentMethodsData },
    } = await api.get("/users/payment_methods");
    setPaymentMethods(paymentMethodsData);
    setIsLoading(false);
  };

  const getCoupon = async (couponCode: string) => {
    try {
      const { data } = await api.get(`/coupons/${couponCode}`);
      addToast("Promo code successful", "success");
      setCoupon(data);
    } catch (error: any) {
      addToast("The access code you entered is not valid.", "error");
    }
  };

  const removeCoupon = () => {
    setCoupon(null);
    addToast("Coupon removed!", "success");
  };

  const getCurrentSubscription = async (
    triggerEvent?: boolean,
    event: string = ""
  ) => {
    setIsLoading(true);
    const {
      data: { data },
    } = await api.get("/users/subscriptions");
    setCurrentSubscription({
      ...data,
      isFreePlan: data.current_plan.name.toLowerCase() === "free",
    });
    if (triggerEvent && user) {
      triggerUserChangedSubscription(
        user,
        event,
        data.current_price.recurring_interval
      );
    }
    setIsLoading(false);
  };

  const getCurrentVCSSubscriptions = async () => {
    setIsLoading(true);
    const {
      data: { data },
    } = await api.get("/users/virtual_care_squad_subscriptions");
    setCurrentVCSSubscriptions(data);

    setIsLoading(false);
  };

  const getCurrentVCSSubscription = async (virtualCareSquadId) => {
    setIsLoading(true);
    const {
      data: { data },
    } = await api.get(
      `/users/virtual_care_squad_subscriptions/${virtualCareSquadId}`
    );
    setCurrentVCSSubscription({ ...data });
    setIsLoading(false);
  };

  const getPlans = async () => {
    setIsLoading(true);
    const {
      data: { data: plansData },
    } = await api.get("/plans");
    setPlans(plansData);
  };

  useEffect(() => {
    getPlans();
    getPaymentMethods();
    getCurrentSubscription();
  }, []);

  const updateSubscriptionStatus = async (newStatus: string) => {
    if (!currentSubscription) return;

    setIsLoading(true);

    if (newStatus === "renew") {
      if (currentSubscription.current_price.id) {
        await api.put(`/users/subscriptions/${currentSubscription.id}`, {
          user_subscription: {
            renew: true,
            price_id: currentSubscription.current_price.id,
          },
        });
      } else {
        addToast(
          "Could not fetch price id for the desired plan, please contact support at support@healthinherhue.com",
          "error"
        );
      }
    } else if (newStatus === "cancel") {
      await api.put(`/users/subscriptions/${currentSubscription.id}`, {
        user_subscription: { cancel: true },
      });
    }

    const triggerEvent = true;
    await getCurrentSubscription(triggerEvent, newStatus);
    addToast("Subscription Updated", "success");
  };

  const createSubscription = async (priceId, couponId) => {
    setIsLoading(true);

    let payload: any = { user_subscription: { price_id: priceId } };

    if (couponId) {
      payload = {
        user_subscription: { price_id: priceId, coupon_code: couponId },
      };
    }

    await api.post("/users/subscriptions", payload);

    const triggerEvent = true;
    await getCurrentSubscription(triggerEvent, "subscribe");
    addToast("Subscription Upgraded to Premium", "success");
  };

  const createVCSSubscription = async (virtualCareSquadId, priceId) => {
    setIsLoading(true);
    await api.post("/users/subscriptions", {
      user_subscription: { price_id: priceId },
    });
    await getCurrentVCSSubscription(virtualCareSquadId);
    addToast("Subscription to Virtual Care Squad successful", "success");
  };

  const startProcessLoading = () => {
    setIsLoading(true);
  };
  const stopProcessLoading = () => {
    setIsLoading(false);
  };

  const openCancelationModal = () => {
    setIsCancelModalOpen(true);
  };

  const closeCancelationModal = () => {
    setIsCancelModalOpen(false);
  };

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value = {
    coupon,
    currentSubscription,
    currentVCSSubscription,
    currentVCSSubscriptions,
    isCancelModalOpen,
    isLoading,
    paymentMethods,
    plans,
    closeCancelationModal,
    createSubscription,
    createVCSSubscription,
    getCoupon,
    getCurrentVCSSubscriptions,
    openCancelationModal,
    refreshCurrentSubscription: getCurrentSubscription,
    refreshPaymentMethods: getPaymentMethods,
    removeCoupon,
    setCurrentVCSSubscription,
    startProcessLoading,
    stopProcessLoading,
    updateSubscriptionStatus,
  };

  return (
    <SubscriptionContext.Provider value={value}>
      {children}
    </SubscriptionContext.Provider>
  );
};

export const useSubscriptionContext = () => {
  const context = useContext(SubscriptionContext);
  if (typeof context === "undefined") {
    throw new Error(
      "useSubscription must be used within a SubscriptionContext"
    );
  }
  return context;
};
