import moment from "moment";
import * as yup from "yup";

const passwordMatchesMessage =
  "Must contain 8 characters, one uppercase, one lowercase, one number and one special character. (!, @, #, $, %, ^, &, *)";

const LettersAndNumbersRegex = /^[a-zA-Z0-9]*$/;

export const yupName = yup
  .string()
  .nullable()
  .test(
    "letters-and-numbers",
    "This name type is not allowed",
    (value) => !value || LettersAndNumbersRegex.test(value)
  )

export const yupRequiredDate = yup
  .date()
  .test("is-valid-date", "non valid date", (value) =>
    moment(value, "MM/DD/YYYY", true).isValid()
  )
  .typeError("Invalid Date");

export const yupGoogleAutocompleteRequired = yup
  .object()
  .shape({
    display_name: yup.string().required("This is a required field"),
    name: yup.string().nullable(),
    city: yup.string().nullable(),
    state: yup.string().nullable(),
    country: yup.string().required("Please search and select an option"),
    zip_code: yup.string().nullable(),
    lonlat: yup.string(),
  })
  .nullable()
  .required("This is a required field");

export const yupConditionedGoogleAutocompleteRequired = (
  conditionField: string
) =>
  yup
    .object()
    .shape({
      display_name: yup.string(),
      name: yup.string().nullable(),
      city: yup.string(),
      state: yup.string(),
      country: yup.string(),
      zip_code: yup.string().nullable(),
      lonlat: yup.string(),
    })
    .nullable()
    .when(conditionField, {
      is: true,
      then: (schema) => schema.required("This is a required field"),
    });

export const yupGoogleAutocomplete = yup
  .object()
  .shape({
    display_name: yup.string(),
    city: yup.string(),
    zip_code: yup.string(),
    state: yup.string(),
    country: yup.string().when("display_name", {
      is: (display_name) => typeof display_name === "string",
      then: yup.string().required("Please enter and select an address."),
      otherwise: yup.string(),
    }),
    lonlat: yup.string().when("display_name", {
      is: (display_name) => typeof display_name === "string",
      then: yup.string().required("Please enter and select an address."),
      otherwise: yup.string(),
    }),
    name: yup.string().when("display_name", {
      is: (display_name) => typeof display_name === "string",
      then: yup.string().required("Please enter and select an address."),
      otherwise: yup.string(),
    }),
  })
  .nullable();

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const yupPhoneRequired = yup
  .string()
  .nullable()
  .trim()
  .matches(phoneRegExp, "Phone number is not valid")
  .max(10, "Phone number is too long")
  .required("Phone number is required");

export const yupPhoneToggleRequired = yup
  .string()
  .nullable()
  .trim()
  .matches(phoneRegExp, "Phone number is not valid")
  .max(10, "Phone number is too long")
  .when("accept_new_patients", {
    is: true,
    then: (schema) => schema.required("Phone number is required"),
  });

export const yupPhoneNullable = yup
  .string()
  .nullable()
  .trim()
  .matches(phoneRegExp, "Phone number is not valid")
  .max(10, "Phone number is too long");

export const yupRequired = yup
  .string()
  .nullable()
  .trim()
  .required("This is a required field");

export const yupConditionedRequired = (conditionField: string) =>
  yup
    .string()
    .nullable()
    .when(conditionField, {
      is: true,
      then: (schema) => schema.required("This is a required field"),
    });

export const yupBooleanRequired = yup
  .boolean()
  .oneOf([true], "This is a required field");

export const yupRequiredObject = yup
  .object()
  .shape({
    id: yup.number(),
    label: yup.string(),
    value: yup.string(),
    created_at: yup.string(),
    updated_at: yup.string(),
  })
  .required()
  .typeError("Please select an option.");

export const yupRequiredArrayOfObjects = yup
  .array()
  .of(
    yup
      .object()
      .shape({ id: yup.number(), label: yup.string(), value: yup.string() })
      .nullable()
  )
  .required()
  .min(1, "Please select at least one option.");

export const requiredString = (fieldLabel = "") =>
  yup.string().nullable().required().label(fieldLabel);

export const yupEmail = yup
  .string()
  .email("Invalid email")
  .required("Email is required");

export const yupEmailWithTrailingWhitespace = yup
  .string()
  .trim()
  .email("Invalid email")
  .required("Email is required");

export const yupEmailNullable = yup.string().nullable().email("Invalid email");

export const yupCompanyName = yup
  .string()
  .min(2, "Company name should have at least 2 characters")
  .required("Company name is required");

// TODO: More secure password validations
export const yupPassword = yup
  .string()
  .matches(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
    passwordMatchesMessage
  )
  .required(passwordMatchesMessage);

export const yupPasswordConfirmation = yup
  .string()
  .oneOf([yup.ref("password")], "Passwords do not match")
  .required("Password confirmation is required");

const URL_REGEX =
  /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[A-Za-z0-9]+([-.]{1}[A-Za-z0-9]+)*\.[A-Za-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;

export const yupWebsite = requiredString("Website").matches(
  URL_REGEX,
  "Website is not valid"
);

export const yupWebsiteNullable = yup
  .string()
  .nullable()
  .trim()
  .label("Website")
  .matches(URL_REGEX, "Website is not valid");

export const yupUrl = yup
  .string()
  .nullable()
  .trim()
  .label("URL")
  .matches(URL_REGEX, "Invalid URL");

export const yupRole = yup.string().required("Select one role");

export const yupNumber = yup.number().typeError("Only numbers are valid");
export const yupNumberRequired = yup
  .number()
  .typeError("Only numbers are valid")
  .required("A number is required");

export const yupTextAreaRequired = (maxChar = 700) =>
  yup
    .string()
    .max(maxChar, `Maximun number of ${maxChar} characters exceeded`)
    .required("This is a required field");

export const yupTextArea = (maxChar = 700) =>
  yup.string().max(maxChar, `Maximun number of ${maxChar} characters exceeded`);

export const yupCardHolder = yup
  .string()
  .trim()
  .matches(/^[aA-zZ\s]+$/, "Only alphabetical characters")
  .required("Card Holder is required");

export const yupRequiredContactObject = yup
  .object()
  .shape({
    id: yup.number(),
    label: yup.string(),
    value: yup.string(),
  })
  .required()
  .typeError("Please select an option.");

export const yupNoEmptyArray = yup
  .array()
  .min(1, "At least one option is required.")
  .required();

export const yupRequiredState = yup
  .object()
  .shape({
    id: yup.string(),
    label: yup.string(),
    value: yup.string(),
  })
  .default(undefined)
  .nullable()
  .required("Please select an option.");

export const yupRequiredAutocomplete = yup
  .object()
  .shape({
    id: yup.string(),
    label: yup.string(),
    value: yup.string(),
  })
  .default(undefined)
  .nullable()
  .required("Please select an option.");
