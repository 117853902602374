import React from "react";
import AppleIcon from "@mui/icons-material/Apple";
import AppleLogin from "react-apple-login";

import { AuthButton } from "components/styled";

type AppleAuthButtonProps = {
  onSuccess: (response: any) => void;
  onFailure: (error: any) => void;
  role?: string;
  isSignUp?: boolean;
};

const AppleAuthButton: React.FC<AppleAuthButtonProps> = ({
  onSuccess,
  onFailure,
  role = "",
  isSignUp = false
}) => (
  <AppleLogin
    clientId={`${process.env.REACT_APP_APPLE_CLIENT_ID}`}
    redirectURI={`${process.env.REACT_APP_APPLE_REDIRECT_URI}`}
    scope="name email"
    usePopup
    responseMode="form_post"
    render={(renderProps) => (
      <AuthButton
        variant="outlined"
        aria-label="Apple Sign In Button"
        fullWidth
        onClick={() => {
          if (isSignUp && role === "") {
            // eslint-disable-next-line
            alert("Please select your role before proceeding with Apple login");
          } else {
            renderProps.onClick();
          }
        }}
        disabled={renderProps.disabled}>
        <AppleIcon fontSize="small" />
      </AuthButton>
    )}
    callback={(response) => {
      if (response.error) {
        onFailure(response.error?.error);
      } else {
        onSuccess(response);
      }
    }}
  />
);

export default AppleAuthButton;
