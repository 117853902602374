import { Grid, Box } from "@mui/material";
import ProviderImage from "assets/images/provider-onboarding-image.png";
import ProviderShapes from "assets/images/provider-onboarding-shapes.svg";
import { Banner, BannerBackground, Container } from "components/styled";
import React from "react";
import { Outlet } from "react-router-dom";
import styled from "styled-components";

const SessionContainer = styled(Container)`
  @media all and (max-width: 767px) {
    padding: 52px 0;
    height: calc(100vh - 104px);
  }
`;

const SessionLayout: React.FC = () => (
  <Grid container>
    <Box
      component={Grid}
      item
      md={6}
      display={{ xs: "none", md: "flex", lg: "flex" }}
      sx={{
        alignItems: "flex-end",
        overflow: "hidden",
        position: "relative",
      }}
    >
      <BannerBackground background={ProviderShapes}>
        <Banner src={ProviderImage} alt="banner" />
      </BannerBackground>
    </Box>

    <Grid container item xs={12} md={6} alignItems="center">
      <SessionContainer
        overflowY="auto"
        height="calc(100vh - 196px)"
        alignItems="start"
        padding="98px 0 98px 0"
      >
        <Outlet />
      </SessionContainer>
    </Grid>
  </Grid>
);

export default SessionLayout;
