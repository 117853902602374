import moment from "moment";

import { filterDuplicatedValues, findkeysToDelete } from "../../utils";
import { AutocompleteFieldProps, TextFieldProps } from "./fields";

const parsePersonalInfoRequest = (formValues, step, user) => {
  const payloadKeys = Object.keys(formValues);

  if (payloadKeys.length === 0) {
    return formValues;
  }

  const selectedLanguages = formValues.users_languages_attributes?.map(
    (language) => ({
      language_id: language.id,
    })
  );

  const languagesToDelete = findkeysToDelete(
    selectedLanguages,
    user.users_languages,
    "language_id"
  );

  const languages = filterDuplicatedValues(
    user.languages,
    selectedLanguages,
    "language_id"
  );

  const selectedRaces = formValues.users_races_attributes?.map((race) => ({
    race_id: race.id,
  }));

  const racesToDelete = findkeysToDelete(
    selectedRaces,
    user.users_races,
    "race_id"
  );

  const races = filterDuplicatedValues(user.races, selectedRaces, "race_id");

  const parsedPayload = {
    sign_up_stage: step + 1,
    first_name: formValues.first_name,
    last_name: formValues.last_name,
    pronoun_id: formValues.pronoun_id?.id,
    users_races_attributes: [...races, ...racesToDelete],
    users_languages_attributes: [...languages, ...languagesToDelete],
    date_of_birthday: formValues.date_of_birthday
      ? moment(formValues.date_of_birthday).format("MM/DD/YYYY")
      : "",
  };

  return { user: { ...parsedPayload } };
};

export const addOptionsToAutocompleteFields = (
  fields,
  fieldOptions
): AutocompleteFieldProps[] => {
  const options = Object.keys(fieldOptions);

  if (options.length === 0) {
    return fields;
  }

  return fields.map((field) => {
    if (field.name === "users_languages_attributes") {
      return { ...field, options: fieldOptions.languages };
    }
    if (field.name === "users_races_attributes") {
      return { ...field, options: fieldOptions.races };
    }
    if (field.name === "pronoun_id") {
      return { ...field, options: fieldOptions.pronouns };
    }
    return field;
  });
};

export const addInitialValueToFields = (
  initialValues,
  fields
): TextFieldProps[] | AutocompleteFieldProps[] => {
  if (!initialValues) {
    return fields;
  }
  // TODO: make this logic DRYER
  const fieldsWithInitialValues = fields.map((field) => {
    if (field.name === "pronoun_id") {
      return {
        ...field,
        initialValue:
          field.options
            ?.filter((option) => option.id === initialValues[field.name])
            .pop() || null,
      };
    }

    if (field.name === "users_languages_attributes") {
      return {
        ...field,
        initialValue: initialValues.languages,
      };
    }

    if (field.name === "users_races_attributes") {
      return {
        ...field,
        initialValue: initialValues.races,
      };
    }

    return {
      ...field,
      initialValue: initialValues[field.name],
    };
  });

  return fieldsWithInitialValues;
};

export default parsePersonalInfoRequest;
