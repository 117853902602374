import colors from "styles/colors";
import styled from "styled-components";
import { Button } from "@mui/material";
import { Container, Logo } from "components/styled";

export const NavLogo = styled(Logo)`
  cursor: pointer;
  margin-top: 0;
  width: 193px;

  @media screen and (max-width: 640px) {
    width: 176px;
  }
`;

export const DrawerContainer = styled(Container)`
  @media all and (min-width: 1201px) {
    display: none;
  }
  max-width: 350px;
`;

export const ContentContainer = styled(Container)`
  padding: 24px 64px;

  @media screen and (max-width: 640px) {
    padding: 24px 24px;
  }
`;

export const MenuItemButton = styled(Button)`
  &.menu-item-btn {
    height: auto;
    justify-content: flex-start;
    padding: 0;
    font-size: 1.25rem;
    margin-bottom: 24px;
    border: 0;
  }

  &:active {
    background-color: transparent !important;
  }

  &.menu-item-btn:hover {
    background-color: transparent;
    box-shadow: none;
  }

  & path {
    stroke: ${colors.CORAL[5]};
  }
`;
