import React from "react";

import ContactUsWavesLarge from "assets/images/contact-us/contact-us-wave-large.svg";
import ContactUsWavesMedium from "assets/images/contact-us/contact-us-wave-medium.svg";
import ContactUsWavesSmall from "assets/images/contact-us/contact-us-wave-small.svg";
import ContactUsWavesXs from "assets/images/contact-us/contact-us-wave-xs.svg";
import ContactUsWaves from "assets/images/contact-us/contact-us-wave.svg";

import styled from "styled-components";

const WaveBackground = styled.div`
  background-image: url(${ContactUsWaves});
  background-repeat: no-repeat;
  background-size: cover;
  height: 320px;
  position: absolute;
  top: 0px;
  width: 100%;
  z-index: 3;

  @media all and (min-width: 2560px) {
    background-position-x: center;
    background-size: auto;
    height: 285px;
    background-image: url(${ContactUsWavesLarge});
  }

  @media all and (max-width: 900px) {
    background-image: url(${ContactUsWavesMedium});
  }

  @media all and (max-width: 600px) {
    background-image: url(${ContactUsWavesSmall});
  }

  @media all and (max-width: 480px) {
    background-image: url(${ContactUsWavesXs});
    background-size: contain;
  }
`;

const Wave = () => <WaveBackground />;

export default Wave;
