export const findkeysToDelete = (selectedValues, originalValues, compareKey) =>
  originalValues
    .filter(
      (originalElement) =>
        !selectedValues.find(
          (selectedElement) =>
            originalElement[compareKey] === selectedElement[compareKey]
        )
    )
    .map((element) => ({ id: element.id, _destroy: true }));

export const filterDuplicatedValues = (backendArray, frontArray, compareKey) =>
  frontArray.filter(
    (element1) =>
      !backendArray.find((element2) => element1[compareKey] === element2.id)
  );
