import { Typography } from '@mui/material';
import { Container } from 'components/styled';
import React from 'react';
import colors from 'styles/colors';

type SinglePriceTypes =  {
  isFree: boolean;
  price: {
    price: string;
    recurring_interval: string;
  };
}

const SinglePriceOption = ({ isFree, price }: SinglePriceTypes) => (
  <Container justifyContent="flex-start" gap="0 10px">
    <Typography
      fontWeight={700}
      fontSize="40px"
      lineHeight="44px"
      color={colors.NUDE[9]}
    >
      ${price.price}
    </Typography>
    {!isFree && (
      <Typography variant="body1" color={colors.GREY[6]}>
        / {price.recurring_interval}ly
      </Typography>
    )}
  </Container>
);

export default SinglePriceOption;
