import React from "react";
import { usePlacesWidget } from "react-google-autocomplete";
import { TextField } from "@mui/material";
import { addToast } from "./HIHHToast";

type GoogleMapsAutocompleteProps = {
  errorMsg?: any;
  hasError: boolean;
  label?: string;
  name: string;
  touched: boolean;
  value: any;
  onBlur: () => void;
  onChange: (place: any) => void;
  types?: string[];
};

const GoogleMapsAutocomplete: React.FC<GoogleMapsAutocompleteProps> = ({
  errorMsg,
  hasError,
  label = "Address",
  name,
  touched,
  value,
  onBlur,
  onChange,
  types = [],
}) => {
  const { ref } = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    inputAutocompleteValue: "on",
    options: {
      types: types.length > 0 ? types : ["address"],
      fields: ["address_components", "geometry", "formatted_address", "name"],
    },
    onPlaceSelected: (place) => {
      if (!place) {
        addToast(
          "Please try again with a different location, the current one doesn't have all the required information.",
          "error"
        );
        return;
      }

      let city = "";
      let state = "";
      let country = "";
      let postalCode = "";

      place.address_components?.forEach((address) => {
        if (address.types.includes("locality")) city = address.long_name;
        if (address.types.includes("postal_code"))
          postalCode = address.long_name;
        if (address.types.includes("administrative_area_level_1"))
          state = address.long_name;
        if (address.types.includes("country")) country = address.long_name;
      });

      const newPlace = {
        display_name: place.formatted_address,
        name: place.name,
        city,
        state,
        zip_code: postalCode,
        country,
        lonlat:
          place.geometry && place.geometry.location
            ? `POINT (${place.geometry.location.lng()} ${place.geometry.location.lat()})`
            : null,
      };

      onChange(newPlace);
    },
  });

  const handleChange = (targetValue) => {
    let place: any = {
      display_name: targetValue,
      name: targetValue,
      city: "",
      state: "",
      country: "",
      lonlat: "",
    };

    if (targetValue === "" && value && value.id) {
      place._destroy = true;
      place.id = value.id;
      place.addressable_id = value.addressable_id;
    } else if (targetValue === "") {
      place = null;
    }

    onChange(place);
  };

  const hasErrors = errorMsg?.display_name || errorMsg?.country;

  return (
    <TextField
      error={hasError}
      fullWidth
      helperText={touched && hasErrors}
      inputRef={ref}
      placeholder=""
      value={value?.name}
      variant="outlined"
      onBlur={onBlur}
      onChange={(e) => handleChange(e.target.value)}
      autoComplete="on"
      inputProps={{
        autoComplete: "on",
      }}
      InputLabelProps={{
        shrink: value?.name?.length > 0,
      }}
      {...{ name, label }}
    />
  );
};

export default GoogleMapsAutocomplete;
