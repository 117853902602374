import { Box, Step, StepLabel, Stepper } from "@mui/material";
import React from "react";

type HIHHStepperSteps = {
  label: string;
  pathName: string;
};

type HIHHStepperProps = {
  steps: HIHHStepperSteps[];
  activeStep: number;
  maxWidth?: number;
};

const HIHHStepper: React.FC<HIHHStepperProps> = ({
  steps,
  activeStep,
  maxWidth = 500,
}) => (
  <Box
    sx={{
      maxWidth,
      margin: "0 auto",
      padding: "18px 0",
      alignSelf: "flex-start",
    }}
  >
    <Stepper activeStep={activeStep} alternativeLabel>
      {steps.map((step) => (
        <Step key={step.label}>
          <StepLabel>{step.label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  </Box>
);

export default HIHHStepper;
