import styled from "styled-components";

export const StyledBannerImg = styled.div<{ src: string }>`
  background-image: url("${(props) => props.src}");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
  height: 480px;
  min-width: 481px;

  @media all and (max-width: 750px) {
    height: 327px;
    min-width: 327px;
  }
`;

export const StyledContainer = styled.div<{ inverted?: boolean }>`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: ${(props) => (props.inverted ? "row-reverse" : "row")};
  gap: 95px;
  @media all and (max-width: 1200px) {
    flex-wrap: wrap;
    gap: 24px;
    justify-content: center;
  }
`;

export const StyledColumn = styled.div`
  align-items: flex-start;
  box-sizing: border-box;
  display: flex;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
