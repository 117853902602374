import React from "react";
import BenefitCard from "./BenefitCard";
import cards from "./cards";
import * as S from "./styles";

const BenefitsSection = () => (
  <S.StyledBenefitContainer>
    {cards.map((card) => (
      <BenefitCard {...card} />
    ))}
  </S.StyledBenefitContainer>
);

export default BenefitsSection;
