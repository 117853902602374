export default {
  WHITE: "#FFFFFF",
  BLACK: "#000000",
  CHOCOLATE_BROWN: "#41291D",
  CULTURED: "#FBF6F4",
  TESTIMONIAL_SUBTITLE_MOBILE: "#B58274",
  CORAL: {
    1: "#FCE8E8",
    2: "#F9D0D1",
    3: "#F7B9BB",
    4: "#F4A1A4",
    5: "#F18A8D",
    6: "#C16E71",
    7: "#915355",
    8: "#603738",
    9: "#301C1C",
  },
  GREY: {
    1: "#E5E6E9",
    2: "#CBCDD3",
    3: "#B0B5BC",
    4: "#969CA6",
    5: "#7C8390",
    6: "#636973",
    7: "#4A4F56",
    8: "#32343A",
    9: "#191A1D",
    10: "#4B3630",
  },
  SKIN: {
    1: "#FDF6F5",
    2: "#FCEDEB",
    3: "#FAE4E0",
    4: "#F9DBD6",
    5: "#F7D2CC",
    6: "#C6A8A3",
    7: "#947E7A",
    8: "#635452",
    9: "#312A29",
  },
  NUDE: {
    1: "#F8EDE9",
    2: "#F1DBD3",
    3: "#EAC9BE",
    4: "#E3B7A8",
    5: "#DCA592",
    6: "#B08475",
    7: "#846358",
    8: "#58423A",
    9: "#2C211D",
  },
  OLIVE: {
    1: "#E5E7DB",
    2: "#CAD0B7",
    3: "#B0B893",
    4: "#95A16F",
    5: "#7B894B",
    6: "#626E3C",
    7: "#4A522D",
    8: "#31371E",
    9: "#191B0F",
  },
  BRONZE: {
    1: "#F8E4E0",
    2: "#F0C9C0",
    3: "#E9ADA1",
    4: "#E19281",
    5: "#DA7762",
    6: "#AE5F4E",
    7: "#83473B",
    8: "#573027",
    9: "#2C1814",
  },
  RED: {
    1: "#F7DBDB",
    2: "#F0B7B7",
    3: "#E89393",
    4: "#E16F6F",
    5: "#D94B4B",
    6: "#AE3C3C",
    7: "#822D2D",
    8: "#571E1E",
    9: "#2B0F0F",
  },
  PURPLE: {
    1: "#E2DBE6",
    2: "#C5B6CE",
    3: "#A892B5",
    4: "#8B6D9D",
    5: "#6E4984",
    6: "#583A6A",
    7: "#422C4F",
    8: "#2C1D35",
    9: "#160F1A",
  },
  YELLOW: {
    1: "#FFF7E4",
    2: "#FFF0C8",
    3: "#FFE8AD",
    4: "#FFE191",
    5: "#FFD976",
    6: "#CCAE5E",
    7: "#998247",
    8: "#66572F",
    9: "#332B18",
  },
};
