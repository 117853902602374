/* eslint-disable no-shadow */
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import { QuestionsContextProvider } from "components/QuestionsContextProvider";
import { AccountSettingsContextProvider } from "components/AccountSettingsContextProvider";
import { MemberFavoriteContextProvider } from "components/MemberFavoriteContextProvider";
import { MenuContextProvider } from "components/MenuContextProvider";
import { ProvidersFilterContextProvider } from "components/ProvidersFilterContextProvider";
import { useUserContext } from "components/UserContextProvider";
import usePageViews from "hooks/usePageViews";
import React, { useEffect } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { Route, Routes, useLocation } from "react-router-dom";
import Footer from "components/Footer";
import NavBar from "components/NavBar";
import { DirectoryContextProvider } from "./Directory/DirectoryContextProvider";
import NotFound from "./NotFound";
import OnboardingRoutes, { ONBOARDING_VIEW_PATH } from "./Onboarding";
import PrivacyPolicy from "./PrivacyPolicy";
import { ProfileContextProvider } from "./Profile/ProfileContextProvider";
import ProtectedRoute from "./ProtectedRoute";
import RequiresOnboardingRoute from "./RequiresOnboardingRoute";
import SessionRoutes, { SESSION_VIEW_PATH } from "./Session";
import SubscriptionPayment from "./AccountSettings/Member/Subscription/PlansSection/Plan/SubscriptionPayment";
import { SubscriptionContextProvider } from "./AccountSettings/Member/Subscription/SubscriptionContextProvider";
import ContactUs from "./ContactUs";
import VCSPayment from "./VCSPayment";
import Disclaimer from "./Disclaimer";
import OnboardingWardRoute from "./Onboarding/OnboardingWardRoute";
import CareSquads from "./CareSquads";
import Partnership from "./Partnership";
import BcbsMnPage from "./BcbsMnPage";

const AboutUs = React.lazy(() => import("./AboutUs"));
const AccountSettings = React.lazy(() => import("./AccountSettings"));
const AnswerDetails = React.lazy(() => import("./AnswerDetails"));
const CarePoint = React.lazy(() => import("./CarePoint"));
const Community = React.lazy(() => import("./Community"));
const Directory = React.lazy(() => import("./Directory"));
const Favorites = React.lazy(() => import("./Favorites"));
const HealthEquityPledge = React.lazy(() => import("./HealthEquityPledge"));
const Home = React.lazy(() => import("./Home"));
const Membership = React.lazy(() => import("./Membership"));
const ProviderProfile = React.lazy(() => import("./Profile/Provider"));
const QuestionCategories = React.lazy(() => import("./QuestionCategories"));
const TermsAndConditions = React.lazy(() => import("./TermsConditions"));
const VirtualCareSquads = React.lazy(() => import("./VirtualCareSquads"));

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const GRAPHQL_URI = process.env.REACT_APP_WPGRAPHQL_BASE;

const client = new ApolloClient({
  uri: GRAPHQL_URI,
  cache: new InMemoryCache(),
});

const reactQueryClient = new QueryClient();

export const APP_ROUTES = {
  aboutUs: "/about-us",
  accountSettings: "/account_settings",
  community: "/community",
  contactUs: "/contact-us",
  directory: "/directory/providers",
  disclaimer: "/disclaimer",
  favoriteProviders: "/favorites/providers",
  healthEquityPledge: "/health-equity-pledge",
  home: "/",
  membershipIndividuals: "/membership/individuals",
  membershipProviders: "/membership/providers",
  paymentVCS: "/virtual-care-squads/payment/:virtualCareSquadId",
  privacy: "/privacy",
  questionCategories: "/question-categories/:categoryKey",
  signIn: "/users/sign-in",
  signUp: "/users/sign-up",
  terms: "/terms",
  virtualCareSquads: "/virtual-care-squads",
  providerProfile: "/provider",
  partnership: "/partnership",
  careSquads: "/care-squads",
  bcbsMnPage: "/mn",
  carePoint: "/carepoint",
  answerDetails: "/answers/:answerId",
  footer: "iframes/footer",
  navbar: "iframes/navbar",
};

/*-------------------------------------------------------------------------------------*/
  /* July 31, 2024: K.Suazo
  Removing RequiresOnboardingRoute to the following routes:
  1) Account Settings
  2) Favorited providers
  3) Virtual care squad payment
  This is due to members not requiring the onboarding process to use their account */
/*-------------------------------------------------------------------------------------*/

const HIHHRoutes = () => {
  const { user } = useUserContext();
  usePageViews(user);

  return (
    <>
      <ScrollToTop />
      <MenuContextProvider>
        <Routes>
          <Route path={SESSION_VIEW_PATH} element={<SessionRoutes />} />
          <Route
            path={ONBOARDING_VIEW_PATH}
            element={
              <ProtectedRoute>
                <QueryClientProvider client={reactQueryClient}>
                  <OnboardingWardRoute>
                    <OnboardingRoutes />
                  </OnboardingWardRoute>
                </QueryClientProvider>
              </ProtectedRoute>
            }
          />
          <Route
            path="provider/:providerId"
            element={
              <RequiresOnboardingRoute>
                <ProfileContextProvider>
                  <QueryClientProvider client={reactQueryClient}>
                    <ProviderProfile />
                  </QueryClientProvider>
                </ProfileContextProvider>
              </RequiresOnboardingRoute>
            }
          />
          <Route
            path="/directory/providers/*"
            element={
              <ProvidersFilterContextProvider>
                <DirectoryContextProvider>
                  <Directory />
                </DirectoryContextProvider>
              </ProvidersFilterContextProvider>
            }
          />
          <Route path={APP_ROUTES.community} element={<Community />} />
          <Route
            path={APP_ROUTES.virtualCareSquads}
            element={
              <QueryClientProvider client={reactQueryClient}>
                <VirtualCareSquads />
              </QueryClientProvider>
            }
          />
          <Route
            path={APP_ROUTES.careSquads}
            element={
              <QueryClientProvider client={reactQueryClient}>
                <CareSquads />
              </QueryClientProvider>
            }
          />
          <Route
            path={APP_ROUTES.healthEquityPledge}
            element={<HealthEquityPledge />}
          />
          <Route
            path={`${APP_ROUTES.accountSettings}/*`}
            element={
              <ProtectedRoute>
                <AccountSettingsContextProvider>
                  <SubscriptionContextProvider>
                    <Routes>
                      <Route
                        index
                        element={
                          <QueryClientProvider client={reactQueryClient}>
                            <AccountSettings />
                          </QueryClientProvider>
                        }
                      />
                      <Route
                        path="/subscription_payment"
                        element={<SubscriptionPayment />}
                      />
                    </Routes>
                  </SubscriptionContextProvider>
                </AccountSettingsContextProvider>
              </ProtectedRoute>
            }
          />
          <Route
            path={APP_ROUTES.favoriteProviders}
            element={
              <ProtectedRoute>
                <MemberFavoriteContextProvider>
                  <QueryClientProvider client={reactQueryClient}>
                    <ApolloProvider client={client}>
                      <Favorites />
                    </ApolloProvider>
                  </QueryClientProvider>
                </MemberFavoriteContextProvider>
              </ProtectedRoute>
            }
          />
          <Route
            index
            element={
              <ProvidersFilterContextProvider>
                <ApolloProvider client={client}>
                  <QueryClientProvider client={reactQueryClient}>
                    <Home />
                  </QueryClientProvider>
                </ApolloProvider>
              </ProvidersFilterContextProvider>
            }
          />
          <Route path={APP_ROUTES.terms} element={<TermsAndConditions />} />
          <Route path={APP_ROUTES.privacy} element={<PrivacyPolicy />} />
          <Route path={APP_ROUTES.disclaimer} element={<Disclaimer />} />
          <Route path={APP_ROUTES.partnership} element={<Partnership />} />
          <Route path={APP_ROUTES.bcbsMnPage} element={<BcbsMnPage />} />
          <Route
            path={APP_ROUTES.aboutUs}
            element={
              <QueryClientProvider client={reactQueryClient}>
                <AboutUs />
              </QueryClientProvider>
            }
          />

          <Route path={APP_ROUTES.contactUs} element={<ContactUs />} />

          <Route
            path="/membership/*"
            element={
              <QueryClientProvider client={reactQueryClient}>
                <Membership />
              </QueryClientProvider>
            }
          />

          <Route
            path={APP_ROUTES.paymentVCS}
            element={
              <ProtectedRoute>
                <SubscriptionContextProvider>
                  <QueryClientProvider client={reactQueryClient}>
                    <VCSPayment />
                  </QueryClientProvider>
                </SubscriptionContextProvider>
              </ProtectedRoute>
            }
          />

          <Route
            path={APP_ROUTES.carePoint}
            element={
              <QueryClientProvider client={reactQueryClient}>
                <QuestionsContextProvider>
                  <CarePoint />
                </QuestionsContextProvider>
              </QueryClientProvider>
            }
          />
          <Route
            path={APP_ROUTES.questionCategories}
            element={
              <QueryClientProvider client={reactQueryClient}>
                <QuestionsContextProvider>
                  <QuestionCategories />
                </QuestionsContextProvider>
              </QueryClientProvider>
            }
          />
          <Route path={APP_ROUTES.footer} element={<Footer />} />
          <Route path={APP_ROUTES.navbar} element={<NavBar />} />
          <Route
            path={APP_ROUTES.answerDetails}
            element={
              <QueryClientProvider client={reactQueryClient}>
                <AnswerDetails />
              </QueryClientProvider>
            }
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </MenuContextProvider>
    </>
  );
};

export default HIHHRoutes;
