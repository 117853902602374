import React from "react";
import { CircularProgress } from "@mui/material";
import {
  FormikAutocomplete,
  FormikDateInput,
  FormikTextInput,
} from "components/Form/FormikFields";
import { Container, OnboardingMobileLogo } from "components/styled";

import OnboardingLogo from "assets/logos/onboarding-logo.svg";
import FormHeader from "routes/Onboarding/FormHeader";
import FormLayout from "routes/Onboarding/FormLayout";
import { useOnboardingContext } from "routes/Onboarding/OnboardingContextProvider";
import { v4 as uuidv4 } from "uuid";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import OnboardingTooltip from "routes/Onboarding/OnboardingTooltip";
import {
  autocompleteFields,
  TextFieldProps,
  textFields,
  dateField,
} from "./fields";
import {
  addInitialValueToFields,
  addOptionsToAutocompleteFields,
} from "./utils";

const textFieldsUI = (
  <>
    {textFields.map((field) => (
      <FormikTextInput key={`${field.name} ${uuidv4()}`} {...field} />
    ))}
  </>
);

const dateFieldsUI = (
  <FormikDateInput key={`${dateField.name} ${uuidv4()}`} {...dateField} />
);

type AutocompleteFieldsProps = {
  fieldsOptions: any[];
};

const AutocompleteFields: React.FC<AutocompleteFieldsProps> = ({
  fieldsOptions,
}) => (
  <>
    {fieldsOptions.map((field) => (
      <FormikAutocomplete key={`${field.name} ${uuidv4()}`} {...field} />
    ))}
  </>
);

const AccountInfo = () => {
  const { stepFieldsOptions, user, isLoading } = useOnboardingContext();

  let fieldsWithOptions;
  if (!isLoading) {
    fieldsWithOptions = addOptionsToAutocompleteFields(
      autocompleteFields,
      stepFieldsOptions
    );
  }

  let accountInfoFields: TextFieldProps[] | AutocompleteFieldsProps[] = [];

  if (!isLoading && user) {
    accountInfoFields = addInitialValueToFields(user, [
      ...textFields,
      ...fieldsWithOptions,
      dateField,
    ]);
  }

  return (
    <FormLayout fields={accountInfoFields}>
      <Container gap="31px">
        <OnboardingMobileLogo src={OnboardingLogo} alt="app-bar-logo" />

        <FormHeader
          title="Account Information"
          subtitle="Tell us about yourself."
        />
        {isLoading ? (
          <CircularProgress />
        ) : (
          <Container maxWidth="500px" gap="28px" padding="0 18px">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              {textFieldsUI}
              <AutocompleteFields fieldsOptions={fieldsWithOptions} />
              {dateFieldsUI}
            </LocalizationProvider>
            <OnboardingTooltip
              title="We are collecting demographic information to better understand you as a member of our community."
              message="Why are we asking this information?"
            />
          </Container>
        )}
        <Container height="40px" />
      </Container>
    </FormLayout>
  );
};

export default AccountInfo;
