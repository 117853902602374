import {
  ToggleButtonGroup as MaterialToggleButtonGroup,
  Typography,
} from "@mui/material";
import React from "react";
import styled from "styled-components";
import colors from "styles/colors";
import HIHHToggleButton from "./HIHHToggleButton";

const ErrorMessage = styled(Typography)`
  margin-left: 0;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 16px;
  color: ${colors.RED[5]};
`;

const ToggleButtonGroup = styled(MaterialToggleButtonGroup)`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

type HIHHToggleButtonGroupProps = {
  dirty?: boolean;
  errorMsg?: string;
  options: any[];
  value: any;
  onChange: (event: any, newValue: string) => void;
};

const HIHHToggleButtonGroup: React.FC<HIHHToggleButtonGroupProps> = ({
  dirty = false,
  errorMsg = "",
  value,
  options,
  onChange,
}) => (
  <>
    <ToggleButtonGroup value={value} onChange={onChange} exclusive>
      {options.map((option) => (
        <HIHHToggleButton
          key={option.value}
          label={option.label}
          icon={option.icon}
          value={option.value}
          onChange={onChange}
          selected={option.value === value}
        />
      ))}
    </ToggleButtonGroup>
    {dirty && errorMsg && <ErrorMessage>{errorMsg}</ErrorMessage>}
  </>
);

export default HIHHToggleButtonGroup;
