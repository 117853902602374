import { CircularProgress } from "@mui/material";
import { Container } from "components/styled";
import React from "react";

type PageLoaderProps = {
  isBlockLoader?: boolean;
};

const PageLoader = ({ isBlockLoader = false }: PageLoaderProps) => (
  <Container
    height={isBlockLoader ? "100%" : "100vh"}
    width={isBlockLoader ? "100%" : "100vw"}
  >
    <CircularProgress />
  </Container>
);

export default PageLoader;
