import OnboardingLogo from "assets/logos/onboarding-logo.svg";
import { addToast } from "components/common/HIHHToast";
import PageLoader from "components/common/PageLoader";
import {
  FormikAutocomplete,
  FormikDateInput,
  FormikTextInput,
} from "components/Form/FormikFields";
import { Container, OnboardingMobileLogo } from "components/styled";
import useStepFieldOptions from "hooks/Onboarding/useStepFieldOptions";
import React from "react";
import { v4 as uuidv4 } from "uuid";

import FormHeader from "routes/Onboarding/FormHeader";
import FormLayout from "routes/Onboarding/FormLayout";
import { useOnboardingContext } from "routes/Onboarding/OnboardingContextProvider";
import OnboardingTooltip from "routes/Onboarding/OnboardingTooltip";
import {
  autocompleteFields,
  dateField,
  TextFieldProps,
  textFields,
} from "./fields";
import {
  addInitialValueToFields,
  addOptionsToAutocompleteFields,
} from "./utils";

const textFieldsUI = (
  <>
    {textFields.map((field) => (
      <FormikTextInput key={`${field.name} ${uuidv4()}`} {...field} />
    ))}
  </>
);

type AutocompleteFieldsProps = {
  fieldsOptions: any[];
};

const AutocompleteFields: React.FC<AutocompleteFieldsProps> = ({
  fieldsOptions,
}) => (
  <>
    {fieldsOptions.map((field) => (
      <FormikAutocomplete key={`${field.name} ${uuidv4()}`} {...field} />
    ))}
  </>
);

const PersonalInfo = () => {
  const { step, user } = useOnboardingContext();

  const { isLoading, data, error } = useStepFieldOptions(step);

  if (isLoading) {
    return <PageLoader isBlockLoader />;
  }

  if (error) {
    addToast(error.message, "error");
    return null;
  }

  const stepFieldsOptions = data?.step_fields ?? [];

  const fieldsWithOptions = addOptionsToAutocompleteFields(
    autocompleteFields,
    stepFieldsOptions
  );

  let personalInfoFields: TextFieldProps[] | AutocompleteFieldsProps[] = [];

  if (user) {
    personalInfoFields = addInitialValueToFields(user, [
      ...textFields,
      ...fieldsWithOptions,
      dateField,
    ]);
  }

  const dateFieldsUI = (
    <FormikDateInput key={`${dateField.name} ${uuidv4()}`} {...dateField} />
  );

  return (
    <FormLayout fields={personalInfoFields}>
      <Container gap="31px">
        <OnboardingMobileLogo src={OnboardingLogo} alt="app-bar-logo" />
        <FormHeader
          title="Personal Information"
          subtitle="Tell us about yourself."
        />
        <Container maxWidth="500px" gap="30px" padding="0 18px">
          {textFieldsUI}
          <AutocompleteFields fieldsOptions={fieldsWithOptions} />
          {dateFieldsUI}
          <OnboardingTooltip
            title="We are collecting demographic information to be able to provide better search results."
            message="Why are we asking this information?"
          />
        </Container>
      </Container>
    </FormLayout>
  );
};

export default PersonalInfo;
