import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Typography } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import styled from "styled-components";

import { useUserContext } from "components/UserContextProvider";
import Form from "components/Form";
import { FormikTextInput } from "components/Form/FormikFields";
import FormikSubmitButton from "components/Form/FormikSubmitButton";
import { Container } from "components/styled";
import { SESSION_ROUTES } from "routes/Session";
import resetPasswordFields from "./fields";
import UserFormHeader from "../shared/UserFormHeader";

const ResetPasswordForm = styled(Form)`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const ResetPasswordContainer = styled(Container)`
  max-width: 430px;

  @media all and (max-width: 1500px) {
    padding-left: 80px;
    padding-right: 80px;
  }

  @media all and (max-width: 580px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const textFieldsUI = (
  <>
    {resetPasswordFields.map((field) => (
      <FormikTextInput
        key={`${field.name} ${uuidv4()}`}
        type="password"
        {...field}
      />
    ))}
  </>
);

const ResetPassword = () => {
  const { passwordToken } = useParams();
  const { resetUserPassword } = useUserContext();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const onSubmit = async ({ password, password_confirmation }) => {
    setIsLoading(true);
    try {
      if (passwordToken) {
        await resetUserPassword(passwordToken, password, password_confirmation);
        setIsLoading(false);
        navigate(`/users${SESSION_ROUTES.SIGN_IN}`);
      }
    } catch (err) {
      setIsLoading(false);
    }
  };

  return (
    <ResetPasswordContainer
      direction="column"
      justifyContent="space-between"
      gap="20px"
    >
      <>
        <UserFormHeader
          title="Reset your password"
          subtitle="Enter your new password"
        />

        <Container maxWidth="340px" gap="28px">
          <ResetPasswordForm fields={resetPasswordFields} onSubmit={onSubmit}>
            <Container maxWidth="340px" gap="28px">
              {textFieldsUI}
              <FormikSubmitButton disableOnErrors disabled={isLoading}>
                <Typography variant="subtitle1">Confirm</Typography>
              </FormikSubmitButton>
            </Container>
          </ResetPasswordForm>
        </Container>
      </>
    </ResetPasswordContainer>
  );
};

export default ResetPassword;
