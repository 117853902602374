import { v4 as uuidv4 } from "uuid";

export type MetricItem = {
  value: string;
  body: string;
  height?: number;
  key?: string;
};

export default [
  {
    height: 124,
    key: uuidv4(),
    value: "12,300",
    body: "Members",
  },
  {
    key: uuidv4(),
    value: "1,400",
    body: "Healthcare Providers",
  },
] as MetricItem[];
