import { Typography } from "@mui/material";
import { Container } from "components/styled";
import React from "react";
import colors from "styles/colors";

type FormHeaderProps = {
  title: string;
  subtitle?: string;
};

const ContactFormHeader = ({ title, subtitle }: FormHeaderProps) => (
  <Container
    direction="column"
    margin="0 0 24px 0"
    gap="8px"
    alignItems="start"
  >
    <Typography variant="link4" color={colors.GREY[6]}>
      {title}
    </Typography>
    <Typography variant="body2" color={colors.GREY[9]}>
      {subtitle}
    </Typography>
  </Container>
);

export default ContactFormHeader;
