import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { Container } from "components/styled";
import React from "react";
import colors from "styles/colors";

export type VCSPricing = {
  currency: string;
  id: number;
  price: string;
  price_cents: number;
  price_type: string;
  recurring_interval: string;
  recurring_interval_count: number;
  stripe_price: boolean;
  stripe_price_id: string;
};

type PriceProps = {
  virtualCareSquadPrices: VCSPricing[];
  selectedPriceId: string;
  setSelectedPriceId: React.SetStateAction<any>;
};

const PriceValue = ({ price }: { price: any }) => (
  <Container justifyContent="flex-start" style={{ marginBottom: "4px" }}>
    <Typography
      fontSize="48px"
      lineHeight="72px"
      fontWeight={700}
      style={{
        marginRight: "12px",
      }}
    >
      ${price.price}
    </Typography>

    {price.price_type === "recurring" && (
      <Typography
        fontSize="14px"
        lineHeight="44px"
        fontWeight={700}
        color={colors.GREY[6]}
        style={{
          letterSpacing: "0.135em",
        }}
      >
        / 4 MONTHS
      </Typography>
    )}
  </Container>
);

const Pricing = ({
  virtualCareSquadPrices,
  selectedPriceId,
  setSelectedPriceId,
}: PriceProps) => (
  <div>
    <FormControl>
      <RadioGroup
        aria-labelledby="plan-prices-radio-buttons-group"
        name="plan-prices-radio-buttons-group"
        row
        value={selectedPriceId}
        onChange={(_, newValue) => setSelectedPriceId(newValue)}
      >
        <PriceValue
          price={
            virtualCareSquadPrices.filter(
              (price) => price.id.toString() === selectedPriceId
            )[0]
          }
        />
        {virtualCareSquadPrices.map((price) => (
          <FormControlLabel
            key={price.id}
            value={price.id}
            control={<Radio />}
            label={
              <Typography variant="link1" color={colors.GREY[6]}>
                {price.price_type === "one_time" ? "One Payment" : "Monthly"}
              </Typography>
            }
          />
        ))}
      </RadioGroup>
    </FormControl>
  </div>
);

export default Pricing;
