import { Alert, AlertColor } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";

const useStyles = makeStyles(() => ({
  root: {
    padding: 8,
  },
  message: {
    padding: 0,
  },
  icon: {
    padding: 0,
    alignSelf: "center",
    marginRight: 0,
  },
  action: {
    padding: 0,
  },
}));

type HIHHAlertProps = {
  severity: AlertColor | undefined;
  onClose: () => void;
};

const HIHHAlert: React.FC<HIHHAlertProps> = ({
  severity,
  onClose,
  children,
}) => {
  const styles = useStyles();

  return (
    <Alert severity={severity} onClose={onClose} classes={styles}>
      {children}
    </Alert>
  );
};

export default HIHHAlert;
