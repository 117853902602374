import { Box, Grid } from "@mui/material";
import HIHHStepper from "components/common/HIHHStepper";
import Form from "components/Form";
import { Container, ContainerWithTopShadow } from "components/styled";
import React from "react";
import styled from "styled-components";
import { MEMBER_ONBOARDING_STEPS, PROVIDER_ONBOARDING_STEPS } from ".";

import NavigationControls from "./NavigationControls";
import { useOnboardingContext } from "./OnboardingContextProvider";

export const FormContainer = styled(ContainerWithTopShadow)`
  height: calc(100vh - 287px);
  overflow-y: scroll;
  display: flex;
  padding: 40px 0;

  @media all and (max-width: 480px) {
    height: auto;
    overflow-y: hidden;
  }
`;

type FormLayoutProps = {
  fields: any;
};

const FormLayout: React.FC<FormLayoutProps> = ({ fields, children }) => {
  const { step, user, submitForm } = useOnboardingContext();

  const onboardingRoutes =
    user?.role === "provider"
      ? PROVIDER_ONBOARDING_STEPS
      : MEMBER_ONBOARDING_STEPS;

  return (
    <Box component={Grid} item md={6} xs={12} sx={{ paddingTop: "18px" }}>
      <Form fields={fields} onSubmit={submitForm}>
        <>
          <HIHHStepper steps={onboardingRoutes} activeStep={step - 1} />

          <FormContainer id="middle" alignItems="start">
            <Container>{children}</Container>
          </FormContainer>

          <NavigationControls />
        </>
      </Form>
    </Box>
  );
};

export default FormLayout;
