import React, { useCallback } from "react";
import { Typography } from "@mui/material";

import HIHHLogo from "assets/logos/brand/hihh-round-logo.svg";
import colors from "styles/colors";
import { Container, Logo } from "components/styled";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

type UserFormHeaderProps = {
  title: string;
  subtitle: string | React.ReactElement;
  margin?: string;
};

const MainTitle = styled(Typography)`
  text-align: center;
`;

const UserFormHeader = ({
  title,
  subtitle,
  margin = "108px 0 20px 0",
}: UserFormHeaderProps): JSX.Element => {
  const navigate = useNavigate();
  const goToHome = useCallback(() => navigate("/"), []);

  return (
    <Container>
      <Logo onClick={goToHome} src={HIHHLogo} alt="hihh-round-logo" />
      <Container direction="column" margin={margin} gap="12px">
        <MainTitle variant="h2" color={colors.GREY[9]}>
          {title}
        </MainTitle>
        <MainTitle variant="body2" color={colors.GREY[9]}>
          {subtitle}
        </MainTitle>
      </Container>
    </Container>
  );
};

export default UserFormHeader;
