import styled from "styled-components";
import colors from "styles/colors";
import metricsBgMobile from "../assets/metricsBgMobile.svg";
import metricsBgDesktop from "../assets/metricsBgDesktop.svg";

export const StyledMetricsContainer = styled.div`
  align-items: center;
  background-color: ${colors.PURPLE[6]};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 72px;
  height: 940px;
  justify-content: center;
  padding: 64px 24px 96px;
  position: relative;
  width: 100%;

  @media all and (min-width: 1200px) {
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 24px;
    height: 492px;
    justify-content: center;
    padding: 112px 208px 112px 209px;
  }
`;

export const StyledTitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 1;
`;

export const StyledMetricBg = styled.div`
  background-image: url("${metricsBgMobile}");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  bottom: 0;
  box-sizing: border-box;
  height: 190px;
  position: absolute;
  right: 0;
  width: 100%;

  @media all and (min-width: 1200px) {
    background-image: url("${metricsBgDesktop}");
    height: 380px;
    width: 1440px;
  }
`;

export const StyledMetricElement = styled.div<{ height?: number }>`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 325px;
  min-height: ${(props) => (props.height ? props.height : 156)}px;
  z-index: 1;
  margin: 0px 20px;

  @media all and (min-width: 1200px) {
    min-height: 156px;
  }
`;
