import { dataURIToBlob } from "utils";
import { TextFieldProps } from "components/ProfilePictureModal/profilePicturefields";

export const addInitialValueToFields = (
  initialValues,
  fields
): TextFieldProps[] => {
  if (!initialValues) {
    return fields;
  }

  const profilePictureName = "profile_picture_url";

  const fieldsWithInitialValues = fields.map((field) => ({
    ...field,
    initialValue: initialValues[profilePictureName],
  }));

  return fieldsWithInitialValues;
};

export const parseProfilePictureRequest = (userFormInfo: any, user) => {
  const base64 = userFormInfo.profile_picture;

  const blob = dataURIToBlob(base64);
  const file = blob
    ? new File([blob], `${user.first_name}_${user.last_name}.jpeg`)
    : "";

  const formData = new FormData();
  formData.append("user[profile_picture]", file);

  return formData;
};
