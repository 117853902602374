import { Typography } from "@mui/material";
import { Container } from "components/styled";
import React from "react";
import colors from "styles/colors";

type FormHeaderProps = {
  title: string;
  subtitle?: string;
  margin?: string;
};

const FormHeader = ({
  title,
  subtitle,
  margin = "0 0 9px 0",
}: FormHeaderProps) => (
  <Container direction="column" margin={margin} gap="13px">
    <Typography variant="h3" color={colors.GREY[9]} textAlign="center">
      {title}
    </Typography>
    {subtitle && (
      <Typography
        variant="body2"
        color={colors.GREY[9]}
        style={{ maxWidth: "500px", textAlign: "center" }}
      >
        {subtitle}
      </Typography>
    )}
  </Container>
);

export default FormHeader;
