export const isString = (variable: any) =>
  typeof variable === "string" || variable instanceof String;

export const isObject = (value) =>
  value && typeof value === "object" && value.constructor === Object;

export const isObjectEmpty = (object) => Object.keys(object).length === 0;

export const hasNullValueKey = (object) =>
  !Object.values(object).some((x) => x !== null && x !== "");

export const getCroppedImg = async (
  imageSrc,
  pixelCrop
): Promise<string | null> =>
  new Promise((resolve) => {
    const canvas = document.createElement("canvas");
    const ctx: CanvasRenderingContext2D = canvas.getContext(
      "2d"
    ) as CanvasRenderingContext2D;

    if (!ctx) resolve(null);

    const image = new Image();
    image.crossOrigin = "Anonymous";
    image.src = imageSrc;

    image.onload = () => {
      canvas.width = image.width;
      canvas.height = image.height;

      ctx.drawImage(image, 0, 0);

      const data = ctx.getImageData(
        pixelCrop.x,
        pixelCrop.y,
        pixelCrop.width,
        pixelCrop.height
      );

      canvas.width = pixelCrop.width;
      canvas.height = pixelCrop.height;

      ctx.putImageData(data, 0, 0);

      // As Base64 string
      resolve(canvas.toDataURL("image/jpeg"));
    };
  });

export const dataURLToBase64 = (url, callback) => {
  if (!url) {
    callback("");
    return;
  }

  const xhr = new XMLHttpRequest();

  xhr.onload = () => {
    const reader = new FileReader();
    reader.onloadend = () => {
      callback(reader.result);
    };
    reader.readAsDataURL(xhr.response);
  };

  xhr.open("GET", url);
  xhr.responseType = "blob";
  xhr.send();
};

export const dataURIToBlob = (dataURI: string) => {
  if (!dataURI) return null;

  const splitDataURI = dataURI.split(",");

  const byteString =
    splitDataURI[0].indexOf("base64") >= 0
      ? atob(splitDataURI[1])
      : decodeURI(splitDataURI[1]);

  const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);

  return new Blob([ia], { type: mimeString });
};

export const getPath = (path, obj) =>
  path.split(".").reduce((prev, curr) => (prev ? prev[curr] : undefined), obj);

export const stringArrayToIntArray = (stringArray) => {
  if (!stringArray || stringArray.length === 0) return [];

  return stringArray.replace(/, +/g, ",").split(",").map(Number);
};

export const scrollToWithNavbar = (element) => {
  if (!element) return;

  const NAVBAR_HEIGHT = 80; // aprox height for navbar
  const top =
    element.getBoundingClientRect().top + window.pageYOffset - NAVBAR_HEIGHT;
  window.scrollTo({ top, behavior: "smooth" });
};

export const getReadingTime = (text = "") => {
  const wordsPerMinute = 200; // Average case.
  let result;
  const textLength = text.split(" ").length; // Split by words
  if (textLength > 0) {
    const value = Math.ceil(textLength / wordsPerMinute);
    result = `${value} min read`;
  }
  return result;
};

export const fileToBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const areObjectKeysTruthy = (element) => {
  if (!element) {
    return false;
  }
  return Object.values(element).every((value) => value);
};

export const getPageQueryParam = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const currentPage = queryParams.get("page");

  return currentPage ? parseInt(currentPage, 10) : 1;
};

export const isOddNumber = (number) => number % 2 !== 0;
