import HIHHModal from "components/common/HIHHModal";
import styled from "styled-components";
import colors from "styles/colors";
import rightFrameBackground from "./assets/rightFrameBackground.svg";

export const StyledHIHHModal = styled(HIHHModal)`
  overflow-y: scroll;

  .modal-box {
    background-color: transparent !important;
    box-sizing: border-box;
    height: 100%;
    padding-top: 75px;
  }
`;

export const StyledContainer = styled.div`
  align-items: flex-start;
  background: ${colors.WHITE};
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 24px;
  min-width: calc(100vw - 48px);
  position: relative;
  min-height: 100%;

  @media all and (min-width: 1200px) {
    flex-direction: row;
    min-width: 1280px;
  }
`;

export const StyledCloseButton = styled.a`
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  gap: 9px;
  justify-content: center;
  position: absolute;
  right: 12px;
  top: 12px;
`;

export const LeftFrameContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 40px;
  width: 100%;
  height: 100%;

  @media all and (min-width: 1200px) {
    flex: 1;
  }
`;

export const RightFrameContainer = styled.div`
  background-image: url("${rightFrameBackground}");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 8px 0px 0px 8px;
  display: none;
  height: 840px;
  left: 0px;
  min-width: 640px;
  top: 0px;
  width: 640px;

  @media all and (min-width: 1200px) {
    display: flex;
    flex: 1;
  }
`;

export const HighlighText = styled.span`
  color: ${colors.BRONZE[5]};
`;
