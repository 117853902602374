import { Button, IconButton, Typography } from "@mui/material";
import { TagIcon } from "components/common/icons";
import { FormikTextInput } from "components/Form/FormikFields";
import useFormikField from "components/Form/hooks/useFormikField";
import { Container } from "components/styled";
import React from "react";
import styled from "styled-components";
import colors from "styles/colors";
import CloseIcon from "@mui/icons-material/Close";
import { useSubscriptionContext } from "../../SubscriptionContextProvider";

import { couponField } from "./fields";

const AppliedCoupon = styled(Container)`
  background-color: ${colors.SKIN[1]};
  justify-content: space-between;
`;

const CouponField = () => {
  const { value } = useFormikField(couponField.name);
  const { getCoupon, coupon, removeCoupon } = useSubscriptionContext();

  if (coupon) {
    return (
      <AppliedCoupon id="applied-coupon">
        <Container padding="4px 14px" gap="4px" justifyContent="space-between">
          <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
            <TagIcon />
            <Typography variant="link2" color={colors.NUDE[8]}>
              {coupon.code}
            </Typography>
          </div>
          <IconButton sx={{ color: colors.NUDE[8] }} onClick={removeCoupon}>
            <CloseIcon />
          </IconButton>
        </Container>
      </AppliedCoupon>
    );
  }

  return (
    <FormikTextInput
      {...couponField}
      endAdornment={
        <Button
          id="apply-coupon-btn"
          variant="outlined"
          style={{ height: "32px", width: "71px" }}
          disabled={value?.length === 0}
          onClick={() => getCoupon(value)}
        >
          <Typography variant="link2">Apply</Typography>
        </Button>
      }
    />
  );
};

export default CouponField;
