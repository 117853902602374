import { Typography, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import colors from "styles/colors";
import { close } from "./assets/icons";
import * as S from "./styles";

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

// eslint-disable-next-line react/no-unused-prop-types
type OnCancelProps = { onCancel: () => void; isDisabled?: boolean };

const CloseButton = ({ onCancel }: OnCancelProps) => (
  <S.StyledCloseButton onClick={onCancel}>
    <Typography variant="link1" color={colors.CORAL[5]}>
      Close
    </Typography>
    {close}
  </S.StyledCloseButton>
);

const HeroSection = () => (
  <>
    <Typography
      textAlign="center"
      color={colors.NUDE[9]}
      variant="mk_h3"
      fontWeight={500}
      width="100%"
    >
      Interested in collaborating with us?
    </Typography>
    <Typography
      textAlign="center"
      variant="body1"
      marginTop="18px"
      marginBottom="24px"
    >
      Please complete the form below, and we will reach out to schedule a meeting and discuss tailored solutions for your organization.
    </Typography>

    <div id="hubspot-form" />
  </>
);

const RightFrame = () => <S.RightFrameContainer />;

const LeftFrame = () => (
  <S.LeftFrameContainer>
    <HeroSection />
  </S.LeftFrameContainer>
);

const BookADemo = ({ isOpen, onClose }: Props) => {
  const theme = useTheme();

  useEffect(() => {
    if (isOpen) {
      const script = document.createElement("script");
      script.src = "//js.hsforms.net/forms/embed/v2.js";
      script.async = true;

      document.head.appendChild(script);

      script.onload = () => {
        // @ts-ignore
        window.hbspt.forms.create({
          region: "na1",
          portalId: process.env.REACT_APP_HUBSPOT_PORTAL_ID || "",
          formId: process.env.REACT_APP_HUBSPOT_PARTNERSHIP_FORM_ID || "",
          target: "#hubspot-form"
        });
      };
    }

    return () => {
      const script = document.querySelector("script[src='//js.hsforms.net/forms/embed/v2.js']");
      if (script) {
        document.head.removeChild(script);
      }

      const hubspotFormContainer = document.getElementById("hubspot-form");
      if (hubspotFormContainer) {
        hubspotFormContainer.innerHTML = "";
      }
    };
  }, [isOpen, theme]);

  return (
    <S.StyledHIHHModal open={isOpen} onClose={onClose}>
      <S.StyledContainer>
        <CloseButton onCancel={onClose} />
        <RightFrame />
        <LeftFrame />
      </S.StyledContainer>
    </S.StyledHIHHModal>
  );
};

export default BookADemo;
