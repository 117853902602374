import {
  ToggleButtonGroup as MaterialToggleButtonGroup,
  Typography,
} from "@mui/material";
import React from "react";
import styled from "styled-components";
import colors from "styles/colors";

import HIHHIconToggleButton from "./HIHHIconToggleButton";

const ErrorMessage = styled(Typography)`
  margin-left: 0;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 16px;
  color: ${colors.RED[5]};
`;

const ToggleButtonGroup = styled(MaterialToggleButtonGroup)`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

type HIHHToggleButtonGroupProps = {
  dirty?: boolean;
  errorMsg?: string;
  options: any[];
  value: any[];
  onChange: (event: any, newValue: string) => void;
};

const HIHHIconToggleButtonGroup: React.FC<HIHHToggleButtonGroupProps> = ({
  dirty = false,
  errorMsg = "",
  value = [],
  options,
  onChange,
}) => {
  const handleSelection = (
    event: React.MouseEvent<HTMLElement>,
    newValue: string
  ) => {
    onChange(event, newValue);
  };

  const isActive = (option) => value?.includes(option.value);

  return (
    <>
      <ToggleButtonGroup value={value} onChange={handleSelection}>
        {options?.map((option) => (
          <HIHHIconToggleButton
            key={option.id}
            active={isActive(option)}
            {...option}
          />
        ))}
      </ToggleButtonGroup>
      {dirty && errorMsg && <ErrorMessage>{errorMsg}</ErrorMessage>}
    </>
  );
};

export default HIHHIconToggleButtonGroup;
