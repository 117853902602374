import React from "react";
import colors from "styles/colors";

type IconProps = {
  color?: string;
};

const defaultColor = colors.NUDE[5];

export const UserIcon = ({ color = defaultColor }: IconProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 15C10.1325 15 8.56394 16.2798 8.12343 18.0101C7.98717 18.5453 7.55228 19 7 19C6.44772 19 5.99127 18.5489 6.08226 18.0042C6.55661 15.1644 9.02554 13 12 13C14.9745 13 17.4434 15.1644 17.9177 18.0042C18.0087 18.5489 17.5523 19 17 19C16.4477 19 16.0128 18.5453 15.8766 18.0101C15.4361 16.2798 13.8675 15 12 15Z"
      fill={color}
    />
    <circle cx="12" cy="8.5" r="3.5" stroke={color} strokeWidth="2" />
  </svg>
);

export const LockIcon = ({ color = defaultColor }: IconProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="6"
      y="12"
      width="12"
      height="8"
      rx="1"
      strokeWidth="2"
      stroke={color}
    />
    <path
      d="M8 10V8C8 5.79086 9.79086 4 12 4V4C14.2091 4 16 5.79086 16 8V10"
      strokeWidth="2"
      strokeLinecap="square"
      strokeLinejoin="round"
      stroke={color}
    />
  </svg>
);

export const OpenEyeIcon = ({ color = defaultColor }: IconProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 6C7.2 6 4.66667 10 4 12C4.66667 14 7.2 18 12 18C16.8 18 19.3333 14 20 12C19.3333 10 16.8 6 12 6Z"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      stroke={color}
    />
    <circle cx="12" cy="12" r="2" strokeWidth="2" stroke={color} />
  </svg>
);

export const CloseEyeIcon = ({ color = defaultColor }: IconProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.30176 13.3127C9.78866 14.3117 10.814 15 12.0001 15C13.6569 15 15.0001 13.6569 15.0001 12C15.0001 10.8139 14.3118 9.7886 13.3128 9.30169L11.4492 11.1653C11.6072 11.0608 11.7965 11 12.0001 11C12.5523 11 13.0001 11.4477 13.0001 12C13.0001 12.5523 12.5523 13 12.0001 13C11.4478 13 11.0001 12.5523 11.0001 12C11.0001 11.7964 11.0609 11.6071 11.1653 11.4491L9.30176 13.3127Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.5297 5.35605C13.7585 5.13005 12.9156 5 12 5C9.29581 5 7.22602 6.1344 5.76149 7.52477C4.31396 8.89901 3.43106 10.5445 3.05132 11.6838C2.98289 11.889 2.98289 12.111 3.05132 12.3162C3.32155 13.1269 3.84657 14.194 4.64854 15.2372L6.07749 13.8083C5.60929 13.1617 5.27385 12.521 5.06511 12C5.40715 11.1463 6.08939 9.97122 7.13851 8.97523C8.30732 7.8656 9.90419 7 12 7C12.2888 7 12.5681 7.01643 12.8381 7.04768L14.5297 5.35605ZM7.42774 15.2865C8.56642 16.2676 10.0721 17 12 17C14.0958 17 15.6927 16.1344 16.8615 15.0248C17.9106 14.0288 18.5929 12.8537 18.9349 12C18.5929 11.1463 17.9106 9.97122 16.8615 8.97523C16.338 8.47828 15.7287 8.03027 15.0272 7.68704L16.5026 6.21158C17.1543 6.59461 17.7325 7.04441 18.2385 7.52477C19.686 8.89901 20.5689 10.5445 20.9487 11.6838C21.0171 11.889 21.0171 12.111 20.9487 12.3162C20.5689 13.4555 19.686 15.101 18.2385 16.4752C16.774 17.8656 14.7042 19 12 19C9.44561 19 7.45727 17.9878 6.01044 16.7038L7.42774 15.2865Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.7071 3.29289C21.0976 3.68342 21.0976 4.31658 20.7071 4.70711L4.70711 20.7071C4.31658 21.0976 3.68342 21.0976 3.29289 20.7071C2.90237 20.3166 2.90237 19.6834 3.29289 19.2929L19.2929 3.29289C19.6834 2.90237 20.3166 2.90237 20.7071 3.29289Z"
      fill={color}
    />
  </svg>
);

export const ChevronDownIcon = ({ color = defaultColor }: IconProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 10L11.2929 13.2929C11.6834 13.6834 12.3166 13.6834 12.7071 13.2929L16 10"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ChevronRightIcon = ({ color = defaultColor }: IconProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 16L14.2929 12.7071C14.6834 12.3166 14.6834 11.6834 14.2929 11.2929L11 8"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ChevronLeftIcon = ({ color = defaultColor }: IconProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13 8L9.70711 11.2929C9.31658 11.6834 9.31658 12.3166 9.70711 12.7071L13 16"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ChevronUpIcon = ({ color = defaultColor }: IconProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 13L12.7071 9.70711C12.3166 9.31658 11.6834 9.31658 11.2929 9.70711L8 13"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const PlusIcon = ({ color = defaultColor }: IconProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 7V17"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17 12L7 12"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const MinusIcon = ({ color = defaultColor }: IconProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M8 12H16" stroke={color} strokeWidth="2" strokeLinecap="round" />
  </svg>
);

export const TrashIcon = ({ color = defaultColor }: IconProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 9L7.75196 17.2716C7.89244 18.8168 9.18803 20 10.7396 20H13.2604C14.812 20 16.1076 18.8168 16.248 17.2716L17 9"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5 8H19"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 6V6C10 4.89543 10.8954 4 12 4V4C13.1046 4 14 4.89543 14 6V6"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const CrossIcon = ({ color = defaultColor }: IconProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.5356 8.46447L8.46458 15.5355"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.5356 15.5355L8.46458 8.46447"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const CheckIcon = ({ color = defaultColor }: IconProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.63604 12.364L9.75736 14.4853C10.1479 14.8758 10.781 14.8758 11.1716 14.4853L16.8284 8.82843"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const DoctorIcon = ({ color = defaultColor }: IconProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 3C13 3.55228 12.5523 3.99999 12 4C10.9957 4 10.2143 4.87301 10.3252 5.87122L10.5985 8.3313C10.7673 9.85059 12.0515 11 13.5802 11H15H16.4198C17.9485 11 19.2327 9.85059 19.4015 8.33129L19.6748 5.87122C19.7858 4.87301 19.0044 4 18 4C18 4 18 4 18 4C17.4477 4 17 3.55228 17 3C17 2.44772 17.4477 2 18 2C18 2 18 2 18 2C20.1964 2 21.9052 3.90914 21.6626 6.09208L21.3893 8.55216C21.1079 11.0843 18.9676 13 16.4198 13H16V16.5C16 19.5376 13.5376 22 10.5 22C7.54126 22 5.12819 19.6637 5.00494 16.7353C4.40442 16.3902 4 15.7423 4 15C4 13.8954 4.89543 13 6 13C7.10457 13 8 13.8954 8 15C8 15.7373 7.60108 16.3813 7.00732 16.7282C7.12487 18.5548 8.64366 20 10.5 20C12.433 20 14 18.433 14 16.5V13H13.5802C11.0324 13 8.89212 11.0843 8.61077 8.55216L8.33743 6.09208C8.09488 3.90914 9.80364 2 12 2C12 2 12 2 12 2M13 3C13 2.44772 12.5523 2.00001 12 2L13 3Z"
      fill={color}
    />
  </svg>
);

export const MobileDoctorIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.66699 1.99967C8.66699 2.36786 8.36852 2.66633 8.00034 2.66634C7.33077 2.66634 6.80985 3.24835 6.88379 3.91382L7.06602 5.55387C7.17856 6.56674 8.03469 7.33301 9.05378 7.33301H10.0003H10.9469C11.966 7.33301 12.8221 6.56674 12.9347 5.55387L13.1169 3.91382C13.1908 3.24835 12.6699 2.66634 12.0003 2.66634C12.0003 2.66634 12.0003 2.66634 12.0003 2.66634C11.6321 2.66634 11.3337 2.36786 11.3337 1.99967C11.3337 1.63148 11.6321 1.33301 12.0003 1.33301C12.0003 1.33301 12.0003 1.33301 12.0003 1.33301C13.4646 1.33301 14.6038 2.60577 14.4421 4.06106L14.2598 5.70111C14.0723 7.38922 12.6454 8.66634 10.9469 8.66634H10.667V10.9997C10.667 13.0247 9.02537 14.6663 7.00033 14.6663C5.02783 14.6663 3.41912 13.1088 3.33695 11.1565C2.9366 10.9265 2.66699 10.4946 2.66699 9.99967C2.66699 9.26329 3.26395 8.66634 4.00033 8.66634C4.73671 8.66634 5.33366 9.26329 5.33366 9.99967C5.33366 10.4912 5.06771 10.9206 4.67187 11.1518C4.75024 12.3696 5.76277 13.333 7.00033 13.333C8.28899 13.333 9.33366 12.2883 9.33366 10.9997V8.66634H9.05378C7.35529 8.66634 5.92841 7.38922 5.74084 5.70111L5.55861 4.06106C5.39691 2.60577 6.53609 1.33301 8.00034 1.33301C8.00033 1.33301 8.00033 1.33301 8.00033 1.33301M8.66699 1.99967C8.66699 1.63149 8.36852 1.33301 8.00034 1.33301L8.66699 1.99967Z"
      fill="#DCA592"
    />
  </svg>
);

export const CheckedCheckbox = ({ color = defaultColor }: IconProps) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 6C2 3.79086 3.79086 2 6 2H14C16.2091 2 18 3.79086 18 6V14C18 16.2091 16.2091 18 14 18H6C3.79086 18 2 16.2091 2 14V6Z"
      fill="white"
    />
    <path
      d="M6 3H14V1H6V3ZM17 6V14H19V6H17ZM14 17H6V19H14V17ZM3 14V6H1V14H3ZM6 17C4.34315 17 3 15.6569 3 14H1C1 16.7614 3.23858 19 6 19V17ZM17 14C17 15.6569 15.6569 17 14 17V19C16.7614 19 19 16.7614 19 14H17ZM14 3C15.6569 3 17 4.34315 17 6H19C19 3.23858 16.7614 1 14 1V3ZM6 1C3.23858 1 1 3.23858 1 6H3C3 4.34315 4.34315 3 6 3V1Z"
      fill={color}
    />
    <rect x="6" y="6" width="8" height="8" rx="1" fill={color} />
  </svg>
);

export const DefaultCheckbox = ({ color = defaultColor }: IconProps) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 6C2 3.79086 3.79086 2 6 2H14C16.2091 2 18 3.79086 18 6V14C18 16.2091 16.2091 18 14 18H6C3.79086 18 2 16.2091 2 14V6Z"
      fill="white"
    />
    <path
      d="M6 3H14V1H6V3ZM17 6V14H19V6H17ZM14 17H6V19H14V17ZM3 14V6H1V14H3ZM6 17C4.34315 17 3 15.6569 3 14H1C1 16.7614 3.23858 19 6 19V17ZM17 14C17 15.6569 15.6569 17 14 17V19C16.7614 19 19 16.7614 19 14H17ZM14 3C15.6569 3 17 4.34315 17 6H19C19 3.23858 16.7614 1 14 1V3ZM6 1C3.23858 1 1 3.23858 1 6H3C3 4.34315 4.34315 3 6 3V1Z"
      fill={color}
    />
  </svg>
);

export const LocationIcon = ({ color = defaultColor }: IconProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19 10.3636C19 16.0909 12 21 12 21C12 21 5 16.0909 5 10.3636C5 8.41068 5.7375 6.53771 7.05025 5.15676C8.36301 3.77581 10.1435 3 12 3C13.8565 3 15.637 3.77581 16.9497 5.15676C18.2625 6.53771 19 8.41068 19 10.3636Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 13C13.6569 13 15 11.6569 15 10C15 8.34315 13.6569 7 12 7C10.3431 7 9 8.34315 9 10C9 11.6569 10.3431 13 12 13Z"
      fill={color}
    />
  </svg>
);

export const FavoriteIcon = ({ color = colors.CORAL[5] }: IconProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 5C6.48273 5 5.19332 5.57405 4.29911 6.63084C3.4212 7.66838 3 9.07913 3 10.6429C3 13.514 5.39919 17.5172 11.6358 19.9313C11.6587 19.9403 11.682 19.9484 11.7056 19.9557C11.9035 20.0169 12.108 20.0133 12.2944 19.9557C12.318 19.9484 12.3413 19.9403 12.3642 19.9313C18.6008 17.5172 21 13.514 21 10.6429C21 9.07913 20.5788 7.66838 19.7009 6.63084C18.8067 5.57405 17.5173 5 16 5C14.2749 5 12.8788 5.7565 12 6.46805C11.1212 5.7565 9.72508 5 8 5ZM12.7071 8.56438C12.7364 8.53515 12.7636 8.50445 12.7885 8.47248C13.1773 8.00059 14.4088 7 16 7C16.9827 7 17.6933 7.35452 18.1741 7.92273C18.6712 8.51019 19 9.42087 19 10.6429C19 12.3765 17.4672 15.694 12 17.924C6.53282 15.694 5 12.3765 5 10.6429C5 9.42087 5.3288 8.51019 5.82589 7.92273C6.30668 7.35452 7.01727 7 8 7C9.59122 7 10.8227 8.00059 11.2115 8.47248C11.2364 8.50445 11.2636 8.53515 11.2929 8.56438C11.4853 8.75723 11.7416 8.85702 12 8.85718C12.2584 8.85702 12.5147 8.75723 12.7071 8.56438Z"
      fill={color}
    />
  </svg>
);

export const FavoriteEmptyIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.8181 19.4657L11.8164 19.4651C5.69825 17.0969 3.5 13.2381 3.5 10.6429C3.5 9.16434 3.89822 7.8787 4.68081 6.95381C5.47153 6.01932 6.61615 5.5 8 5.5C9.57726 5.5 10.8657 6.19299 11.6854 6.85664C11.8688 7.00519 12.1312 7.00519 12.3146 6.85664C13.1343 6.19299 14.4227 5.5 16 5.5C17.3839 5.5 18.5285 6.01932 19.3192 6.95381C20.1018 7.8787 20.5 9.16434 20.5 10.6429C20.5 13.2381 18.3018 17.0969 12.1836 19.4651L12.1819 19.4657C12.1707 19.4701 12.1592 19.4742 12.1476 19.4777C12.0537 19.5069 11.9517 19.5086 11.8525 19.4778L11.8524 19.4777C11.8408 19.4742 11.8293 19.4701 11.8181 19.4657Z"
      stroke="#636973"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ShareIcon = ({ color = colors.CORAL[5] }: IconProps) => (
  <svg
    width="20"
    height="17"
    viewBox="0 0 20 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.0333 5V6H12.0333V5H11.0333ZM11.0333 11H12.0333V10H11.0333V11ZM11.8753 14.2107L12.5592 14.9402L11.8753 14.2107ZM18.1109 7.63523L18.7949 6.90569L18.1109 7.63523ZM18.1109 8.36477L18.7949 9.09431L18.1109 8.36477ZM11.8753 1.78935L11.1914 2.51888L11.8753 1.78935ZM1.86838 15.5219L2.65085 16.1446L1.86838 15.5219ZM2.51023 15.4342C2.60111 12.9321 3.29656 10.5593 4.66166 8.83515C5.99866 7.14651 8.03748 6 11.0333 6V4C7.45173 4 4.82545 5.40637 3.09364 7.59365C1.38994 9.74543 0.61289 12.5715 0.511549 15.3616L2.51023 15.4342ZM12.0333 5V2.15412H10.0333V5H12.0333ZM11.1914 2.51888L17.427 8.36477L18.7949 6.90569L12.5592 1.05981L11.1914 2.51888ZM17.427 7.63523L11.1914 13.4811L12.5592 14.9402L18.7949 9.09431L17.427 7.63523ZM12.0333 13.8459V11H10.0333V13.8459H12.0333ZM11.0333 10C9.24785 10 7.52211 10.2424 5.84452 10.9983C4.16403 11.7556 2.599 12.9979 1.08592 14.8992L2.65085 16.1446C4.0046 14.4435 5.32797 13.4248 6.66622 12.8218C8.00737 12.2174 9.43068 12 11.0333 12V10ZM11.1914 13.4811C11.5107 13.1817 12.0333 13.4082 12.0333 13.8459H10.0333C10.0333 15.159 11.6012 15.8383 12.5592 14.9402L11.1914 13.4811ZM17.427 8.36477C17.2163 8.16723 17.2163 7.83277 17.427 7.63523L18.7949 9.09431C19.427 8.50169 19.427 7.4983 18.7949 6.90569L17.427 8.36477ZM12.0333 2.15412C12.0333 2.59184 11.5107 2.81826 11.1914 2.51888L12.5592 1.05981C11.6012 0.161686 10.0333 0.840957 10.0333 2.15412H12.0333ZM0.511549 15.3616C0.465807 16.621 1.99478 16.969 2.65085 16.1446L1.08592 14.8992C1.31592 14.6102 1.68321 14.5455 1.96615 14.6425C2.25679 14.742 2.52462 15.0382 2.51023 15.4342L0.511549 15.3616Z"
      fill={color}
    />
  </svg>
);

export const EmailIcon = ({ color = colors.CORAL[5] }: IconProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 5C4.34315 5 3 6.34315 3 8V17C3 18.6569 4.34315 20 6 20H18C19.6569 20 21 18.6569 21 17V8C21 6.34315 19.6569 5 18 5H6ZM5 8C5 7.44772 5.44772 7 6 7H18C18.5523 7 19 7.44772 19 8V8.44589L12.5302 12.4895C12.2059 12.6922 11.7945 12.6922 11.4702 12.4895L5 8.44563V8ZM5 10.8041V17C5 17.5523 5.44772 18 6 18H18C18.5523 18 19 17.5523 19 17V10.8044L13.5902 14.1855C12.6174 14.7935 11.383 14.7935 10.4102 14.1855L5 10.8041Z"
      fill={color}
    />
  </svg>
);

export const WebsiteIcon = ({ color = colors.CORAL[5] }: IconProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.9381 13C19.5689 15.9604 17.5822 18.4185 14.8903 19.4619C15.1721 19.0173 15.4293 18.5394 15.6579 18.0441C16.3383 16.5698 16.8272 14.8114 16.9623 13H19.9381ZM12 19.8108C11.3972 19.3238 10.7261 18.4369 10.158 17.2059C9.58894 15.973 9.17531 14.5038 9.0442 13H14.9558C14.8247 14.5038 14.4111 15.973 13.842 17.2059C13.2739 18.4369 12.6028 19.3238 12 19.8108ZM9.10967 19.4619C6.41776 18.4185 4.43105 15.9604 4.06189 13H7.0377C7.17282 14.8114 7.66167 16.5698 8.34209 18.0441C8.57072 18.5394 8.8279 19.0173 9.10967 19.4619ZM9.0442 11C9.17531 9.49625 9.58894 8.02705 10.158 6.79406C10.7261 5.56311 11.3972 4.67619 12 4.18918C12.6028 4.67619 13.2739 5.56311 13.842 6.79406C14.4111 8.02705 14.8247 9.49625 14.9558 11H9.0442ZM7.0377 11H4.06189C4.43105 8.03957 6.41776 5.58148 9.10968 4.53807C8.8279 4.98271 8.57072 5.46058 8.34209 5.95594C7.66167 7.4302 7.17282 9.18859 7.0377 11ZM19.9381 11H16.9623C16.8272 9.18859 16.3383 7.4302 15.6579 5.95594C15.4293 5.46058 15.1721 4.98271 14.8903 4.53807C17.5822 5.58148 19.5689 8.03957 19.9381 11ZM22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
      fill={color}
    />
  </svg>
);

export const PhoneIcon = ({ color = colors.CORAL[5] }: IconProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.7778 16.5358C18.7777 16.5357 18.7778 16.5358 18.7778 16.5358L18.7753 16.5326L15.9502 13.7075C15.9501 13.7076 15.9503 13.7075 15.9502 13.7075L15.9468 13.7102L13.8285 15.8285C13.3152 16.3418 12.6965 16.5012 12.1299 16.4089C11.6254 16.3268 11.2311 16.0595 11.0001 15.8285L8.17168 13.0001C7.65838 12.4868 7.49903 11.868 7.59126 11.3015C7.6734 10.7969 7.94068 10.4027 8.17168 10.1717L10.2927 8.05069C10.2926 8.05063 10.2927 8.05075 10.2927 8.05069L10.29 8.04732L7.46493 5.22226C7.46486 5.22231 7.46499 5.22222 7.46493 5.22226L7.46157 5.22492C6.73046 5.95708 5.82626 7.248 5.4855 8.68731C5.15871 10.0677 5.34005 11.5827 6.75747 13.0001L10.9995 17.2421L10.293 17.9498L9.58589 18.6569L5.34326 14.4143C3.36656 12.4376 3.0765 10.1814 3.5393 8.22655C3.98768 6.33264 5.13208 4.72598 6.05036 3.8077C6.28137 3.57669 6.67562 3.30941 7.18016 3.22728C7.74671 3.13505 8.36549 3.2944 8.87879 3.8077L11.7072 6.63613C11.9382 6.86713 12.2055 7.26138 12.2876 7.76592C12.3799 8.33247 12.2205 8.95125 11.7072 9.46455L9.58891 11.5829L9.58638 11.586C9.58634 11.5861 9.58643 11.586 9.58638 11.586L12.4113 14.4113L12.4145 14.4138C12.4145 14.4139 12.4144 14.4138 12.4145 14.4138L14.5356 12.293C14.7666 12.062 15.1609 11.7947 15.6654 11.7126C16.232 11.6203 16.8508 11.7797 17.3641 12.293L20.1925 15.1214C20.4235 15.3524 20.6908 15.7467 20.7729 16.2512C20.8651 16.8178 20.7058 17.4365 20.1925 17.9498L20.1834 17.9589C19.81 18.3324 18.4468 19.6959 16.5607 20.3908C15.5934 20.7471 14.4578 20.9399 13.2361 20.7116C12.0072 20.4821 10.77 19.841 9.58589 18.6569L10.293 17.9498L10.9995 17.2421C11.9367 18.1793 12.8214 18.5996 13.6034 18.7457C14.3925 18.8931 15.1572 18.7764 15.8693 18.5141C17.3258 17.9775 18.4301 16.8834 18.7778 16.5358Z"
      fill={color}
    />
  </svg>
);

type MapMarkerIconProps = {
  width?: string;
  height?: string;
};

export const MapMarkerIcon = ({
  width = "36",
  height = "36",
}: MapMarkerIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 8.36364C15 14.0909 8 19 8 19C8 19 1 14.0909 1 8.36364C1 6.41068 1.7375 4.53771 3.05025 3.15676C4.36301 1.77581 6.14348 1 8 1C9.85652 1 11.637 1.77581 12.9497 3.15676C14.2625 4.53771 15 6.41068 15 8.36364Z"
      stroke={defaultColor}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill={width ? "white" : defaultColor}
    />
    <path
      d="M8 11C9.65685 11 11 9.65685 11 8C11 6.34315 9.65685 5 8 5C6.34315 5 5 6.34315 5 8C5 9.65685 6.34315 11 8 11Z"
      fill={width ? "#DCA592" : defaultColor}
    />
  </svg>
);

export const InsuranceIcon = ({ color = defaultColor }: IconProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 9.5V14.5"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.5 12L9.5 12"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.7071 3.29289C13.5556 4.14139 16.3122 6 20 6C20.5523 6 21 6.44772 21 7C21 10.6734 20.0791 13.6243 18.5158 15.9353C16.9562 18.2408 14.7975 19.8502 12.4103 20.9119C12.4037 20.9149 12.397 20.9178 12.3903 20.9207C12.1492 21.0229 11.8688 21.0306 11.6097 20.9207C11.603 20.9178 11.5963 20.9149 11.5897 20.9119C9.20251 19.8502 7.0438 18.2408 5.48422 15.9353C3.92091 13.6243 3 10.6734 3 7C3 6.44772 3.44772 6 4 6C7.68783 6 10.4444 4.14139 11.2929 3.29289C11.6834 2.90237 12.3166 2.90237 12.7071 3.29289ZM12 18.8984C13.9408 17.9658 15.6297 16.6322 16.8592 14.8147C18.0418 13.0665 18.8321 10.8224 18.9762 7.95971C15.837 7.7124 13.3621 6.37552 12 5.32141C10.6379 6.37552 8.16298 7.7124 5.02376 7.95971C5.16786 10.8224 5.95818 13.0665 7.14078 14.8147C8.37027 16.6322 10.0592 17.9658 12 18.8984Z"
      fill={color}
    />
  </svg>
);

export const RaceIcon = ({ color = defaultColor }: IconProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="16" cy="8" r="4" stroke={color} strokeWidth="2" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 15.3333C13.8213 15.3333 11.9913 16.8264 11.4773 18.8451C11.3184 19.4696 10.811 20 10.1667 20V20C9.52233 20 8.98981 19.4737 9.09597 18.8382C9.64938 15.5251 12.5298 13 16 13C19.4702 13 22.3506 15.5251 22.904 18.8382C23.0102 19.4737 22.4777 20 21.8333 20V20C21.189 20 20.6816 19.4696 20.5227 18.8451C20.0087 16.8264 18.1787 15.3333 16 15.3333Z"
      fill={color}
    />
    <mask
      id="path-3-outside-1_13884_123193"
      maskUnits="userSpaceOnUse"
      x="-0.9375"
      y="4"
      width="17"
      height="19"
      fill="black"
    >
      <rect fill="white" x="-0.9375" y="4" width="17" height="19" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00076 15C10.7622 15 13.0008 12.7614 13.0008 10C13.0008 7.23858 10.7622 5 8.00076 5C5.23934 5 3.00076 7.23858 3.00076 10C3.00076 12.7614 5.23934 15 8.00076 15ZM1.07123 21.003C1.55518 17.6092 4.47326 15 8.00076 15C11.5283 15 14.4463 17.6092 14.9303 21.003C15.0083 21.5497 14.553 22 14.0008 22H2.00076C1.44848 22 0.993262 21.5497 1.07123 21.003Z"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.00076 15C10.7622 15 13.0008 12.7614 13.0008 10C13.0008 7.23858 10.7622 5 8.00076 5C5.23934 5 3.00076 7.23858 3.00076 10C3.00076 12.7614 5.23934 15 8.00076 15ZM1.07123 21.003C1.55518 17.6092 4.47326 15 8.00076 15C11.5283 15 14.4463 17.6092 14.9303 21.003C15.0083 21.5497 14.553 22 14.0008 22H2.00076C1.44848 22 0.993262 21.5497 1.07123 21.003Z"
      fill={color}
    />
    <path
      d="M1.07123 21.003L2.06121 21.1441L2.06121 21.1441L1.07123 21.003ZM14.9303 21.003L13.9403 21.1441L13.9403 21.1441L14.9303 21.003ZM12.0008 10C12.0008 12.2091 10.2099 14 8.00076 14V16C11.3145 16 14.0008 13.3137 14.0008 10H12.0008ZM8.00076 6C10.2099 6 12.0008 7.79086 12.0008 10H14.0008C14.0008 6.68629 11.3145 4 8.00076 4V6ZM4.00076 10C4.00076 7.79086 5.79163 6 8.00076 6V4C4.68706 4 2.00076 6.68629 2.00076 10H4.00076ZM8.00076 14C5.79163 14 4.00076 12.2091 4.00076 10H2.00076C2.00076 13.3137 4.68706 16 8.00076 16V14ZM8.00076 14C3.96848 14 0.634466 16.9823 0.0812446 20.8618L2.06121 21.1441C2.4759 18.2362 4.97805 16 8.00076 16V14ZM15.9203 20.8618C15.3671 16.9823 12.0331 14 8.00076 14V16C11.0235 16 13.5256 18.2362 13.9403 21.1441L15.9203 20.8618ZM14.0008 23C15.0603 23 16.0978 22.107 15.9203 20.8618L13.9403 21.1441C13.9375 21.1242 13.939 21.0966 13.9485 21.0686C13.9574 21.0423 13.9701 21.0249 13.9793 21.0152C13.9958 20.9979 14.0054 21 14.0008 21V23ZM2.00076 23H14.0008V21H2.00076V23ZM0.0812446 20.8618C-0.0963181 22.107 0.941255 23 2.00076 23V21C1.99612 21 2.0057 20.9979 2.0222 21.0152C2.03148 21.0249 2.04414 21.0423 2.05305 21.0686C2.06254 21.0966 2.06406 21.1242 2.06121 21.1441L0.0812446 20.8618Z"
      fill="white"
      mask="url(#path-3-outside-1_13884_123193)"
    />
  </svg>
);

export const SearchIcon = ({ color = defaultColor }: IconProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 11C6 8.23858 8.23858 6 11 6C13.7614 6 16 8.23858 16 11C16 13.7614 13.7614 16 11 16C8.23858 16 6 13.7614 6 11ZM11 4C7.13401 4 4 7.13401 4 11C4 14.866 7.13401 18 11 18C12.5759 18 14.0302 17.4792 15.2001 16.6004C15.2281 16.6376 15.259 16.6733 15.2929 16.7071L17.7929 19.2071C18.1834 19.5976 18.8166 19.5976 19.2071 19.2071C19.5976 18.8166 19.5976 18.1834 19.2071 17.7929L16.7071 15.2929C16.6733 15.259 16.6376 15.2281 16.6004 15.2001C17.4792 14.0302 18 12.5759 18 11C18 7.13401 14.866 4 11 4Z"
      fill={color}
    />
  </svg>
);

export const EditIcon = ({ color = colors.CORAL[5] }: IconProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.34315 17.9999H6V16.6567C6 16.1263 6.21071 15.6176 6.58579 15.2425L5.17157 13.8283L6.58579 15.2425L12.2071 9.62117L14.3787 11.7927L8.75736 17.4141C8.38229 17.7892 7.87358 17.9999 7.34315 17.9999ZM15.7929 10.3785L17.0858 9.08568C17.6855 8.48601 17.6854 7.51377 17.0858 6.9141C16.4861 6.31444 15.5139 6.31444 14.9142 6.9141L13.6214 8.20696L15.7929 10.3785ZM13.5 5.49989L5.17157 13.8283C4.42143 14.5785 4 15.5959 4 16.6567V18.9999C4 19.5522 4.44772 19.9999 5 19.9999H7.34315C8.40401 19.9999 9.42143 19.5785 10.1716 18.8283L18.5 10.4999C19.8807 9.11918 19.8807 6.8806 18.5 5.49989C17.1193 4.11918 14.8807 4.11918 13.5 5.49989Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.0001 18L11.1717 19.8284C11.1124 19.8877 11.0515 19.9449 10.989 20L19.0001 20V18H13.0001Z"
      fill={color}
    />
  </svg>
);

export const UploadIcon = ({ color = defaultColor }: IconProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 12L12.7071 8.70711C12.3166 8.31658 11.6834 8.31658 11.2929 8.70711L8 12"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 10L12 20"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M4 9L4.35689 7.21554C4.73083 5.34584 6.37249 4 8.27922 4H15.7208C17.6275 4 19.2692 5.34584 19.6431 7.21553L20 9"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export const DirectoryBlankStateIcon = () => (
  <svg
    width="189"
    height="189"
    viewBox="0 0 189 189"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle opacity="0.2" cx="94.5" cy="94.5" r="94.5" fill={colors.NUDE[1]} />
    <circle
      cx="87.0833"
      cy="79.0833"
      r="31"
      transform="rotate(-45 87.0833 79.0833)"
      stroke={colors.NUDE[4]}
      strokeWidth="6"
    />
    <path
      d="M108.756 79.2502C108.756 74.4073 107.158 69.6997 104.21 65.8575C101.261 62.0153 97.1278 59.2533 92.4499 57.9999"
      stroke="#E4D8EA"
      strokeWidth="4"
      strokeLinecap="round"
    />
    <path
      d="M108.296 100.297L123.853 115.853"
      stroke={colors.NUDE[4]}
      strokeWidth="3"
      strokeLinecap="round"
    />
    <path
      d="M117.489 109.489L134.459 126.459"
      stroke={colors.NUDE[4]}
      strokeWidth="7"
      strokeLinecap="round"
    />
    <path
      d="M58.7505 153.501L79.7505 153.501"
      stroke="#E4D8EA"
      strokeWidth="4"
      strokeLinecap="round"
    />
    <path
      d="M88.7505 153.501L138.75 153.501"
      stroke="#E4D8EA"
      strokeWidth="4"
      strokeLinecap="round"
    />
  </svg>
);

export const BurgerIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 9H18"
      stroke="#DCA592"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 15H18"
      stroke="#DCA592"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const FiltersIcon = ({ color = colors.CORAL[5] }: IconProps) => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 0.25C8.41421 0.25 8.75 0.585786 8.75 1V11.1454C9.48296 11.4421 10 12.1607 10 13C10 13.8393 9.48296 14.5579 8.75 14.8546V16.25C8.75 16.6642 8.41421 17 8 17C7.58579 17 7.25 16.6642 7.25 16.25V14.8546C6.51704 14.5579 6 13.8393 6 13C6 12.1607 6.51704 11.4421 7.25 11.1454V1C7.25 0.585786 7.58579 0.25 8 0.25ZM2 1C2.41421 1 2.75 1.33579 2.75 1.75V3.14538C3.48296 3.44207 4 4.16066 4 5C4 5.83934 3.48296 6.55793 2.75 6.85462V16.25C2.75 16.6642 2.41421 17 2 17C1.58579 17 1.25 16.6642 1.25 16.25V6.85462C0.517042 6.55793 0 5.83934 0 5C0 4.16066 0.517042 3.44207 1.25 3.14538V1.75C1.25 1.33579 1.58579 1 2 1ZM14 1C14.4142 1 14.75 1.33579 14.75 1.75V6.14538C15.483 6.44207 16 7.16066 16 8C16 8.83934 15.483 9.55793 14.75 9.85462V16.25C14.75 16.6642 14.4142 17 14 17C13.5858 17 13.25 16.6642 13.25 16.25V9.85462C12.517 9.55793 12 8.83934 12 8C12 7.16066 12.517 6.44207 13.25 6.14538V1.75C13.25 1.33579 13.5858 1 14 1Z"
      fill={color}
    />
  </svg>
);

export const LogoutIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 8L10.7071 11.2929C10.3166 11.6834 10.3166 12.3166 10.7071 12.7071L14 16"
      stroke="#636973"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 12L20 12"
      stroke="#636973"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M9 19L6 19C4.89543 19 4 18.1046 4 17L4 7C4 5.89543 4.89543 5 6 5L9 5"
      stroke="#636973"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export const DrawerMenuIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 9H18"
      stroke={colors.CORAL[5]}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 15H18"
      stroke={colors.CORAL[5]}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const EmptyStateFavoriteFilledIcon = () => (
  <svg
    width="189"
    height="189"
    viewBox="0 0 18 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 0C3.48273 0 2.19332 0.574054 1.29911 1.63084C0.4212 2.66838 0 4.07913 0 5.64286C0 8.51397 2.39921 12.5173 8.63588 14.9314C8.65832 14.9401 8.68108 14.9481 8.70411 14.9553C8.90301 15.0171 9.10856 15.0134 9.29589 14.9553C9.31892 14.9481 9.34168 14.9401 9.36412 14.9314C15.6008 12.5173 18 8.51397 18 5.64286C18 4.07913 17.5788 2.66838 16.7009 1.63084C15.8067 0.574054 14.5173 0 13 0C11.2749 0 9.87879 0.756496 9 1.46805C8.12121 0.756496 6.72508 0 5 0Z"
      fill={colors.CORAL[5]}
    />
  </svg>
);

export const FavoriteFilledIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 5C6.48273 5 5.19332 5.57405 4.29911 6.63084C3.4212 7.66838 3 9.07913 3 10.6429C3 13.514 5.39921 17.5173 11.6359 19.9314C11.6583 19.9401 11.6811 19.9481 11.7041 19.9553C11.903 20.0171 12.1086 20.0134 12.2959 19.9553C12.3189 19.9481 12.3417 19.9401 12.3641 19.9314C18.6008 17.5173 21 13.514 21 10.6429C21 9.07913 20.5788 7.66838 19.7009 6.63084C18.8067 5.57405 17.5173 5 16 5C14.2749 5 12.8788 5.7565 12 6.46805C11.1212 5.7565 9.72508 5 8 5Z"
      fill={colors.CORAL[5]}
    />
  </svg>
);

export const ContentArrowRight = ({ color = colors.CORAL[5] }: IconProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.2931 14.5929C12.9026 14.9834 12.9026 15.6166 13.2931 16.0071C13.6836 16.3976 14.3168 16.3976 14.7073 16.0071L17.2931 13.4213C18.0742 12.6403 18.0742 11.3739 17.2931 10.5929L14.7073 8.00709C14.3168 7.61657 13.6836 7.61657 13.2931 8.00709C12.9026 8.39762 12.9026 9.03078 13.2931 9.42131L14.8718 11L7.00023 11C6.44794 11 6.00023 11.4477 6.00023 12C6.00023 12.5523 6.44794 13 7.00023 13L14.886 13L13.2931 14.5929Z"
      fill={color}
    />
  </svg>
);

export const BlogArrowLeft = () => (
  <svg
    width="12"
    height="10"
    viewBox="0 0 12 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.70688 2.40706C5.0974 2.01653 5.0974 1.38337 4.70688 0.992844C4.31636 0.60232 3.68319 0.60232 3.29267 0.992844L0.70688 3.57863C-0.0741692 4.35968 -0.0741675 5.62601 0.70688 6.40706L3.29267 8.99284C3.68319 9.38337 4.31636 9.38337 4.70688 8.99285C5.0974 8.60232 5.0974 7.96916 4.70688 7.57863L3.1282 5.99995H10.9998C11.5521 5.99995 11.9998 5.55224 11.9998 4.99995C11.9998 4.44767 11.5521 3.99995 10.9998 3.99995H3.11398L4.70688 2.40706Z"
      fill={colors.CORAL[5]}
    />
  </svg>
);

export const SimpleLocationIcon = () => (
  <svg
    width="11"
    height="14"
    viewBox="0 0 11 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.24899 13.2377C1.55978 7.88946 0.875 7.34056 0.875 5.375C0.875 2.6826 3.0576 0.5 5.75 0.5C8.44239 0.5 10.625 2.6826 10.625 5.375C10.625 7.34056 9.94021 7.88946 6.25101 13.2377C6.00891 13.5874 5.49107 13.5874 5.24899 13.2377ZM5.75 7.40625C6.87183 7.40625 7.78125 6.49683 7.78125 5.375C7.78125 4.25317 6.87183 3.34375 5.75 3.34375C4.62816 3.34375 3.71875 4.25317 3.71875 5.375C3.71875 6.49683 4.62816 7.40625 5.75 7.40625Z"
      fill="#636973"
    />
  </svg>
);

export const UpdateIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.4393 4.15372C11.9911 3.84504 13.5997 4.00347 15.0615 4.60897C16.5233 5.21447 17.7727 6.23985 18.6518 7.55544C19.5308 8.87103 20 10.4178 20 12C20 12.5523 19.5523 13 19 13C18.4477 13 18 12.5523 18 12C18 10.8133 17.6481 9.65328 16.9888 8.66658C16.3295 7.67989 15.3925 6.91085 14.2961 6.45673C13.1997 6.0026 11.9933 5.88378 10.8295 6.11529C9.66557 6.3468 8.59648 6.91825 7.75736 7.75736L7.75042 7.76424L8.36837 7.75502C8.92059 7.74678 9.37494 8.18777 9.38317 8.73999C9.39141 9.29221 8.95043 9.74656 8.3982 9.7548L5.39854 9.79955C4.84631 9.80778 4.39197 9.3668 4.38373 8.81457L4.33898 5.81491C4.33074 5.26268 4.77173 4.80834 5.32395 4.8001C5.87618 4.79186 6.33052 5.23285 6.33876 5.78507L6.34703 6.33927C7.46526 5.22257 8.88922 4.46205 10.4393 4.15372ZM6.36855 7.7819L6.37168 7.78481L6.36859 7.78485L6.36855 7.7819ZM13.5607 19.8463C12.0089 20.155 10.4003 19.9965 8.93853 19.391C7.47672 18.7855 6.22729 17.7602 5.34824 16.4446C4.46919 15.129 4 13.5823 4 12C4 11.4477 4.44771 11 5 11C5.55229 11 6 11.4477 6 12C6 13.1867 6.35189 14.3467 7.01118 15.3334C7.67047 16.3201 8.60754 17.0892 9.7039 17.5433C10.8003 17.9974 12.0067 18.1162 13.1705 17.8847C14.3344 17.6532 15.4035 17.0818 16.2426 16.2426L16.2496 16.2358L15.6316 16.245C15.0794 16.2532 14.6251 15.8122 14.6168 15.26C14.6086 14.7078 15.0496 14.2534 15.6018 14.2452L18.6015 14.2005C19.1537 14.1922 19.608 14.6332 19.6163 15.1854L19.661 18.1851C19.6693 18.7373 19.2283 19.1917 18.676 19.1999C18.1238 19.2081 17.6695 18.7672 17.6612 18.2149L17.653 17.6607C16.5347 18.7774 15.1108 19.538 13.5607 19.8463ZM17.6315 16.2181L17.6283 16.2152L17.6314 16.2152L17.6315 16.2181Z"
      fill={colors.CORAL[5]}
    />
  </svg>
);

export const LikeIcon = () => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="36" height="36" rx="18" fill="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 11C12.4827 11 11.1933 11.5741 10.2991 12.6308C9.4212 13.6684 9 15.0791 9 16.6429C9 19.514 11.3992 23.5173 17.6359 25.9314C17.6583 25.9401 17.6811 25.9481 17.7041 25.9553C17.903 26.0171 18.1086 26.0134 18.2959 25.9553C18.3189 25.9481 18.3417 25.9401 18.3641 25.9314C24.6008 23.5173 27 19.514 27 16.6429C27 15.0791 26.5788 13.6684 25.7009 12.6308C24.8067 11.5741 23.5173 11 22 11C20.2749 11 18.8788 11.7565 18 12.4681C17.1212 11.7565 15.7251 11 14 11Z"
      fill="#F18A8D"
    />
  </svg>
);

export const FlagIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 12V5C5 5 5.5 4 8.5 4C10.0683 4 11.0901 4.54659 12.0653 5.06828C12.9555 5.54451 13.8069 6 15 6C16.3628 6 17.3129 5.79365 17.944 5.56841C18.3635 5.41869 19 5.76165 19 6.20711V15.1716C19 15.702 18.7797 16.2113 18.2955 16.4279C17.6882 16.6995 16.6447 17 15 17C13.8069 17 12.9555 16.5445 12.0653 16.0683C11.0901 15.5466 10.0683 15 8.5 15C7.90767 15 7.4128 15.039 7 15.1016V19C7 19.5523 6.55228 20 6 20C5.44772 20 5 19.5523 5 19V16V12ZM7 12V13.0835C7.45107 13.0294 7.94932 13 8.5 13C10.5146 13 11.8795 13.7024 12.8787 14.2353C13.8831 14.771 14.3269 15 15 15C15.9283 15 16.5757 14.8878 17 14.7702V7.85354C16.4171 7.94506 15.7532 8 15 8C13.2903 8 12.0365 7.3247 11.1691 6.85749C11.1097 6.82549 11.0521 6.79447 10.9963 6.76471C10.1205 6.29758 9.48538 6 8.5 6C7.79811 6 7.31651 6.06412 7 6.1342V12Z"
      fill="#636973"
    />
  </svg>
);

export const CalendarIcon = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="48" height="48" rx="24" fill="#F18A8D" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 16C19.4477 16 19 16.4477 19 17V18H18C16.8954 18 16 18.8954 16 20V30C16 31.1046 16.8954 32 18 32H30C31.1046 32 32 31.1046 32 30V20C32 18.8954 31.1046 18 30 18H29V17C29 16.4477 28.5523 16 28 16C27.4477 16 27 16.4477 27 17V18H21V17C21 16.4477 20.5523 16 20 16ZM18 30V22H30V30H18Z"
      fill="white"
    />
  </svg>
);

export const BriefCaseXSIcon = ({ color = colors.NUDE[5] }: IconProps) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.5 4.5H2.5C2.22386 4.5 2 4.72386 2 5V13C2 13.2761 2.22386 13.5 2.5 13.5H13.5C13.7761 13.5 14 13.2761 14 13V5C14 4.72386 13.7761 4.5 13.5 4.5Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.5 4.5V3.5C10.5 3.23478 10.3946 2.98043 10.2071 2.79289C10.0196 2.60536 9.76522 2.5 9.5 2.5H6.5C6.23478 2.5 5.98043 2.60536 5.79289 2.79289C5.60536 2.98043 5.5 3.23478 5.5 3.5V4.5"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14 7.89355C12.1764 8.94819 10.1066 9.50231 8 9.4998C5.89303 9.50529 3.82242 8.95097 2 7.89355"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.25 7.5H8.75"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const BriefCaseSmallIcon = ({ color = colors.NUDE[8] }: IconProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.25 6.75H3.75C3.33579 6.75 3 7.08579 3 7.5V19.5C3 19.9142 3.33579 20.25 3.75 20.25H20.25C20.6642 20.25 21 19.9142 21 19.5V7.5C21 7.08579 20.6642 6.75 20.25 6.75Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.75 6.75V5.25C15.75 4.85218 15.592 4.47064 15.3107 4.18934C15.0294 3.90804 14.6478 3.75 14.25 3.75H9.75C9.35218 3.75 8.97064 3.90804 8.68934 4.18934C8.40804 4.47064 8.25 4.85218 8.25 5.25V6.75"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21 11.8398C18.2646 13.4218 15.1599 14.253 12 14.2492C8.83955 14.2574 5.73363 13.426 3 11.8398"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.875 11.25H13.125"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const BriefCaseIcon = ({ color = colors.GREY[7] }: IconProps) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M27 9H5C4.44772 9 4 9.44772 4 10V26C4 26.5523 4.44772 27 5 27H27C27.5523 27 28 26.5523 28 26V10C28 9.44772 27.5523 9 27 9Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21 9V7C21 6.46957 20.7893 5.96086 20.4142 5.58579C20.0391 5.21071 19.5304 5 19 5H13C12.4696 5 11.9609 5.21071 11.5858 5.58579C11.2107 5.96086 11 6.46957 11 7V9"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28 15.7875C24.3529 17.8968 20.2132 19.005 16 19C11.7861 19.0109 7.64484 17.9023 4 15.7875"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.5 15H17.5"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const DesktopXSIcon = ({ color = colors.NUDE[5] }: IconProps) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 12L13 12C13.5523 12 14 11.5523 14 11L14 4C14 3.44771 13.5523 3 13 3L3 3C2.44771 3 2 3.44771 2 4L2 11C2 11.5523 2.44771 12 3 12Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 14H6"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2 9.5H14"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 12V14"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const DesktopSmallIcon = ({ color = colors.NUDE[8] }: IconProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.5 18L19.5 18C20.3284 18 21 17.3284 21 16.5V6C21 5.17157 20.3284 4.5 19.5 4.5L4.5 4.5C3.67157 4.5 3 5.17157 3 6V16.5C3 17.3284 3.67157 18 4.5 18Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15 21H9"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3 14.25H21"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 18V21"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const DesktopIcon = ({ color = colors.GREY[7] }: IconProps) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 24L26 24C27.1046 24 28 23.1046 28 22L28 8C28 6.89543 27.1046 6 26 6L6 6C4.89543 6 4 6.89543 4 8L4 22C4 23.1046 4.89543 24 6 24Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20 28H12"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4 19H28"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16 24V28"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const HomeXSIcon = ({ color = colors.NUDE[5] }: IconProps) => (
  <svg
    width="12"
    height="13"
    viewBox="0 0 12 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.5 12.0001V9.00013C7.5 8.86752 7.44732 8.74035 7.35355 8.64658C7.25979 8.55281 7.13261 8.50013 7 8.50013H5C4.86739 8.50013 4.74021 8.55281 4.64645 8.64658C4.55268 8.74035 4.5 8.86752 4.5 9.00013V12.0001C4.5 12.1327 4.44732 12.2599 4.35355 12.3537C4.25979 12.4475 4.13261 12.5001 4 12.5001H1C0.867392 12.5001 0.740215 12.4475 0.646447 12.3537C0.552679 12.2599 0.5 12.1327 0.5 12.0001V6.21888C0.50112 6.14969 0.516073 6.08141 0.543981 6.01808C0.571889 5.95475 0.612186 5.89765 0.6625 5.85013L5.6625 1.30638C5.75467 1.22206 5.87507 1.17529 6 1.17529C6.12493 1.17529 6.24533 1.22206 6.3375 1.30638L11.3375 5.85013C11.3878 5.89765 11.4281 5.95475 11.456 6.01808C11.4839 6.08141 11.4989 6.14969 11.5 6.21888V12.0001C11.5 12.1327 11.4473 12.2599 11.3536 12.3537C11.2598 12.4475 11.1326 12.5001 11 12.5001H8C7.86739 12.5001 7.74021 12.4475 7.64645 12.3537C7.55268 12.2599 7.5 12.1327 7.5 12.0001Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const HomeSmallIcon = ({ color = colors.NUDE[8] }: IconProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.25 19.5009V15.0009C14.25 14.802 14.171 14.6112 14.0303 14.4706C13.8897 14.3299 13.6989 14.2509 13.5 14.2509H10.5C10.3011 14.2509 10.1103 14.3299 9.96967 14.4706C9.82902 14.6112 9.75 14.802 9.75 15.0009V19.5009C9.75 19.6998 9.67098 19.8906 9.53033 20.0313C9.38968 20.1719 9.19891 20.2509 9 20.2509H4.5C4.30109 20.2509 4.11032 20.1719 3.96967 20.0313C3.82902 19.8906 3.75 19.6998 3.75 19.5009V10.8291C3.75168 10.7253 3.77411 10.6229 3.81597 10.5279C3.85783 10.4329 3.91828 10.3472 3.99375 10.2759L11.4937 3.46031C11.632 3.33382 11.8126 3.26367 12 3.26367C12.1874 3.26367 12.368 3.33382 12.5062 3.46031L20.0062 10.2759C20.0817 10.3472 20.1422 10.4329 20.184 10.5279C20.2259 10.6229 20.2483 10.7253 20.25 10.8291V19.5009C20.25 19.6998 20.171 19.8906 20.0303 20.0313C19.8897 20.1719 19.6989 20.2509 19.5 20.2509H15C14.8011 20.2509 14.6103 20.1719 14.4697 20.0313C14.329 19.8906 14.25 19.6998 14.25 19.5009Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const HomeIcon = ({ color = colors.GREY[7] }: IconProps) => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 23V17C15 16.7348 14.8946 16.4804 14.7071 16.2929C14.5196 16.1054 14.2652 16 14 16H10C9.73478 16 9.48043 16.1054 9.29289 16.2929C9.10536 16.4804 9 16.7348 9 17V23C9 23.2652 8.89464 23.5196 8.70711 23.7071C8.51957 23.8947 8.26522 24 8 24H2C1.73478 24 1.48043 23.8947 1.29289 23.7071C1.10536 23.5196 1 23.2652 1 23V11.4375C1.00224 11.2991 1.03215 11.1626 1.08796 11.0359C1.14378 10.9093 1.22437 10.7951 1.325 10.7L11.325 1.61252C11.5093 1.44387 11.7501 1.35034 12 1.35034C12.2499 1.35034 12.4907 1.44387 12.675 1.61252L22.675 10.7C22.7756 10.7951 22.8562 10.9093 22.912 11.0359C22.9679 11.1626 22.9978 11.2991 23 11.4375V23C23 23.2652 22.8946 23.5196 22.7071 23.7071C22.5196 23.8947 22.2652 24 22 24H16C15.7348 24 15.4804 23.8947 15.2929 23.7071C15.1054 23.5196 15 23.2652 15 23Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const RoundedCheckIcon = ({ color = colors.CORAL[5] }: IconProps) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="7.99998" cy="7.99998" r="6.66667" fill={color} />
    <path
      d="M5.09067 8.24264L6.50489 9.65685C6.76523 9.9172 7.18735 9.9172 7.44769 9.65685L11.2189 5.88562"
      stroke="white"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const LanguageIcon = ({ color = colors.GREY[7] }: IconProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2 12H22"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 2C14.5013 4.73835 15.9228 8.29203 16 12C15.9228 15.708 14.5013 19.2616 12 22C9.49872 19.2616 8.07725 15.708 8 12C8.07725 8.29203 9.49872 4.73835 12 2V2Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const CareProficiencyIcon = ({ color = colors.GREY[6] }: IconProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 15H3.75C3.55109 15 3.36032 14.921 3.21967 14.7803C3.07902 14.6397 3 14.4489 3 14.25V9.75C3 9.55109 3.07902 9.36032 3.21967 9.21967C3.36032 9.07902 3.55109 9 3.75 9H9V3.75C9 3.55109 9.07902 3.36032 9.21967 3.21967C9.36032 3.07902 9.55109 3 9.75 3H14.25C14.4489 3 14.6397 3.07902 14.7803 3.21967C14.921 3.36032 15 3.55109 15 3.75V9H20.25C20.4489 9 20.6397 9.07902 20.7803 9.21967C20.921 9.36032 21 9.55109 21 9.75V14.25C21 14.4489 20.921 14.6397 20.7803 14.7803C20.6397 14.921 20.4489 15 20.25 15H15V20.25C15 20.4489 14.921 20.6397 14.7803 20.7803C14.6397 20.921 14.4489 21 14.25 21H9.75C9.55109 21 9.36032 20.921 9.21967 20.7803C9.07902 20.6397 9 20.4489 9 20.25V15Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const CareDeliveryIcon = ({ color = colors.GREY[6] }: IconProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 7H4C2.89543 7 2 7.89543 2 9V19C2 20.1046 2.89543 21 4 21H20C21.1046 21 22 20.1046 22 19V9C22 7.89543 21.1046 7 20 7Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.5078 6.10938V5.32583C16.5078 4.73799 16.2708 4.17422 15.8488 3.75856C15.4268 3.34289 14.8545 3.10938 14.2578 3.10938H9.75781C9.16108 3.10938 8.58878 3.34289 8.16682 3.75856C7.74487 4.17422 7.50781 4.73799 7.50781 5.32583V6.10938"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect x="11" y="10" width="2" height="8" rx="1" fill={color} />
    <rect
      x="16"
      y="13"
      width="2"
      height="8"
      rx="1"
      transform="rotate(90 16 13)"
      fill={color}
    />
  </svg>
);

export const VideoPlayIcon = ({ color = colors.NUDE[8] }: IconProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 8L16 12L10 16V8Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const PlayIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
      fill="#F18A8D"
    />
    <path d="M10 8L16 12L10 16V8Z" fill="#FCE8E8" />
  </svg>
);

export const TagIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.7273 8.94163L8.94732 13.7216C8.82349 13.8456 8.67644 13.9439 8.51457 14.011C8.35271 14.0781 8.17921 14.1127 8.00398 14.1127C7.82876 14.1127 7.65526 14.0781 7.4934 14.011C7.33153 13.9439 7.18448 13.8456 7.06065 13.7216L1.33398 8.00163V1.33496H8.00065L13.7273 7.06163C13.9757 7.31144 14.115 7.64938 14.115 8.00163C14.115 8.35388 13.9757 8.69181 13.7273 8.94163V8.94163Z"
      stroke="#635452"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.66602 4.66504H4.6731"
      stroke="#635452"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
