import { Typography } from "@mui/material";
import api from "api";
import { addToast } from "components/common/HIHHToast";
import { CheckIcon } from "components/common/icons";
import { Container } from "components/styled";
import { User } from "hooks/useUserInfo";
import React from "react";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "routes";
import { SESSION_ROUTES } from "routes/Session";
import styled from "styled-components";
import colors from "styles/colors";
import { v4 as uuidv4 } from "uuid";

import PlanCTA from "./PlanCTA";
import Price, { PlanPrice } from "./Price";

const PlanContainer = styled<any>(Container)`
  align-items: start;
  background-color: ${colors.WHITE};
  border-radius: 8px;
  border: 1px solid ${colors.GREY[1]};
  flex-direction: column;
  height: 615px;
  justify-content: space-between;
  padding: 30px;
  position: relative;
  width: 380px;

  @media all and (max-width: 900px) {
    width: 400px;
  }

  @media all and (max-width: 600px) {
    height: 500px;
    width: 422px;
  }

  @media all and (max-width: 480px) {
    height: ${(props) => (props.isFree ? "580px" : "680px")};
    width: 275px;
  }

  @media all and (max-width: 320px) {
    height: 700px;
    width: 245px;
  }
`;

const ColorLine = styled.div<any>`
  height: 6px;
  background-color: ${(props) => props.bgColor};
  border-radius: 8px 8px 0 0;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;

const InfoContainer = styled.div`
  width: 100%;
`;

const ComingSoon = styled(Typography)`
  @media all and (max-width: 480px) {
    font-size: 2rem;
  }
`;

export const MEMBERSHIP_REDIRECTED_KEY = "membership-redirected";

type PlanCardProps = {
  description: string;
  hidePrice: boolean;
  isAuthenticated: boolean;
  isOnProviderPage: boolean;
  name: string;
  prices: PlanPrice[];
  subscriptionPlan: any;
  user: User | null;
  openWaitListModal: (open: boolean) => void;
};

const PlanCard = ({
  description,
  hidePrice,
  isAuthenticated,
  isOnProviderPage,
  name,
  prices,
  subscriptionPlan,
  user,
  openWaitListModal,
}: PlanCardProps) => {
  const multiplePrices = prices.length > 1;
  const isFreePlan = prices[0].amount === 0;
  const navigate = useNavigate();

  const titleUI = (
    <Container
      justifyContent="flex-start"
      flexWrap="nowrap"
      margin="0 0 12px 0"
      width="100%"
    >
      <Typography
        variant="subtitle2"
        id={multiplePrices ? "plan-prices-radio-buttons-group" : ""}
        color={colors.NUDE[9]}
        style={{ letterSpacing: "1px" }}
      >
        {name}
      </Typography>
    </Container>
  );

  const descriptionUI = (
    <Container direction="column" margin="28px 0 0 0" gap="16px 0">
      {description.split(",").map((text) => (
        <Container
          key={`${text.length} ${uuidv4()}`}
          justifyContent="flex-start"
          alignItems="start"
          gap="16px"
        >
          <CheckIcon />
          <Typography
            variant="body1"
            color={colors.GREY[6]}
            style={{ flex: 1 }}
          >
            {text}
          </Typography>
        </Container>
      ))}
    </Container>
  );

  const getPlanLabel = () => {
    const hasToJoinWaitList = isOnProviderPage && !isFreePlan;

    if (!isAuthenticated) {
      if (hasToJoinWaitList) return "Join the Waitlist";
      return "Get Started";
    }

    const isPremiumSubscription = subscriptionPlan.name === "Premium";
    const isFreeSubscription = subscriptionPlan.name === "Free";

    if (hasToJoinWaitList) return "Join the Waitlist";

    if (isFreeSubscription && isFreePlan) return "Current Plan";

    if (isPremiumSubscription && !isFreePlan) return "Current Plan";
    if (isPremiumSubscription && isFreePlan) return "Downgrade Plan";

    return "Upgrade Plan";
  };

  const addUserToWaitList = async () => {
    if (user) {
      await api.post("/user_waiting_lists", {
        email: user.email,
        name: user.first_name,
      });
      addToast("You were successfully added to the wait list.", "success");
    }
  };

  const handleClick = () => {
    if (isOnProviderPage) {
      if (isFreePlan) {
        navigate(`/users${SESSION_ROUTES.SIGN_UP}`);
      }
      if (!isFreePlan) {
        if (isAuthenticated) {
          addUserToWaitList();
          return;
        }
        openWaitListModal(true);
        return;
      }

      return;
    }

    if (!isAuthenticated) {
      localStorage.setItem(MEMBERSHIP_REDIRECTED_KEY, "true");
      navigate(`/users${SESSION_ROUTES.SIGN_UP}`);
      return;
    }

    if (user && user.role === "provider") {
      addToast(
        "This is a individual's only feature, please log in as a member",
        "error"
      );
      return;
    }

    navigate(`${APP_ROUTES.accountSettings}?tabIndexValue=${2}`);
  };

  return (
    <PlanContainer isFree={isFreePlan}>
      <ColorLine bgColor={isFreePlan ? colors.PURPLE[4] : colors.YELLOW[5]} />
      <InfoContainer>
        {titleUI}
        {hidePrice ? (
          <ComingSoon variant="mk_h2">Coming Soon</ComingSoon>
        ) : (
          <Price planPrice={prices} />
        )}
        {descriptionUI}
      </InfoContainer>
      <PlanCTA
        isDisabled={isOnProviderPage && isFreePlan && isAuthenticated}
        variant={isFreePlan ? "outlined" : "contained"}
        label={getPlanLabel()}
        onClick={handleClick}
      />
    </PlanContainer>
  );
};

export default PlanCard;
