/**
 * Place here al styled components that are commonly used, avoid code duplication.
 */

import { Button, Grid, Typography } from "@mui/material";
import styled from "styled-components";
import colors from "styles/colors";

type AuthButtonProps = {
  disabled?: boolean;
};

type ContainerProps = {
  alignSelf?: "flex-start" | "flex-end" | "center" | "strech";
  direction?: "column" | "column-reverse" | "row-reverse" | "row";
  flexWrap?: "nowrap" | "wrap-reverse";
  justifyContent?: "flex-start" | "flex-end" | "space-between" | "space-around";
  alignItems?: "start" | "end" | "stretch";
  width?: string;
  padding?: string;
  margin?: string;
  maxWidth?: string;
  gap?: string;
  height?: string;
  overflowY?: string;
  minHeight?: string;
  minWidth?: string;
  maxHeight?: string;
  ref?: any;
};

export const Container = styled.div<ContainerProps>`
  align-self: ${(props) => props.alignSelf};
  display: flex;
  flex-direction: ${(props) => props.direction || "row"};
  flex-wrap: ${(props) => props.flexWrap || "wrap"};
  gap: ${(props) => props.gap || 0};
  height: ${(props) => props.height};
  justify-content: ${(props) => props.justifyContent || "center"};
  align-items: ${(props) => props.alignItems || "center"};
  margin: ${(props) => props.margin || "0"};
  max-height: ${(props) => props.maxHeight};
  max-width: ${(props) => props.maxWidth};
  min-height: ${(props) => props.minHeight};
  min-width: ${(props) => props.minWidth};
  overflow-y: ${(props) => props.overflowY};
  padding: ${(props) => props.padding || "0"};
  width: ${(props) => props.width || "100%"};

  &.page-layout {
    width: 82%;
    margin: 0 auto;
    max-width: 1200px;

    @media all and (max-width: 1200px) {
      width: 92%;
    }

    @media all and (max-width: 400px) {
      width: 96%;
    }
  }
`;

export const ContainerWithTopShadow = styled(Container)`
  @media all and (max-width: 767px) {
    box-shadow: 0px -4px 12px rgb(88 66 58 / 6%);
    border-radius: 30px 30px 0 0;
    margin-top: 10px;
  }
`;

export const Banner = styled.img`
  bottom: 0;
  left: 50%;
  height: 75%;
  object-fit: contain;
  position: absolute;
  transform: translateX(-50%);
  width: auto;
`;

export const BannerBackground = styled.div<{ background: string }>`
  background-image: ${(props) => `url(${props.background})`};
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
`;

export const BannerLogo = styled.img`
  left: 32px;
  position: absolute;
  top: 32px;
`;

export const BannerContainer = styled(Grid)`
  background-color: ${colors.CORAL[5]};
`;

export const Logo = styled.img`
  cursor: pointer;
  @media only screen and (max-height: 800px) {
    margin-top: 20px;
  }
`;

export const OnboardingMobileLogo = styled(Logo)`
  margin-top: 0;
  @media all and (min-width: 900px) {
    display: none;
  }
`;

export const AuthButton = styled(Button)<AuthButtonProps>`
  border: 1px solid ${colors.GREY[6]};
  background-color: ${colors.WHITE};

  svg {
    fill: ${({ disabled }) => (disabled ? colors.GREY[2] : colors.GREY[6])};
  }

  &:hover {
    box-shadow: 0px 4px 4px -2px rgba(74, 79, 86, 0.07);
    border: 1px solid ${colors.GREY[5]};
    background-color: ${colors.GREY[1]};

    svg {
      fill: ${colors.GREY[5]};
    }
  }

  &:focus {
    border: 3px solid ${colors.GREY[1]};
    background-color: ${colors.WHITE};
  }

  &:active {
    border: 1px solid ${colors.GREY[6]};
    background-color: ${colors.GREY[2]};
  }

  &:disabled {
    && {
      border: 1px solid ${colors.GREY[1]};
    }
  }
`;

export const ProviderProfileSectionContainer = styled(Grid)`
  border: 1px solid rgba(48, 28, 28, 0.1);
  border-radius: 10px;
  padding: 40px;
  box-shadow: 0px 8px 16px -4px rgba(74, 79, 86, 0.05);
  z-index: 2;
  align-items: flex-start;

  @media all and (max-width: 480px) {
    padding: 20px 16px 28px 16px;
  }
`;

export const ProviderProfileSectionTitle = styled<any>(Typography)`
  &.section-title {
    @media all and (max-width: 700px) {
      max-width: ${(props) => (props.editIcon ? "350px" : "none")};
    }
    @media all and (max-width: 600px) {
      font-size: 1.75rem;
      line-height: 40px;
      max-width: ${(props) => (props.editIcon ? "300px" : "none")};
    }
    @media all and (max-width: 500px) {
      max-width: ${(props) => (props.editIcon ? "250px" : "none")};
    }
    @media all and (max-width: 350px) {
      max-width: ${(props) => (props.editIcon ? "220px" : "none")};
    }
  }
`;

export const Chip = styled.div`
  border-radius: 8px;
  height: 32px;
  overflow: hidden;
  padding: 4px 12px;
`;

export const CounterBadge = styled.div`
  align-items: center;
  background: ${colors.CORAL[5]};
  border-radius: 50%;
  color: ${colors.WHITE};
  display: flex;
  height: 20px;
  justify-content: center;
  text-align: center;
  width: 20px;
`;

export const Rectangle = styled.div<any>`
  background-color: #d79b87;
  height: ${(props) => (props.height ? props.height : "71px")};
  margin-bottom: ${(props) => props.marginBottom};
  width: 3px;

  @media all and (max-width: 425px) {
    display: none;
  }
`;

export const ButtonLink = styled(Button)`
  &.MuiButton-root {
    border-bottom: 1px solid transparent;
    border-radius: 0;
    height: auto;
    margin: 20px 0;
    padding: 0;
    transition: all ease-in-out 0.3s;

    &:hover {
      background-color: ${colors.WHITE};
      border-bottom: 1px solid ${colors.CORAL[4]};
      box-shadow: none;
      color: ${colors.CORAL[4]};
      transition: all ease-in-out 0.3s;
    }

    &:focus,
    &:active {
      background-color: ${colors.WHITE};
      border: none;
      border-bottom: 1px solid ${colors.CORAL[6]};
      color: ${colors.CORAL[6]};
      transition: all ease-in-out 0.3s;
    }
  }
`;

export const HIHHRoundLogo = styled.img`
  height: 88px;
  width: 88px;
  margin-bottom: 36px;
  margin-top: 42px;

  @media all and (max-width: 750px) {
    margin-bottom: 34px;
  }
`;

export const StylessAnchor = styled.a`
  text-decoration: none;
  text-align: center;
`;

export const LinkButton = styled(Button)`
  &.link-button {
    padding: 0;
    color: ${colors.GREY[6]};

    :hover,
    :focus,
    :active {
      text-decoration: underline;
      background-color: transparent;
      color: ${colors.GREY[6]};
      box-shadow: none;
      border: none;
    }
  }
`;

export const MarketingImage = styled.img`
  width: 100%;
  height: 100%;
`;

type NoAvatarProfileContainerProps = {
  borderColor?: string;
  svgFill?: string;
  backgroundColor?: string;
};

export const NoAvatarProfileContainer = styled.div<NoAvatarProfileContainerProps>`
  align-items: center;
  background-color: ${(props) => props.backgroundColor};
  border: 2px solid
    ${(props) => (props.borderColor ? props.borderColor : colors.CORAL[3])};
  display: flex;
  border-radius: 50%;
  height: 100%;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;

  & > svg {
    fill: ${(props) => (props.svgFill ? props.svgFill : colors.CORAL[3])};
  }

  &:hover {
    border: 2px solid ${colors.CORAL[5]};

    & > svg {
      fill: ${colors.CORAL[5]};
    }
  }

  &.active {
    border: 2px solid ${colors.CORAL[7]};

    & > svg {
      fill: ${colors.CORAL[7]};
    }
  }
`;

export const TruncatedTypography = styled(Typography)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

type LineProps = {
  height?: string;
  width?: string;
  color?: string;
};

export const Line = styled.div<LineProps>`
  width: ${(props) => (props.width ? props.width : "143px")};
  background-color: ${(props) =>
    props.color ? props.color : "rgba(0, 0, 0, 0.31)"};
  height: ${(props) => (props.height ? props.height : "1px")};
`;

export const QuestionCardsContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 32px;
  column-gap: 21px;
  margin-bottom: 64px;

  @media (min-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1300px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;
