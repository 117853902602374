import moment from "moment";
import { CAREERS_URL, CONTENT_URL, MAIL_TO_URL } from "routes/constant";
import { User } from "hooks/useUserInfo";

const INDIVIDUAL_COMPLETED_ONBOARDING_PROCCESS_EVENT_NAME =
  "member_account_completed";
const PROVIDER_COMPLETED_ONBOARDING_PROCCESS_EVENT_NAME =
  "provider_account_completed";
const USER_CLICKED_CARE_POINT_EVENT_NAME = "user_clicked_care_point";
const USER_CLICKED_COMMUNITY_EVENT_NAME = "user_clicked_community";
const USER_CLICKED_DIRECTORY_PROVIDERS_CTA_EVENT_NAME =
  "user_clicked_directory_providers_cta";
const USER_CLICKED_REQUEST_AN_APPOINTMENT_EVENT_NAME =
  "user_clicked_request_an_appointment";
const USER_CLICKED_SHARE_BUTTON_EVENT_NAME = "user_clicked_share_button";
const USER_CLICKED_SUBMIT_QUESTION_EVENT_NAME = "user_clicked_submit_question";
const USER_CLICKED_VIDEO_RESPONSE_EVENT_NAME = "user_clicked_video_response";
const USER_LIKED_PROVIDER_EVENT_NAME = "user_liked_provider";
const USER_MARKED_AS_FAVORITE_VIDEO_RESPONSE_EVENT_NAME =
  "user_marked_as_favorite_video_response";
const USER_REGISTERED_EVENT_NAME = "user_registered";
const USER_REVIEWED_PROVIDER_EVENT_NAME = "user_reviewed_provider";
const USER_SEARCHED_PROVIDERS_EVENT_NAME = "user_searched_providers";
const USER_SHARED_ON_SOCIAL_MEDIA_EVENT_NAME = "user_shared_on_social_media";
const USER_SIGNED_IN_EVENT_NAME = "user_signed_in";
const USER_SUBMITTED_QUESTION_EVENT_NAME = "user_submitted_question";
const USER_UPDATED_PROFILE_EVENT_NAME = "user_updated_profile";
const USER_VIEWED_PAGE_EVENT_NAME = "user_viewed_page";
const USER_BECAME_PREMIUM = "premium_members";
const USER_CANCELLED_PREMIUM = "cancelled_premium_account";
const USER_CLICKED_ACCOUNT_DELETE_BUTTON = "user_clicked_account_delete_button";
const USER_STARTED_WATCHING_VIDEO_RESPONSE_EVENT_NAME =
  "user_started_watching_video_response";
const USER_ENDED_WATCHING_VIDEO_RESPONSE_EVENT_NAME =
  "user_ended_watching_video_response";
const USER_CLICKED_COMMUNITY_CTA_EVENT_NAME = "user_clicked_community_cta";
const USER_CLICKED_CARE_SQUADS_CTA_EVENT_NAME = "user_clicked_care_squads_cta";
const USER_VISITED_NEW_PAGE_AFTER_CARE_POINT_EVENT_NAME =
  "user_visited_new_page_after_care_point";
const USER_VISITED_CARE_POINT_EVENT_NAME = "user_visited_care_point";
const USER_CLICKED_LOGIN_BUTTON_EVENT_NAME = "user_clicked_login_button";
const USER_CLICKED_SIGNUP_BUTTON_EVENT_NAME = "user_clicked_signup_button";

const allowedRoutesToTrack = [
  "/",
  "/carepoint",
  "/community",
  "/directory/providers",
  "/membership/individuals",
  "/virtual-care-squads",
  "/membership/providers",
  "/health-equity-pledge",
  "/about-us",
  CAREERS_URL,
  CONTENT_URL,
  MAIL_TO_URL,
];

const addPrefixToAttributes = (prefix, attributes = {}) => {
  const newAttributes = {};

  Object.keys(attributes).forEach((key) => {
    newAttributes[`${prefix}_${key}`] = attributes[key];
  });

  return newAttributes;
};

const extractUserData = (userData) => {
  if (!userData) {
    return { anonymous: true };
  }

  const {
    id,
    email,
    first_name,
    last_name,
    role,
    races,
    pronoun,
    recurring_interval,
  } = userData;

  return {
    id,
    email,
    first_name,
    last_name,
    races: races ? races.map((race) => race.label).join(", ") : null,
    pronoun: pronoun ? pronoun.label : null,
    recurring_interval,
    role: role === "member" ? "individual" : role,
  };
};

export const fireUserClickedCarePointEvent = (userData) => {
  window.analytics.track(USER_CLICKED_CARE_POINT_EVENT_NAME, {
    ...extractUserData(userData),
  });
};

export const fireUserClickedCommunityEvent = (userData) => {
  window.analytics.track(USER_CLICKED_COMMUNITY_EVENT_NAME, {
    ...extractUserData(userData),
  });
};

export const fireUserClickedDirectoryProvidersCTAEvent = (
  userData: User | null
) => {
  window.analytics.track(USER_CLICKED_DIRECTORY_PROVIDERS_CTA_EVENT_NAME, {
    ...extractUserData(userData),
  });
};

export const fireUserClickedRequestAnAppointmentEvent = (
  provider,
  user,
  fromVideoResponse
) => {
  window.analytics.track(USER_CLICKED_REQUEST_AN_APPOINTMENT_EVENT_NAME, {
    from_video_response: !!fromVideoResponse,
    ...addPrefixToAttributes("provider", extractUserData(provider)),
    ...addPrefixToAttributes("requester", extractUserData(user)),
  });
};

export const fireUserClickedSubmitQuestionEvent = (userData: User | null) => {
  window.analytics.track(USER_CLICKED_SUBMIT_QUESTION_EVENT_NAME, {
    ...extractUserData(userData),
  });
};

export const fireUserClickedVideoResponseEvent = (
  answerId: number,
  userData: User | null
) => {
  window.analytics.track(USER_CLICKED_VIDEO_RESPONSE_EVENT_NAME, {
    answer_id: answerId,
    ...extractUserData(userData),
  });
};

export const fireUserCompletedOnboardingProcessEvent = (userData: User) => {
  window.analytics.track(
    userData.role === "provider"
      ? PROVIDER_COMPLETED_ONBOARDING_PROCCESS_EVENT_NAME
      : INDIVIDUAL_COMPLETED_ONBOARDING_PROCCESS_EVENT_NAME,
    {
      from_care_point: userData.from_care_point,
      ...extractUserData({
        ...userData,
        date_completed_profile: moment().format(),
      }),
    }
  );
};

export const fireUserLikedAProviderEvent = (provider, user) => {
  window.analytics.track(USER_LIKED_PROVIDER_EVENT_NAME, {
    ...addPrefixToAttributes("provider", extractUserData(provider)),
    ...addPrefixToAttributes("user", extractUserData(user)),
  });
};

export const fireUserRegisteredEvent = (userData) => {
  window.analytics.track(USER_REGISTERED_EVENT_NAME, {
    ...extractUserData(userData),
  });
};

export const triggerUserChangedSubscription = (
  userData: User,
  newSubscriptionStatus: string,
  recurringInterval: string
) => {
  window.analytics.track(
    newSubscriptionStatus === "cancel"
      ? USER_CANCELLED_PREMIUM
      : USER_BECAME_PREMIUM,
    {
      from_care_point: userData.from_care_point,
      ...extractUserData({
        ...userData,
        recurring_interval: recurringInterval,
      }),
    }
  );
};

export const fireUserReviewedProviderEvent = (reviewData) => {
  window.analytics.track(USER_REVIEWED_PROVIDER_EVENT_NAME, {
    ...reviewData,
  });
};

export const fireUserSearchedProvidersEvent = (
  searchParams,
  searchOptions,
  userData
) => {
  const userSearchedProvidersEventParams: any = {};
  const {
    attention_place_ids,
    care_proficiency_ids,
    insurance_ids,
    language_ids,
    pronoun_ids,
    query,
    specialty_ids,
    location: locationName,
  } = searchParams;

  const getRecordsFromIds = (stringOfIds: string, records: any[]) => {
    const ids = stringOfIds.split(",").map((id: string) => parseInt(id, 10));
    const selectedRecords = ids
      .map((id) => records.find((record: any) => record.id === id))
      .map(({ id, label }) => ({ id, label }));

    return selectedRecords;
  };

  userSearchedProvidersEventParams.name = query || "";
  userSearchedProvidersEventParams.location = locationName || "";
  userSearchedProvidersEventParams.specialties = specialty_ids
    ? getRecordsFromIds(specialty_ids, searchOptions.specialties)
    : [];
  userSearchedProvidersEventParams.insurances = insurance_ids
    ? getRecordsFromIds(insurance_ids, searchOptions.insurances)
    : [];
  userSearchedProvidersEventParams.languages = language_ids
    ? getRecordsFromIds(language_ids, searchOptions.languages)
    : [];

  userSearchedProvidersEventParams.pronouns = pronoun_ids
    ? getRecordsFromIds(pronoun_ids, searchOptions.pronouns)
    : [];

  userSearchedProvidersEventParams.care_proficiencies = care_proficiency_ids
    ? getRecordsFromIds(care_proficiency_ids, searchOptions.careProficiencies)
    : [];

  userSearchedProvidersEventParams.attention_places = attention_place_ids
    ? getRecordsFromIds(attention_place_ids, searchOptions.careDeliveries)
    : [];

  window.analytics.track(USER_SEARCHED_PROVIDERS_EVENT_NAME, {
    ...userSearchedProvidersEventParams,
    ...addPrefixToAttributes("user", extractUserData(userData)),
  });
};

export const fireUserSignedInEvent = (userData) => {
  window.analytics.track(USER_SIGNED_IN_EVENT_NAME, {
    ...extractUserData(userData),
  });
};

export const fireUserSubmittedQuestionEvent = (userData) => {
  window.analytics.track(USER_SUBMITTED_QUESTION_EVENT_NAME, {
    ...extractUserData(userData),
  });
};

export const fireUserUpdatedProfileEvent = (userData, payload) => {
  window.analytics.track(USER_UPDATED_PROFILE_EVENT_NAME, {
    ...extractUserData(userData),
    payload,
  });
};

export const fireUserViewedPageEvent = (
  userData: User | null,
  pathName: string
) => {
  if (allowedRoutesToTrack.includes(pathName)) {
    window.analytics.track(USER_VIEWED_PAGE_EVENT_NAME, {
      ...addPrefixToAttributes("user", extractUserData(userData)),
      path: pathName,
    });
  }
};

export const fireUserDeleteAccountCTAClick = (userData) => {
  window.analytics.track(USER_CLICKED_ACCOUNT_DELETE_BUTTON, {
    ...extractUserData(userData),
  });
};

export const fireUserStartedWatchingVideoResponseEvent = (
  answerId: number,
  userData: User | null
) => {
  window.analytics.track(USER_STARTED_WATCHING_VIDEO_RESPONSE_EVENT_NAME, {
    answer_id: answerId,
    ...extractUserData(userData),
  });
};

export const fireUserEndedWatchingVideoResponseEvent = (
  answerId: number,
  userData: User | null
) => {
  window.analytics.track(USER_ENDED_WATCHING_VIDEO_RESPONSE_EVENT_NAME, {
    answer_id: answerId,
    ...extractUserData(userData),
  });
};

export const fireUserMarkedAsFavoriteVideoResponseEvent = (
  answerId: number,
  userData: User | null
) => {
  window.analytics.track(USER_MARKED_AS_FAVORITE_VIDEO_RESPONSE_EVENT_NAME, {
    answer_id: answerId,
    ...extractUserData(userData),
  });
};

export const fireUserClickedShareButtonEvent = (userData: User | null) => {
  window.analytics.track(USER_CLICKED_SHARE_BUTTON_EVENT_NAME, {
    ...extractUserData(userData),
  });
};

export const fireUserSharedOnSocialMediaEvent = (
  socialMedia: string,
  userData: User | null
) => {
  window.analytics.track(USER_SHARED_ON_SOCIAL_MEDIA_EVENT_NAME, {
    social_media: socialMedia,
    ...extractUserData(userData),
  });
};

export const fireUserClickedCommunityCTAEvent = () => {
  window.analytics.track(USER_CLICKED_COMMUNITY_CTA_EVENT_NAME);
};

export const fireUserClickedCareSquadsCTAEvent = () => {
  window.analytics.track(USER_CLICKED_CARE_SQUADS_CTA_EVENT_NAME);
};

export const fireUserVisitedNewPageAfterCarePointEvent = (
  newPath: string,
  order: number,
  userData: User | null
) => {
  window.analytics.track(USER_VISITED_NEW_PAGE_AFTER_CARE_POINT_EVENT_NAME, {
    new_path: newPath,
    order,
    ...extractUserData(userData),
  });
};

export const fireUserVisitedCarePointEvent = (userData: User) => {
  const demographicInfo = {
    id: userData.id,
    location: userData.member_address.display_name,
    date_of_birthday: userData.date_of_birthday,
    email: userData.email,
    full_name: userData.full_name,
    role: userData.role,
    languages: userData.languages.map((language) => language.value).join(", "),
  };

  window.analytics.track(USER_VISITED_CARE_POINT_EVENT_NAME, {
    ...demographicInfo,
  });
};

export const fireUserClickedLoginButtonEvent = (fromCarepoint: boolean) => {
  window.analytics.track(USER_CLICKED_LOGIN_BUTTON_EVENT_NAME, {
    from_care_point: fromCarepoint,
  });
};

export const fireUserClickedSignUpButtonEvent = (fromCarepoint: boolean) => {
  window.analytics.track(USER_CLICKED_SIGNUP_BUTTON_EVENT_NAME, {
    from_care_point: fromCarepoint,
  });
};

const Analytics = () => ({
  fireUserClickedCarePointEvent,
  fireUserClickedCommunityEvent,
  fireUserClickedRequestAnAppointmentEvent,
  fireUserClickedSubmitQuestionEvent,
  fireUserClickedDirectoryProvidersCTAEvent,
  fireUserClickedVideoResponseEvent,
  fireUserCompletedOnboardingProcessEvent,
  fireUserLikedAProviderEvent,
  fireUserRegisteredEvent,
  fireUserReviewedProviderEvent,
  fireUserSearchedProvidersEvent,
  fireUserSignedInEvent,
  fireUserSubmittedQuestionEvent,
  fireUserUpdatedProfileEvent,
  fireUserViewedPageEvent,
  triggerUserChangedSubscription,
  fireUserStartedWatchingVideoResponseEvent,
  fireUserEndedWatchingVideoResponseEvent,
  fireUserMarkedAsFavoriteVideoResponseEvent,
  fireUserClickedShareButtonEvent,
  fireUserSharedOnSocialMediaEvent,
  fireUserClickedCommunityCTAEvent,
  fireUserClickedCareSquadsCTAEvent,
  fireUserVisitedNewPageAfterCarePointEvent,
  fireUserVisitedCarePointEvent,
  fireUserClickedLoginButtonEvent,
  fireUserClickedSignUpButtonEvent,
});

export default Analytics;
