import { Box, Grid } from "@mui/material";
import MemberImage from "assets/images/member-onboarding-image.png";
import MemberShapes from "assets/images/member-onboarding-shapes.svg";
import ProviderImage from "assets/images/provider-onboarding-image.png";
import ProviderShapes from "assets/images/provider-onboarding-shapes.svg";
import HIHHLogo from "assets/logos/brand/hihh-round-logo.svg";
import { Banner, BannerBackground, BannerLogo } from "components/styled";
import { useUserContext } from "components/UserContextProvider";
import React from "react";
import { Outlet } from "react-router-dom";

const OnboardingLayout = () => {
  const { user } = useUserContext();

  const isUserProvider = user?.role === "provider";

  return (
    <Grid id="layout-container" container sx={{ height: "100vh" }}>
      <Box
        component={Grid}
        item
        md={6}
        display={{ xs: "none", md: "flex", lg: "flex" }}
        sx={{
          alignItems: "flex-end",
          height: "100vh",
          overflow: "hidden",
          position: "relative",
        }}
      >
        <BannerBackground
          background={isUserProvider ? ProviderShapes : MemberShapes}
        >
          <BannerLogo src={HIHHLogo} alt="logo" />
          <Banner
            src={isUserProvider ? ProviderImage : MemberImage}
            alt="banner"
          />
        </BannerBackground>
      </Box>

      <Outlet />
    </Grid>
  );
};

export default OnboardingLayout;
