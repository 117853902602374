import React, { useState } from "react";

import { makeStyles } from "@mui/styles";
import { Typography, Tooltip, tooltipClasses } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Container } from "components/styled";
import colors from "styles/colors";

type OnboardingTooltipProps = {
  title: string;
  message: string;
  flexJustify?: any;
  padding?: string;
};

const useStyles = makeStyles(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    background: colors.NUDE[1],
    borderRadius: "8px",
    color: colors.GREY[9],
    fontSize: "12px",
    lineHeight: "16px",
    padding: "16px",
  },
}));

const OnboardingTooltip = ({
  title,
  message,
  flexJustify = "flex-start",
  padding = "0",
}: OnboardingTooltipProps) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const styles = useStyles();

  return (
    <Container justifyContent={flexJustify} gap="5px" padding={padding}>
      <Tooltip
        classes={styles}
        title={title}
        placement="top-start"
        open={isTooltipOpen}
      >
        <Typography variant="mk_caption" color={colors.CORAL[5]}>
          {message}
        </Typography>
      </Tooltip>
      <InfoOutlinedIcon
        onClick={() => setIsTooltipOpen(!isTooltipOpen)}
        onMouseEnter={() => setIsTooltipOpen(true)}
        onMouseLeave={() => setIsTooltipOpen(false)}
        style={{
          fontSize: "1rem",
          color: colors.CORAL[5],
          cursor: "pointer",
        }}
      />
    </Container>
  );
};

export default OnboardingTooltip;
