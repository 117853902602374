import React, { useContext, useState, useEffect } from "react";
import { NumberParam, useQueryParams, withDefault } from "use-query-params";

import api from "api";
import { addToast } from "./common/HIHHToast";

const DEFAULT_PAGE = 1;
const ITEMS_PER_PAGE = 20;

type State = {
  isLoading: boolean;
  favoriteProviders: any;
  queryParams: any;
  totalPages: number;
  fetchFavoriteProviders: () => Promise<void>;
  deleteFavoriteProvider: (favoriteProviderId) => Promise<void>;
  handlePageChange: (event: React.ChangeEvent<unknown>, value: number) => void;
};

const MemberFavoriteContext = React.createContext<State>({} as State);

export const MemberFavoriteContextProvider: React.FC = ({ children }) => {
  const [favoriteProviders, setFavoriteProviders] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [queryParams, setQueryParams] = useQueryParams({
    page: withDefault(NumberParam, DEFAULT_PAGE),
    items: withDefault(NumberParam, ITEMS_PER_PAGE),
  });

  const fetchFavoriteProviders = async () => {
    setIsLoading(true);
    const res = await api.get(
      `/favorite_providers?page=${queryParams.page}&items=${queryParams.items}`
    );
    setFavoriteProviders(res.data.data);
    if (res.headers["Total-Pages"] || res.headers["total-pages"]) {
      setTotalPages(
        parseInt(res.headers["Total-Pages"] || res.headers["total-pages"], 10)
      );
    }
    setIsLoading(false);
  };

  const deleteFavoriteProvider = async (favoriteProviderId) => {
    await api.delete(`/favorite_providers/${favoriteProviderId}`);
    fetchFavoriteProviders();
    addToast("This provider has been removed from your favorites.", "success");
  };

  const handlePageChange = (event, value) => {
    setQueryParams({ page: value }, "push");
  };

  useEffect(() => {
    fetchFavoriteProviders();
  }, [queryParams.page]);

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value = {
    favoriteProviders,
    totalPages,
    isLoading,
    queryParams,
    fetchFavoriteProviders,
    deleteFavoriteProvider,
    handlePageChange,
  };

  return (
    <MemberFavoriteContext.Provider value={value}>
      {children}
    </MemberFavoriteContext.Provider>
  );
};

export const useMemberFavoriteContext = () => {
  const context = useContext(MemberFavoriteContext);
  if (typeof context === "undefined") {
    throw new Error(
      "useMemberFavoriteContext must be used within a MemberFavoriteContext"
    );
  }
  return context;
};
