import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Radio,
  Stack,
  Switch,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { CheckIcon } from "components/common/icons";
import { Container, Line } from "components/styled";
import { Plan } from "hooks/Onboarding/useMembershiPlans";
import React, { useState } from "react";

import colors from "styles/colors";
import { v4 as uuidv4 } from "uuid";
import { PlanExtra } from "./PlansMember";

const Description = ({ planDescription }: { planDescription: string }) => (
  <Container
    alignItems="start"
    gap="16px 0"
    justifyContent="space-between"
    margin="16px 0 0 0"
    padding="0 16px 0 44px"
    maxWidth="483px"
    width="auto"
  >
    {planDescription.split(",").map((text) => (
      <Container
        key={`${text.length} ${uuidv4()}`}
        justifyContent="flex-start"
        alignItems="start"
        gap="9px"
        maxWidth="204px"
      >
        <CheckIcon />
        <Typography variant="body2" color={colors.GREY[6]} style={{ flex: 1 }}>
          {text}
        </Typography>
      </Container>
    ))}
  </Container>
);

type PlanAccordionProps = {
  plan: Plan & PlanExtra;
  selectedPlan?: number;
  onPlanSelect: (e: any) => void;
};

const PlanAccordion = ({
  plan: { id, name, planLabel, prices, planInterval, planBenefits },
  selectedPlan,
  onPlanSelect,
}: PlanAccordionProps) => {
  const [planPeriod, setPlanPeriod] = useState(0);
  const isMobile = useMediaQuery("(max-width:480px)");

  if (prices.length === 0) {
    return (
      <Container>
        <Typography variant="h3" color={colors.GREY[5]}>
          No memberships available
        </Typography>
      </Container>
    );
  }

  const priceUI = (
    <div>
      <Typography
        variant="mk_h4"
        style={{ fontSize: isMobile ? "20px" : "24px" }}
      >
        ${prices[planPeriod].price}
      </Typography>
    </div>
  );

  return (
    <Accordion
      sx={{
        maxWidth: "515px",
        borderTop: `4px solid ${
          name === "Premium" ? colors.YELLOW[5] : colors.GREY[2]
        }`,
        borderRadius: "8px",
        boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.24)",
        alignItems: "baseline",
        zIndex: 10,
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="membership-content"
        id={`membership-accordion-${name}`}
      >
        <Container gap="12px">
          <Container flexWrap="nowrap">
            <Container justifyContent="flex-start" gap="24px">
              <Radio
                onClick={(event) => event.stopPropagation()}
                checked={id === selectedPlan}
                onChange={(event) =>
                  onPlanSelect(parseInt(event.target.value, 10))
                }
                value={id}
                name="radio-buttons"
                inputProps={{ "aria-label": "Plan-radio-button" }}
                sx={{ zIndex: 1000, padding: isMobile ? 0 : "9px" }}
              />
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Container justifyContent="space-between" gap="24px">
                  <Typography
                    variant="mk_h4"
                    style={{ fontSize: isMobile ? "20px" : "24px" }}
                  >
                    {name}
                  </Typography>
                  {isMobile ? priceUI : null}
                </Container>

                <Typography variant="mk_caption" color={colors.GREY[6]}>
                  {planLabel}
                </Typography>
              </div>
            </Container>
            {!isMobile ? priceUI : null}
          </Container>
          {planInterval ? (
            <Container
              justifyContent="flex-start"
              padding={isMobile ? "0px 0px 0px 50px" : "0px 0px 0px 66px"}
            >
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography variant="body2">{planInterval[1]}</Typography>
                <Switch
                  onClick={(event) => event.stopPropagation()}
                  onChange={() => setPlanPeriod(planPeriod ? 0 : 1)}
                  sx={{ zIndex: 100 }}
                  defaultChecked
                  inputProps={{ "aria-label": "membership interval switch" }}
                />
                <Typography variant="body2">{planInterval[0]}</Typography>
              </Stack>
            </Container>
          ) : null}
        </Container>
      </AccordionSummary>
      <AccordionDetails>
        <Line width="100%" color={colors.GREY[1]} />
        <Description planDescription={planBenefits} />
      </AccordionDetails>
    </Accordion>
  );
};

export default PlanAccordion;
