import React, { createContext, useMemo, useState } from "react";

type State = {
  showCreateQuestionModal: boolean;
  showQuestionCreatedModal: boolean;
  setShowCreateQuestionModal: (show: boolean) => void;
  setShowQuestionCreatedModal: (show: boolean) => void;
};

const QuestionsContext = createContext<State>({} as State);

export const QuestionsContextProvider: React.FC = ({ children }) => {
  const [showCreateQuestionModal, setShowCreateQuestionModal] =
    useState<boolean>(false);
  const [showQuestionCreatedModal, setShowQuestionCreatedModal] =
    useState<boolean>(false);

  const value = useMemo(
    () => ({
      showCreateQuestionModal,
      showQuestionCreatedModal,
      setShowCreateQuestionModal,
      setShowQuestionCreatedModal,
    }),
    [showCreateQuestionModal, showQuestionCreatedModal]
  );

  return (
    <QuestionsContext.Provider value={value}>
      {children}
    </QuestionsContext.Provider>
  );
};

export const useQuestionsContext = () => {
  const context = React.useContext(QuestionsContext);
  if (typeof context === "undefined") {
    throw new Error(
      "useQuestionsContext must be used within a QuestionsContext"
    );
  }
  return context;
};
