import styled from "styled-components";

// eslint-disable-next-line import/prefer-default-export
export const StyledBenefitContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 72px;
  padding: 96px 24px 96px 24px;

  @media all and (min-width: 1200px) {
    gap: 80px;
    padding: 120px 155px 0 155px;
  }
`;
