import {
  yupRequired,
  yupRequiredObject,
  yupRequiredArrayOfObjects,
  yupRequiredDate,
} from "components/Form/validations";

type TextFieldType = "text" | "autocomplete";

export type TextFieldProps = {
  fieldPosition?: number;
  initialValue?: string | null;
  label: string;
  multiline?: boolean;
  name: string;
  type: TextFieldType;
  validation?: any;
};
export interface AutocompleteFieldProps extends TextFieldProps {
  multiple?: boolean;
  options: any[];
}

export const autocompleteFields: AutocompleteFieldProps[] = [
  {
    label: "Pronouns",
    name: "pronoun_id",
    options: [],
    type: "autocomplete",
    validation: yupRequiredObject,
  },
  {
    label: "Race and Ethnicity",
    name: "users_races_attributes",
    options: [],
    multiple: true,
    type: "autocomplete",
    validation: yupRequiredArrayOfObjects,
  },
  {
    label: "Languages",
    multiple: true,
    name: "users_languages_attributes",
    options: [],
    type: "autocomplete",
    validation: yupRequiredArrayOfObjects,
  },
];

export const textFields: TextFieldProps[] = [
  {
    initialValue: "",
    label: "First Name",
    name: "first_name",
    type: "text",
    validation: yupRequired,
  },
  {
    initialValue: "",
    label: "Last Name",
    name: "last_name",
    type: "text",
    validation: yupRequired,
  },
];

export const dateField = {
  initialValue: "",
  label: "Date of Birth",
  name: "date_of_birthday",
  type: "date",
  validation: yupRequiredDate,
};
