import { Box, Modal } from "@mui/material";
import React from "react";
import colors from "styles/colors";

type HIHHModalProps = {
  open: boolean;
  onClose: () => void;
};

const HIHHModal: React.FC<HIHHModalProps> = ({
  children,
  open,
  onClose,
  ...props
}) => (
  <Modal
    open={open}
    onClose={onClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
    {...props}
  >
    <Box
      className="modal-box"
      sx={{
        backgroundColor: colors.WHITE,
        boxShadow: "0px 8px 6px -4px rgba(74, 79, 86, 0.06)",
        borderRadius: "32px",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        outline: "none",
      }}
    >
      {children}
    </Box>
  </Modal>
);

export default HIHHModal;
