import { useFormikContext } from "formik";
import React from "react";
import { isObject } from "utils";

const FormikErrorFocus = () => {
  const { isSubmitting, isValidating, errors } = useFormikContext();

  const getKeysRecursively = (object, depth = 0) => {
    let arrayIndex;

    if (Array.isArray(object)) {
      const firstValue = object.find((el, index) => {
        if (el) {
          arrayIndex = index;
          return el;
        }
        return -1;
      });
      return `[${arrayIndex}] ${getKeysRecursively(firstValue, depth + 1)}`;
    }

    if (!isObject(object)) return "";

    const currentKey = Object.keys(object)[0];

    if (!getKeysRecursively(object[currentKey], depth + 1)) {
      return `${depth > 0 ? `.${currentKey}` : currentKey}`;
    }

    return currentKey + getKeysRecursively(object[currentKey], depth + 1);
  };

  const keys = Object.keys(errors);

  if (keys.length > 0 && isSubmitting && !isValidating) {
    const selectorKey = getKeysRecursively(errors);
    const selector = `[id="${selectorKey}"], [name="${selectorKey}"] `;
    const errorElement = document.querySelector(selector) as any;
    if (errorElement) {
      errorElement?.focus();
    }
  }

  return <div />;
};

export default FormikErrorFocus;
