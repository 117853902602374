import React from "react";
import {
  FormikAutocomplete,
  FormikGoogleMapsAutocomplete,
  FormikTextInput,
} from "components/Form/FormikFields";
import {
  yupEmailNullable,
  yupGoogleAutocompleteRequired,
  yupRequired,
  yupRequiredState,
  yupWebsiteNullable,
} from "components/Form/validations";
import { v4 as uuidv4 } from "uuid";
import states from "data/states";
import { AutocompleteOption } from "components/common/HIHHAutocomplete";

type InputProps = {
  type?: string;
};

export type TextFieldProps = {
  label: string;
  name: string;
  initialValue?: any;
  validation?: any;
  inputProps?: InputProps;
  autocomplete?: boolean;
  dropdown?: boolean;
  dependentFieldName?: string;
  hideNumberCaret?: boolean;
  multiple?: boolean;
  options?: AutocompleteOption[];
};

export const practiceMainLocationFields: TextFieldProps[] = [
  {
    label: "Address",
    name: "practice_address",
    initialValue: "",
    validation: yupGoogleAutocompleteRequired,
    autocomplete: true,
  },
  {
    label: "Apartment, Suite, etc",
    name: "practice_address_line",
    initialValue: "",
  },
  {
    label: "City",
    name: "practice_info_city",
    initialValue: "",
    validation: yupRequired,
  },
  {
    label: "State",
    name: "practice_info_state",
    initialValue: null,
    dropdown: true,
    multiple: false,
    options: states,
    validation: yupRequiredState,
  },
];

export const practiceSecondaryLocationFields: TextFieldProps[] = [
  {
    label: "Address 2",
    name: "practice_address_2",
    initialValue: "",
    autocomplete: true,
  },
  {
    label: "Apartment, Suite, etc",
    name: "practice_address_line_2",
    initialValue: "",
  },
];

export const practiceDigitalFields: TextFieldProps[] = [
  {
    label: "Practice Name (Optional)",
    name: "practice_name",
    initialValue: "",
  },
  {
    label: "Website (Optional)",
    name: "practice_website",
    initialValue: "",
    validation: yupWebsiteNullable,
  },
  {
    label: "Email (Optional)",
    name: "practice_email",
    inputProps: {
      type: "email",
    },
    initialValue: "",
    validation: yupEmailNullable,
  },
  {
    label: "Phone Number",
    name: "practice_phone_number",
    inputProps: {
      type: "number",
    },
    initialValue: "",
    hideNumberCaret: true,
    validation: yupRequired,
  },
];

export const switchField: TextFieldProps = {
  label: "Accepting new patients",
  name: "accept_new_patients",
  initialValue: true,
};

export const practiceFields = [
  ...practiceMainLocationFields,
  ...practiceSecondaryLocationFields,
  ...practiceDigitalFields,
  switchField,
];

export const practiceMainLocationFieldsUI = (
  <>
    {practiceMainLocationFields.map((field: TextFieldProps) => {
      if (field.autocomplete) {
        return (
          <FormikGoogleMapsAutocomplete
            key={`${field.name}-${uuidv4()}`}
            name={field.name}
            label={field.label}
          />
        );
      }
      if (field.dropdown) {
        return (
          <FormikAutocomplete
            key={`${field.name}-${uuidv4()}`}
            {...field}
            options={field.options!}
          />
        );
      }
      return (
        <FormikTextInput
          key={`${field.name}-${uuidv4()}`}
          type={field?.inputProps?.type || "text"}
          {...field}
        />
      );
    })}
  </>
);

export const practiceSecondaryLocationFieldsUI = (
  <>
    {practiceSecondaryLocationFields.map((field: TextFieldProps) =>
      field.autocomplete ? (
        <FormikGoogleMapsAutocomplete
          key={`${field.name}-${uuidv4()}`}
          name={field.name}
          label={field.label}
        />
      ) : (
        <FormikTextInput
          key={`${field.name}-${uuidv4()}`}
          type={field?.inputProps?.type || "text"}
          {...field}
        />
      )
    )}
  </>
);

export const practiceDigitalFieldsUI = (
  <>
    {practiceDigitalFields.map((field: any) => (
      <FormikTextInput
        key={`${field.name}-${uuidv4()}`}
        type={field?.inputProps?.type || "text"}
        {...field}
      />
    ))}
  </>
);
