import { Typography } from "@mui/material";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from "@stripe/react-stripe-js";
import { debounce } from "lodash";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import colors from "styles/colors";
import { areObjectKeysTruthy } from "utils";

import { Coupon } from "../../SubscriptionContextProvider";

const incompleteError = {
  code: "incomplete",
  type: "incomplete",
  message: "Card information required",
};

const CardElementsOptions = {
  style: {
    base: {
      fontFamily: "Nunito Sans",
      "::placeholder": {
        color: colors.GREY[3],
      },
    },
  },
};

const CardLabel = styled(Typography)`
  background-color: white;
  left: 16px;
  padding-left: 2px;
  padding-right: 3px;
  position: absolute;
  top: -10px;
  z-index: 20;
`;

const CardErrorMsg = styled(Typography)`
  left: 0;
  padding-top: 4px;
  position: absolute;
  top: 100%;
  z-index: 20;
`;

const CardFieldsContainer = styled.div`
  display: flex;
  gap: 0 8px;
  width: 100%;

  @media all and (max-width: 480px) {
    flex-direction: column;
    gap: 40px 0;
  }
`;

const FieldBaseStyles = styled.div`
  border: 1px solid ${colors.GREY[4]};
  border-radius: 4px;
  padding: 14px 12px;
  position: relative;
`;

const SmallFieldsContainer = styled.div`
  backgroundcolor: lightblue;
  display: flex;
  gap: 0 8px;
  width: 40%;
  min-width: 194px;

  @media all and (max-width: 480px) {
    width: 100%;
  }
`;

const CardNumberField = styled(FieldBaseStyles)`
  width: 60%;

  @media all and (max-width: 480px) {
    width: auto;
  }
`;

const SmallField = styled(FieldBaseStyles)`
  width: 50%;
`;

type CardFieldsTypes = {
  coupon: Coupon | null;
  setCardError: React.SetStateAction<any>;
  setIsCardFieldComplete: React.SetStateAction<any>;
};

type StripeFieldError = {
  code: string;
  message: string;
  type: string;
};

type ErrorFields = {
  cardCvc: StripeFieldError | null;
  cardExpiry: StripeFieldError | null;
  cardNumber: StripeFieldError | null;
};

const CardFields = ({
  coupon,
  setCardError,
  setIsCardFieldComplete,
}: CardFieldsTypes): JSX.Element => {
  const [cardCompletion, setCardCompletion] = useState({
    cardNumber: false,
    cardExpiry: false,
    cardCvc: false,
  });

  const [cardErrors, setCardErrors] = useState<ErrorFields>({
    cardCvc: null,
    cardExpiry: null,
    cardNumber: null,
  });

  const cardHandling = (e: any) => {
    if (e.error) {
      setCardErrors({ ...cardErrors, [e.elementType]: e.error });
    } else if (!e.complete) {
      setCardErrors({ ...cardErrors, [e.elementType]: incompleteError });
      if (coupon?.percent_off === 100) {
        setCardCompletion({ ...cardCompletion, [e.elementType]: true });
      } else {
        setCardCompletion({ ...cardCompletion, [e.elementType]: false });
      }
    } else {
      setCardErrors({ ...cardErrors, [e.elementType]: null });
      setCardCompletion((prevState) => ({
        ...prevState,
        [e.elementType]: true,
      }));
    }
  };

  const onChangeCardNumber = debounce((event) => cardHandling(event), 100);
  const onChangeCardExpiry = debounce((event) => cardHandling(event), 100);
  const onChangecardCvc = debounce((event) => cardHandling(event), 100);

  useEffect(() => {
    if (areObjectKeysTruthy(cardErrors)) {
      setCardError(true);
    } else {
      setCardError(false);
    }
  }, [cardErrors.cardNumber, cardErrors.cardCvc, cardErrors.cardExpiry]);

  useEffect(() => {
    if (areObjectKeysTruthy(cardCompletion)) {
      setIsCardFieldComplete(true);
    } else {
      setIsCardFieldComplete(false);
    }
  }, [
    cardCompletion.cardCvc,
    cardCompletion.cardExpiry,
    cardCompletion.cardNumber,
  ]);

  const errorMessageUI = ({ errorMsg, errorType }) => (
    <CardErrorMsg variant="body3" color={colors.RED[5]}>
      {errorMsg && errorType !== "incomplete" && errorMsg}
    </CardErrorMsg>
  );

  return (
    <CardFieldsContainer>
      <CardNumberField>
        <CardLabel variant="link3" color={colors.GREY[3]}>
          Card Number {coupon?.percent_off === 100 ? null : "*"}
        </CardLabel>
        <CardNumberElement
          id="card-number-element"
          options={{ ...CardElementsOptions, showIcon: true }}
          onChange={onChangeCardNumber}
        />
        {cardErrors.cardNumber &&
          errorMessageUI({
            errorMsg: cardErrors.cardNumber.message,
            errorType: cardErrors.cardNumber.type,
          })}
      </CardNumberField>
      <SmallFieldsContainer>
        <SmallField>
          <CardLabel variant="link3" color={colors.GREY[3]}>
            Expiry Date {coupon?.percent_off === 100 ? null : "*"}
          </CardLabel>
          <CardExpiryElement
            id="expiry-date-element"
            options={CardElementsOptions}
            onChange={onChangeCardExpiry}
          />
          {cardErrors.cardExpiry &&
            errorMessageUI({
              errorMsg: cardErrors.cardExpiry.message,
              errorType: cardErrors.cardExpiry.type,
            })}
        </SmallField>
        <SmallField>
          <CardLabel variant="link3" color={colors.GREY[3]}>
            CVC {coupon?.percent_off === 100 ? null : "*"}
          </CardLabel>
          <CardCvcElement
            id="cvc-element"
            options={CardElementsOptions}
            onChange={onChangecardCvc}
          />
          {cardErrors.cardCvc &&
            errorMessageUI({
              errorMsg: cardErrors.cardCvc.message,
              errorType: cardErrors.cardCvc.type,
            })}
        </SmallField>
      </SmallFieldsContainer>
    </CardFieldsContainer>
  );
};

export default CardFields;
