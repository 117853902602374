/* eslint-disable react/jsx-no-useless-fragment */
import { Button, Typography } from "@mui/material";
import {
  CardNumberElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { Stripe } from "@stripe/stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import api from "api";
import { addToast } from "components/common/HIHHToast";
import PageLoader from "components/common/PageLoader";
import Form from "components/Form";
import { FormikTextInput } from "components/Form/FormikFields";
import FormikSubmitButton from "components/Form/FormikSubmitButton";
import { Container } from "components/styled";
import { useUserContext } from "components/UserContextProvider";
import { useFormikContext } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import styled, { css } from "styled-components";
import colors from "styles/colors";
import { v4 as uuidv4 } from "uuid";

import { useSubscriptionContext } from "../../SubscriptionContextProvider";
import CardFields from "./CardFields";
import CouponField from "./CouponField";
import fields, { couponField } from "./fields";

type PaymentsFormTypes = {
  closeFormButton?: boolean;
  creationForm: boolean;
  customButtonLabel?: string;
  disabledButton?: boolean;
  hideTitle?: boolean;
  selectPlan?: boolean;
  includeCouponField?: boolean;
  afterSubmit?: () => void;
  closeForm?: () => void;
};

const FormContainer = styled(Container)<any>`
  background-color: white;
  border: 1px solid ${colors.GREY[2]};
  box-sizing: border-box;
  flex-direction: column;
  gap: 40px 0;
  padding: 40px;
  flex-wrap: nowrap;
  width: 100%;

  @media all and (max-width: 1200px) {
    align-items: start;
    border: none;
    gap: 28px 0;
    padding: 32px 16px;
    ${({ selectPlan }) =>
      selectPlan &&
      css`
        padding: 8px 0 28px;
      `};
    width: 100%;
    max-height: 80%;
  }
`;

const StyledCancelButton = styled(Button)`
  && {
    width: auto;

    @media all and (max-width: 480px) {
      width: 100%;
    }
  }
`;

const FormTitle = styled(Typography)`
  && {
    margin-bottom: 12px;
    margin-right: auto;

    @media all and (max-width: 480px) {
      font-size: 28px;
      font-weight: 500;
      line-height: 40px;
      margin-bottom: 0;
    }
  }
`;

const CancelButton = ({
  closeForm,
}: {
  closeForm: () => void;
}): JSX.Element => {
  const { resetForm } = useFormikContext();

  const handleClick = () => {
    resetForm();
    closeForm();
  };

  return <StyledCancelButton onClick={handleClick}>Cancel</StyledCancelButton>;
};

const ButtonsContainer = styled(Container)`
  flex-wrap: nowrap;
  justify-content: flex-end;
  gap: 0 12px;
  margin-top: 12px;

  @media all and (max-width: 480px) {
    flex-direction: column-reverse;
    gap: 12px 0;
  }
`;

const SubmitButton = styled(FormikSubmitButton)<any>`
  && {
    width: ${({ closeForm }) => (closeForm ? "auto" : "100%")};

    @media all and (max-width: 480px) {
      width: 100%;
    }
  }
`;

const PaymentsFormWithStripe = React.forwardRef(
  (
    {
      closeForm,
      creationForm,
      closeFormButton,
      customButtonLabel,
      afterSubmit,
      hideTitle = false,
      selectPlan = false,
      disabledButton = false,
      includeCouponField = false,
    }: PaymentsFormTypes,
    ref
  ): JSX.Element => {
    const stripe = useStripe();
    const elements = useElements();
    const [isLoading, setIsLoading] = useState(false);
    const { user } = useUserContext();
    const { refreshPaymentMethods, coupon } = useSubscriptionContext();
    const [initialValues, setInitialValues] = useState({});
    const [cardError, setCardError] = useState<any>(null);
    const [isCardFieldComplete, setIsCardFieldComplete] = useState(false);

    const defaultLabel = `${creationForm ? "Create" : "Update"}`;

    const isFullDiscount = coupon?.percent_off === 100;

    const renderError = () =>
      addToast(
        `Something went wrong trying to ${
          creationForm ? "create a new" : "update your"
        } payment method`,
        "error"
      );

    const handleSubmit = async (values) => {
      if (!elements) return;
      setIsLoading(true);

      const cardNumber = elements.getElement(CardNumberElement);
      let toastMessage: string = "";

      const createPaymentMethod = async () => {
        setIsCardFieldComplete(false);
        if (!stripe || !cardNumber) {
          renderError();
          return;
        }

        addToast(
          `${creationForm ? "Creating new" : "Updating"} payment method`,
          "loading"
        );
        const { paymentMethod } = await stripe.createPaymentMethod({
          type: "card",
          card: cardNumber,
          billing_details: {
            name: values.cardHolder,
            email: values.email,
            phone: values.phone,
          },
        });

        if (!paymentMethod) {
          renderError();
          return;
        }

        const {
          data: { message },
        } = await api.post("/users/payment_methods", {
          stripe_payment_method_id: paymentMethod.id,
        });
        toastMessage = message;
      };

      try {
        if (!isFullDiscount) {
          await createPaymentMethod();
          await refreshPaymentMethods();
        }

        if (toastMessage !== "") addToast(toastMessage, "success");
        if (afterSubmit) await afterSubmit();

        setIsLoading(false);
      } catch (error) {
        renderError();
        if (closeForm) await closeForm();
        setIsLoading(false);
      }
      setIsCardFieldComplete(true);
    };

    useEffect(() => {
      if (user) {
        const useInitialValues = {
          email: user.email,
          cardHolder: `${user.first_name} ${user.last_name}`,
          coupon: couponField.initialValue,
        };
        setInitialValues(useInitialValues);
      }
    }, [user]);

    const fieldsToShow = useMemo(
      () => (includeCouponField ? [...fields, couponField] : fields),
      [includeCouponField]
    );

    let isSubmitDisabled = true;

    if (!cardError && isCardFieldComplete) {
      isSubmitDisabled = false;
    }

    if (!cardError && !isCardFieldComplete && isFullDiscount) {
      isSubmitDisabled = false;
    }

    if (cardError && isFullDiscount) {
      isSubmitDisabled = false;
    }

    return (
      <Form
        fields={fieldsToShow}
        onSubmit={handleSubmit}
        initialValues={initialValues}
      >
        <FormContainer ref={ref} tabIndex={-1} selectPlan={selectPlan}>
          {!hideTitle && (
            <FormTitle variant="h3">
              {creationForm ? "New" : "Update"} Payment Method
            </FormTitle>
          )}
          {fields.map((field) => (
            <FormikTextInput key={`${field.name} ${uuidv4()}`} {...field} />
          ))}
          <CardFields
            coupon={coupon}
            setCardError={setCardError}
            setIsCardFieldComplete={setIsCardFieldComplete}
          />
          {includeCouponField ? <CouponField /> : null}
          <>
            {isLoading ? (
              <PageLoader isBlockLoader />
            ) : (
              <>
                <ButtonsContainer>
                  {closeFormButton && closeForm && (
                    <CancelButton closeForm={closeForm} />
                  )}
                  <SubmitButton
                    id="payment-button"
                    closeForm={closeForm}
                    disableOnErrors
                    disabledOnDirty={false}
                    disabled={isSubmitDisabled || disabledButton}
                  >
                    {customButtonLabel || defaultLabel}
                  </SubmitButton>
                </ButtonsContainer>
              </>
            )}
          </>
        </FormContainer>
      </Form>
    );
  }
);

let stripeRef: Stripe | null = null;

const PaymentsForm = React.forwardRef(
  (props: PaymentsFormTypes, ref: any): JSX.Element => {
    const [stripe, setStripe] = useState(stripeRef);

    useEffect(() => {
      if (stripeRef != null) return;

      async function waitForStripe() {
        const result = await loadStripe(
          process?.env?.REACT_APP_STRIPE_PK || ""
        );
        stripeRef = result;
        setStripe(stripeRef);
      }

      waitForStripe();
    }, []);

    return stripe ? (
      <Elements
        stripe={stripe}
        options={{
          fonts: [
            {
              cssSrc:
                "https://fonts.googleapis.com/css?family=Nunito+Sans:wght@400;500;600;700;800",
            },
          ],
        }}
      >
        <PaymentsFormWithStripe ref={ref} {...props} />
      </Elements>
    ) : (
      <PageLoader isBlockLoader />
    );
  }
);

export default PaymentsForm;
