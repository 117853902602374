import { statesById } from "data/states";
import {
  filterDuplicatedValues,
  findkeysToDelete,
} from "routes/Onboarding/utils";
import { AutocompleteFieldProps, TextFieldProps } from "./fields";

const parseMemberPersonalInfoRequest = (formValues, step, user) => {
  const payloadKeys = Object.keys(formValues);

  if (payloadKeys.length === 0) {
    return formValues;
  }

  const selectedInsurances = formValues.users_insurances_attributes?.map(
    (insurance) => ({
      insurance_id: insurance.id,
    })
  );

  const insurancesToDelete = findkeysToDelete(
    selectedInsurances,
    user.users_insurances,
    "insurance_id"
  );

  const insurances = filterDuplicatedValues(
    user.insurances,
    selectedInsurances,
    "insurance_id"
  );

  const parsedPayload = {
    user: {
      sign_up_stage: step + 1,
      users_insurances_attributes: [...insurances, ...insurancesToDelete],
      member_address_attributes: {
        ...formValues.member_address_attributes,
        apartment: formValues.apartment,
        info_city: formValues.info_city,
        info_state: formValues.info_state?.id,
      },
    },
  };
  return parsedPayload;
};

export const addOptionsToAutocompleteFields = (
  field,
  fieldOptions
): AutocompleteFieldProps[] => {
  const options = Object.keys(fieldOptions);

  if (options.length === 0) {
    return field;
  }

  return { ...field, options: fieldOptions.insurances };
};

export const addInitialValueToFields = (
  initialValues,
  fields
): TextFieldProps[] | AutocompleteFieldProps[] => {
  if (!initialValues) {
    return fields;
  }

  // TODO: make this logic DRYER
  const fieldsWithInitialValues = fields.map((field) => {
    if (field.name === "users_insurances_attributes") {
      return {
        ...field,
        initialValue: initialValues.insurances,
      };
    }

    if (field.name === "member_address_attributes") {
      return {
        ...field,
        initialValue: initialValues.member_address,
      };
    }

    if (field.name === "apartment") {
      return {
        ...field,
        initialValue: initialValues.member_address?.apartment,
      };
    }

    if (field.name === "info_city") {
      return {
        ...field,
        initialValue: initialValues.member_address?.info_city,
      };
    }

    if (field.name === "info_state") {
      const state = initialValues.member_address?.info_state;
      return {
        ...field,
        initialValue: state ? statesById[state] : state,
      };
    }

    return {
      ...field,
      initialValue: initialValues[field.name],
    };
  });

  return fieldsWithInitialValues;
};

export default parseMemberPersonalInfoRequest;
