import { Typography, useMediaQuery } from "@mui/material";
import React from "react";
import colors from "styles/colors";
import metrics, { MetricItem } from "./data";
import * as S from "./styles";

const Item = ({ value, body, height }: MetricItem) => (
  <S.StyledMetricElement height={height}>
    <Typography height={76} color={colors.WHITE} variant="mk_h1">
      {value}
      <Typography fontSize={40} color={colors.WHITE} variant="mk_h3">
        +
      </Typography>
    </Typography>
    <Typography color={colors.WHITE} variant="mk_body1" textAlign="center">
      {body}
    </Typography>
  </S.StyledMetricElement>
);

const MetricsSection = () => {
  const isDesktop = useMediaQuery("(min-width: 1024px)");
  return (
    <S.StyledMetricsContainer>
      <S.StyledTitleContainer>
        <Typography
          color={colors.WHITE}
          variant={isDesktop ? "mk_serif_h2" : "mk_serif_h3"}
          fontWeight={500}
          marginBottom="40px"
          textAlign="center"
        >
          Inside Our Growing Community
        </Typography>
      </S.StyledTitleContainer>
      {metrics.map((metric) => (
        <Item {...metric} />
      ))}
      <S.StyledMetricBg />
    </S.StyledMetricsContainer>
  );
};

export default MetricsSection;
