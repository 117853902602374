import React, { createContext, useContext, SyntheticEvent } from "react";
import { NumberParam, useQueryParams, withDefault } from "use-query-params";

const DEFAULT_TAB = 0;

type State = {
  queryParams: any;
  handleTabChange: (event: SyntheticEvent, newValue: number) => void;
};

const AccountSettingsContext = createContext<State>({} as State);

export const AccountSettingsContextProvider: React.FC = ({ children }) => {
  const [queryParams, setQueryParams] = useQueryParams({
    tabIndexValue: withDefault(NumberParam, DEFAULT_TAB),
  });

  const handleTabChange = (event: SyntheticEvent, newValue: number) => {
    setQueryParams({ tabIndexValue: newValue }, "push");
  };

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value = {
    queryParams,
    handleTabChange,
  };

  return (
    <AccountSettingsContext.Provider value={value}>
      {children}
    </AccountSettingsContext.Provider>
  );
};

export const useAccountSettingsContext = () => {
  const context = useContext(AccountSettingsContext);
  if (typeof context === "undefined") {
    throw new Error("useSession must be used within a SessionContext");
  }
  return context;
};
