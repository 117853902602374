import { Typography } from "@mui/material";
import Footer from "components/Footer";
import NavBar from "components/NavBar";
import { Container } from "components/styled";
import React, { useEffect } from "react";
import { pageContainer } from "routes/AboutUs/styled";
import styled from "styled-components";
import colors from "styles/colors";

import Wave from "./Wave";

const FirstSection = styled.div`
  background-color: #6e4984;
  margin: 0 auto;
  margin-top: 80px;
  position: relative;
`;

const ThirdSection = styled(Container)`
  margin-bottom: 78px;
  max-width: 670px;
  padding-left: 26.73%;
  padding-right: 26.73%;
  padding-top: 200px;
  width: auto;
  ${pageContainer}
`;

const HubSpotFormContainer = styled.div`
  width: 120%;
`;

const ContactUs = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/v2.js";
    document.body.appendChild(script);

    script.addEventListener("load", () => {
      // @ts-ignore
      if (window.hbspt) {
        // @ts-ignore
        window.hbspt.forms.create({
          portalId: process.env.REACT_APP_HUBSPOT_PORTAL_ID || "",
          formId: process.env.REACT_APP_HUBSPOT_CONTACT_US_FORM_ID || "",
          target: "#hubspotForm",
        });
      }
    });
  }, []);

  return (
    <>
      <NavBar />
      <FirstSection>
        <Wave />
      </FirstSection>

      <ThirdSection>
        <Container justifyContent="flex-start" gap="80px">
          <Typography variant="mk_serif_h1" color={colors.NUDE[8]}>
            Contact Us
          </Typography>
          <Typography
            variant="mk_body1"
            color={colors.CORAL[9]}
            style={{ marginBottom: "30px", marginTop: "-30px" }}
          >
            To get in touch with us, please fill out the form below.
          </Typography>
        </Container>
        <HubSpotFormContainer id="hubspotForm" />
      </ThirdSection>
      <Footer />
    </>
  );
};

export default ContactUs;
