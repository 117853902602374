import { Typography } from "@mui/material";
import React from "react";
import * as S from "./styles";

type Props = {
  banner: string;
  overline: string;
  title: string;
  body: string;
  inverted?: boolean;
};

const BenefitCard = ({ banner, overline, title, body, inverted }: Props) => (
  <S.StyledContainer inverted={inverted}>
    <S.StyledBannerImg src={banner} />
    <S.StyledColumn>
      <Typography variant="mk_overline" style={{ fontSize: "1rem" }}>
        {overline}
      </Typography>
      <Typography variant="mk_h4">{title}</Typography>
      <Typography variant="mk_body1">{body}</Typography>
    </S.StyledColumn>
  </S.StyledContainer>
);

export default BenefitCard;
