/* eslint-disable consistent-return */
import { useUserContext } from "components/UserContextProvider";
import { User } from "hooks/useUserInfo";
import React from "react";
import {
  Location,
  Navigate,
  NavigateFunction,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { APP_ROUTES } from "routes";

type providerRedirectsParams = {
  user: User;
  location: Location;
  navigate: NavigateFunction;
};

const providersRedirects = ({
  user,
  location,
  navigate,
}: providerRedirectsParams) => {

/*-------------------------------------------------------------------------------------*/
  /* July 31, 2024: K.Suazo
  Commeting out the logic for navigating a user to the onboarding process
  if they are not completed. We are removing the onboarding process for members */
/*-------------------------------------------------------------------------------------*/
  
  // const userStartedOnboarding =
  //   user?.onboarding_started && !user?.onboarding_completed;

  // const onOnboarding = location.pathname.indexOf("onboarding") === 0;

  // if (userStartedOnboarding && onOnboarding) {
  //   return navigate(currentOnboardingPath(user), { replace: true });
  // }

  const onAccountSettings =
    location.pathname.indexOf(APP_ROUTES.accountSettings) === 0;

  const onFavoriteProviders =
    location.pathname.indexOf(APP_ROUTES.favoriteProviders) === 0;

  if (user.onboarding_completed && onAccountSettings && onFavoriteProviders) {
    return navigate(`/provider/${user.id}`, { replace: true });
  }
};

const ProtectedRoute: React.FC = ({ children }) => {
  const { user, isAuthenticated: isLoggedIn } = useUserContext();
  const location = useLocation();
  const navigate = useNavigate();

  if (user && user.role === "provider")
    providersRedirects({ user, location, navigate });

  return isLoggedIn ? (
    <div>{children}</div>
  ) : (
    <Navigate to={APP_ROUTES.signIn} state={{ from: location }} />
  );
};

export default ProtectedRoute;
