import { Typography } from "@mui/material";
import HIHHLogo from "assets/logos/brand/hihh-round-logo.svg";
import { Container, HIHHRoundLogo } from "components/styled";
import React from "react";
import styled from "styled-components";

export const DisclaimerWrapper = styled(Container)`
  margin: 0 auto;
  max-width: 814px;
  line-height: 1.6;
  @media all and (max-width: 480px) {
    width: calc(100% - 36px);
  }
`;

const Disclaimer = () => (
  <DisclaimerWrapper
    gap="24px"
    direction="column"
    alignItems="start"
    padding="18px"
  >
    <HIHHRoundLogo src={HIHHLogo} alt="logo" />
    <Typography variant="h3">Important Healthcare Disclaimers</Typography>

    <Typography variant="body1">
      Please be aware of the following healthcare-related disclaimers that apply
      to the Health in Her HUE platform and Virtual Care Squad experience:
    </Typography>

    <Typography variant="body1">
      <b>
        The Materials, Articles, And Other Information Made Available On Or
        Through The Health In Her Hue platform and Virtual Care Squad Is For
        Reference And Educational Purposes Only.
      </b>
      While we hope the information is useful in your healthcare journey, we do
      not intend the information to be complete or exhaustive or to provide any
      medical advice, diagnosis, or treatment.
    </Typography>

    <Typography variant="body1">
      <b>
        Do Not Consider Or Rely On Any Services Or Information Offered By Health
        In Her Hue As Professional Advice, Diagnosis, Or Treatment, Including
        Any Mental Health Concerns.
      </b>
      Do not disregard, avoid, delay, or defer any treatment based on the
      Services or any information available therein. Nothing stated or posted on
      the Services, or made available through the Services, is intended to be,
      and must not be taken to be, the practice of medicine or other healthcare
      profession.
    </Typography>

    <Typography variant="body1">
      <b>
        If You Experience A Medical Emergency, Immediately Call A Healthcare
        Professional And/Or 911.
      </b>
      Always consult with a qualified and licensed physician or other healthcare
      professional and follow their advice without delay regardless of any of
      our content or services. Your use of the Services is solely at your own
      risk.
    </Typography>

    <Typography variant="body1">
      <b>
        No Doctor-Patient Relationship Is Created When You Use The Services.
      </b>
      Healthcare professionals use the Services to share content with you, but
      your use of the content is not a substitute for healthcare. We encourage
      healthcare providers to use the Virtual Care Squad responsibly, but we
      have no control over, and cannot guarantee, the availability of any
      healthcare provider at any particular time. We will not be liable for
      canceled or otherwise unfulfilled appointments, or any injury or loss
      resulting therefrom or related to your participation in the Virtual Care
      Squad.
    </Typography>

    <Typography variant="body1">
      <b>You Are Responsible For Choosing Your Own Healthcare Provider.</b>
      You are also responsible for determining whether the applicable healthcare
      provider is suitable for your healthcare needs.
    </Typography>
  </DisclaimerWrapper>
);

export default Disclaimer;
