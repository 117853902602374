import React, { createContext, useContext, useState } from "react";

type State = {
  isDesktopFiltersMenuOpen: boolean;
  isMobileFiltersMenuOpen: boolean;
  isNavMenuOpen: boolean;
  setIsDesktopFiltersMenuOpen: (open: boolean) => void;
  setIsMobileFiltersMenuOpen: (open: boolean) => void;
  setIsNavMenuOpen: (open: boolean) => void;
};

const MenuContext = createContext<State>({} as State);

export const MenuContextProvider: React.FC = ({ children }) => {
  const [isNavMenuOpen, setIsNavMenuOpen] = useState(false);
  const [isMobileFiltersMenuOpen, setIsMobileFiltersMenuOpen] = useState(false);
  const [isDesktopFiltersMenuOpen, setIsDesktopFiltersMenuOpen] =
    useState(false);

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value = {
    isDesktopFiltersMenuOpen,
    isMobileFiltersMenuOpen,
    isNavMenuOpen,
    setIsDesktopFiltersMenuOpen,
    setIsMobileFiltersMenuOpen,
    setIsNavMenuOpen,
  };

  return <MenuContext.Provider value={value}>{children}</MenuContext.Provider>;
};

export const useMenuContext = () => {
  const context = useContext(MenuContext);
  if (typeof context === "undefined") {
    throw new Error("useSession must be used within a SessionContext");
  }
  return context;
};
