import { useUserContext } from "components/UserContextProvider";
import React from "react";
import { Route, Routes } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import AccountInfo from "./Member/AccountInfo";
import Membership from "./Member/Membership";
import MemberPersonalInfo from "./Member/PersonalInfo";
import { OnboardingContextProvider } from "./OnboardingContextProvider";
import OnboardingLayout from "./OnboardingLayout";
import ContactInfo from "./Provider/ContactInfo";
import PersonalInfo from "./Provider/PersonalInfo";
import Pledge from "./Provider/Pledge";
import ProviderInfo from "./Provider/ProviderInfo";

export const PROVIDER_ONBOARDING_STEPS = [
  {
    label: "Personal Info",
    pathName: "personal-info",
    component: PersonalInfo,
  },
  {
    label: "Provider Info",
    pathName: "provider-info",
    component: ProviderInfo,
  },
  { label: "Contact Info", pathName: "contact-info", component: ContactInfo },
  { label: "Pledge", pathName: "pledge", component: Pledge },
];

export const MEMBER_ONBOARDING_STEPS = [
  { label: "Account", pathName: "account-info", component: AccountInfo },
  {
    label: "Personal",
    pathName: "personal-info",
    component: MemberPersonalInfo,
  },
  {
    label: "Membership",
    pathName: "membership",
    component: Membership,
  },
];

export const ONBOARDING_VIEW_PATH = "/:userRole/onboarding/*";

const OnboardingRoutes = () => {
  const { user } = useUserContext();

  const onboardingRoutes =
    user?.role === "provider"
      ? PROVIDER_ONBOARDING_STEPS
      : MEMBER_ONBOARDING_STEPS;

  return (
    <Routes>
      <Route
        path="/:stepNumber"
        element={
          <OnboardingContextProvider>
            <OnboardingLayout />
          </OnboardingContextProvider>
        }
      >
        {onboardingRoutes.map((route) => (
          <Route
            key={uuidv4()}
            path={route.pathName}
            element={<route.component />}
          />
        ))}
      </Route>
    </Routes>
  );
};

export default OnboardingRoutes;
