import React from "react";
import { CircularProgress } from "@mui/material";
import {
  FormikAutocomplete,
  FormikGoogleMapsAutocomplete,
  FormikTextInput,
} from "components/Form/FormikFields";
import { Container, OnboardingMobileLogo } from "components/styled";

import OnboardingLogo from "assets/logos/onboarding-logo.svg";
import FormHeader from "routes/Onboarding/FormHeader";
import FormLayout from "routes/Onboarding/FormLayout";
import { useOnboardingContext } from "routes/Onboarding/OnboardingContextProvider";
import { v4 as uuidv4 } from "uuid";

import OnboardingTooltip from "routes/Onboarding/OnboardingTooltip";
import {
  insuranceField,
  memberAddressField,
  TextFieldProps,
  fields,
} from "./fields";
import {
  addInitialValueToFields,
  addOptionsToAutocompleteFields,
} from "./utils";

type AutocompleteFieldsProps = {
  fieldsOptions: any[];
};

const AutocompleteFields: React.FC<AutocompleteFieldsProps> = ({
  fieldsOptions,
}) => (
  <>
    {fieldsOptions.map((field) => (
      <FormikAutocomplete key={`${field.name} ${uuidv4()}`} {...field} />
    ))}
  </>
);

const GoogleAddressField = (field: TextFieldProps) => (
  <FormikGoogleMapsAutocomplete {...field} />
);

const TextField = (field: TextFieldProps) => <FormikTextInput {...field} />;

const PersonalInfo = () => {
  const { stepFieldsOptions, user, isLoading } = useOnboardingContext();

  let fieldsWithOptions: any[] = [];
  if (!isLoading) {
    fieldsWithOptions = [
      addOptionsToAutocompleteFields(insuranceField, stepFieldsOptions),
    ];
  }

  let personalInfoFields: any[] = [];

  if (!isLoading && user) {
    personalInfoFields = addInitialValueToFields(user, [
      memberAddressField,
      ...fields,
      ...fieldsWithOptions,
    ]);
  }

  return (
    <FormLayout fields={personalInfoFields}>
      <Container gap="31px">
        <OnboardingMobileLogo src={OnboardingLogo} alt="app-bar-logo" />

        <FormHeader
          title="Personal Information"
          subtitle="Tell us about yourself."
        />
        {isLoading ? (
          <CircularProgress />
        ) : (
          <Container maxWidth="500px" gap="28px" padding="0 18px">
            <AutocompleteFields fieldsOptions={fieldsWithOptions} />
            <GoogleAddressField {...memberAddressField} />
            {fields.map((field) =>
              field.autocomplete ? (
                <FormikAutocomplete key={field.name} {...field} />
              ) : (
                <TextField key={field.name} {...field} />
              )
            )}

            <OnboardingTooltip
              title="With this information, we will be able to attract more providers in your area."
              message="Why are we asking this information?"
            />
          </Container>
        )}
      </Container>
    </FormLayout>
  );
};

export default PersonalInfo;
