import api from "api";
import { useQuery } from "react-query";

type Price = {
  currency: string;
  id: number;
  price: string;
  price_cents: number;
  price_type: string;
  recurring_interval: string;
  recurring_interval_count: number;
  stripe_price: boolean;
  stripe_price_id: number | null;
};

export interface Plan {
  id: number;
  name: string;
  prices: Price[];
  description: string;
  stripe_product: boolean;
  stripe_price_id: number | null;
}

const fetchMembershipPlans = async (): Promise<Plan[]> => {
  const {
    data: { data },
  } = await api.get("/plans");
  return data;
};

const useMembershipPlans = () =>
  useQuery<Plan[], Error>("membership-plans", fetchMembershipPlans);

export default useMembershipPlans;
