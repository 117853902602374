import { Container } from "components/styled";
import { css } from "styled-components";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { Typography } from "@mui/material";
import React from "react";
import colors from "styles/colors";

export const pageContainer = css`
  @media all and (max-width: 1200px) {
    padding-right: 11.33%;
    padding-left: 11.33%;
  }

  @media all and (max-width: 900px) {
    padding-right: 4.55%;
    padding-left: 4.55%;
  }

  @media all and (max-width: 600px) {
    padding-right: 4.16%;
    padding-left: 4.16%;
  }

  @media all and (max-width: 480px) {
    padding-right: 6.66%;
    padding-left: 6.66%;
  }
`;

export const LinkedinLink = ({ url }: { url: string }) => (
  <Container justifyContent="flex-start" gap="16px">
    <LinkedInIcon
      color="primary"
      onClick={() => window.open(url, "_blank")}
      style={{ cursor: "pointer" }}
    />
    <Typography variant="link2" color={colors.CORAL[7]}>
      Linkedin
    </Typography>
  </Container>
);
