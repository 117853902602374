import { Container } from "components/styled";
import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import { APP_ROUTES } from "routes";

import UserFormHeader from "./shared/UserFormHeader";

const AuthenticationLayout: React.FC = () => {
  const { pathname } = useLocation();
  const pageLabel = pathname === APP_ROUTES.signUp ? "Sign Up" : "Sign In";

  return (
    <Container direction="column" gap="20px" justifyContent="space-between">
      <UserFormHeader
        title={pageLabel}
        subtitle={`${pageLabel} to get your account started.`}
        margin="48px 0 20px 0"
      />

      <Outlet />
    </Container>
  );
};

export default AuthenticationLayout;
