import React from "react";
import HIHHModal from "components/common/HIHHModal";
import styled from "styled-components";
import { Button, Typography } from "@mui/material";
import colors from "styles/colors";

const ModalContainer = styled.div`
  width: 648px;
  height: 306px;
  flex-direction: column;
  flex-wrap: nowrap;
  box-sizing: border-box;

  @media all and (max-width: 730px) {
    width: calc(100vw - 60px);
    height: auto;
  }
`;

const ModalContent = styled.div`
  padding: 40px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  .MuiFormHelperText-root.Mui-error {
    position: absolute;
    bottom: -19px;
  }
`;

const Footer = styled.div`
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  gap: 8px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 24px;
  height: 96px;
  max-height: 96px;

  @media all and (max-width: 1200px) {
    height: auto;
    max-height: inherit;
    flex-direction: column-reverse;
  }
`;

const StyledButton = styled(Button)`
  height: 48px !important;
`;

type Props = {
  virtualCareSquad: any;
  isOpen: boolean;
  onClose: () => void;
};

const SuccessPaymentModal = ({ virtualCareSquad, isOpen, onClose }: Props) => (
  <HIHHModal open={isOpen} onClose={onClose}>
    <ModalContainer>
      <ModalContent>
        <Typography
          variant="mk_h3"
          fontSize="40px"
          color={colors.CORAL[9]}
          marginBottom="16px"
          textAlign="center"
        >
          You’re all set!
        </Typography>
        <Typography variant="mk_body2" color={colors.GREY[6]}>
          {`You've successfully signed up for the ${virtualCareSquad} Care Squad!
          We will begin the process of matching you to your Care Squad group.
          Please check your email for more details on next steps and what to
          expect.`}
        </Typography>
      </ModalContent>
      <Footer>
        <StyledButton variant="contained" onClick={onClose}>
          Got it
        </StyledButton>
      </Footer>
    </ModalContainer>
  </HIHHModal>
);

export default SuccessPaymentModal;
