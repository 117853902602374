import api from "api";
import { useProvidersFilterContext } from "components/ProvidersFilterContextProvider";
import React, { createContext, useContext, useEffect, useState } from "react";
import { NumberParam, useQueryParams, withDefault } from "use-query-params";

const DEFAULT_PAGE = 1;
const ITEMS_PER_PAGE = 20;

type State = {
  isLoading: boolean;
  providers: any;
  queryParams: any;
  filterTotalCount: number;
  totalPages: number;
  handlePageChange: (event: React.ChangeEvent<unknown>, value: number) => void;
};

const DirectoryContext = createContext<State>({} as State);

export const DirectoryContextProvider: React.FC = ({ children }) => {
  const [providers, setProviders] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [totalPages, setTotalPages] = useState(0);
  const [filterTotalCount, setFilterTotalCount] = useState(0);

  const { queryParams: filterQueryParams } = useProvidersFilterContext();

  const [queryParams, setQueryParams] = useQueryParams({
    page: withDefault(NumberParam, DEFAULT_PAGE),
    items: withDefault(NumberParam, ITEMS_PER_PAGE),
  });

  const handlePageChange = (event, value) => {
    setQueryParams({ page: value }, "pushIn");
  };

  const fetchProviders = async () => {
    try {
      setIsLoading(true);

      const specialtyParamsIds = filterQueryParams.specialty_ids
        ? `&specialty_ids=${filterQueryParams.specialty_ids}`
        : "";

      const insuranceParamsIds = filterQueryParams.insurance_ids
        ? `&insurance_ids=${filterQueryParams.insurance_ids}`
        : "";

      const filterByParam = filterQueryParams.filter_by
        ? `&filter_by=${filterQueryParams.filter_by}`
        : "";

      const queryParam = filterQueryParams.query
        ? `&query=${filterQueryParams.query}`
        : "";

      const latitudeParam = filterQueryParams.latitude
        ? `&latitude=${filterQueryParams.latitude}`
        : "";

      const longitudeParam = filterQueryParams.longitude
        ? `&longitude=${filterQueryParams.longitude}`
        : "";

      const locationParam = filterQueryParams.location
        ? `&location=${filterQueryParams.location}`
        : "";

      const languageParamsIds = filterQueryParams.language_ids
        ? `&language_ids=${filterQueryParams.language_ids}`
        : "";

      const pronounParamsIds = filterQueryParams.pronoun_ids
        ? `&pronoun_ids=${filterQueryParams.pronoun_ids}`
        : "";

      const raceParamsIds = filterQueryParams.race_ids
        ? `&race_ids=${filterQueryParams.race_ids}`
        : "";

      const careProficiencyParamsIds = filterQueryParams.care_proficiency_ids
        ? `&care_proficiency_ids=${filterQueryParams.care_proficiency_ids}`
        : "";

      const attentionPlacesParamsIds = filterQueryParams.attention_place_ids
        ? `&attention_place_ids=${filterQueryParams.attention_place_ids}`
        : "";

      const res = await api.get(
        `/providers?page=${queryParams.page}&items=${queryParams.items}${queryParam}${filterByParam}${specialtyParamsIds}${insuranceParamsIds}${pronounParamsIds}${languageParamsIds}${careProficiencyParamsIds}${attentionPlacesParamsIds}${latitudeParam}${longitudeParam}${locationParam}${raceParamsIds}`
      );

      setProviders(res.data.data);
      if (res.headers["Total-Pages"] || res.headers["total-pages"]) {
        setTotalPages(
          parseInt(res.headers["Total-Pages"] || res.headers["total-pages"], 10)
        );
      }

      if (res.headers["Total-Count"] || res.headers["total-count"]) {
        setFilterTotalCount(
          parseInt(res.headers["Total-Count"] || res.headers["total-count"], 10)
        );
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchProviders();
  }, [
    filterQueryParams.attention_place_ids,
    filterQueryParams.care_proficiency_ids,
    filterQueryParams.filter_by,
    filterQueryParams.insurance_ids,
    filterQueryParams.language_ids,
    filterQueryParams.latitude,
    filterQueryParams.location,
    filterQueryParams.longitude,
    filterQueryParams.pronoun_ids,
    filterQueryParams.race_ids,
    filterQueryParams.query,
    filterQueryParams.specialty_ids,
    queryParams.page,
  ]);

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value = {
    filterTotalCount,
    isLoading,
    providers,
    queryParams,
    totalPages,
    handlePageChange,
  };

  return (
    <DirectoryContext.Provider value={value}>
      {children}
    </DirectoryContext.Provider>
  );
};

export const useDirectoryContext = () => {
  const context = useContext(DirectoryContext);
  if (typeof context === "undefined") {
    throw new Error("useSession must be used within a SessionContext");
  }
  return context;
};
