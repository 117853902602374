import colors from "styles/colors";
import { Button } from "@mui/material";
import styled from "styled-components";
import { Container, Logo } from "components/styled";

export const NavLogo = styled(Logo)`
  cursor: pointer;
  margin-top: 0;
  max-width: 222px;
  @media all and (max-width: 480px) {
    height: 16px;
    width: 193px;
  }
`;

export const NavigationOptions = styled(Container)`
  height: 80px;
  justify-content: center;

  @media all and (max-width: 1200px) {
    display: none;
  }
`;

export const NavbarContainer = styled(Container)`
  background-color: ${colors.WHITE};
  bottom: auto;
  height: 80px;

  margin: 0 auto;

  min-height: 80px;
  padding: 0 9%;
  position: fixed;
  top: 0;
  width: 82%;
  z-index: 1000;
  border-bottom: 1px solid ${colors.GREY[2]};

  @media all and (max-width: 1200px) {
    width: 92%;
    gap: 30px;
    justify-content: center;
    padding: 0 4%;
  }

  @media all and (max-width: 400px) {
    width: 96%;
    padding: 0 2%;
  }
`;

export const NavbarButton = styled(Button)`
  align-items: center;
  background: none;
  color: ${colors.GREY[10]};
  display: flex;
  column-gap: 4px;
  text-decoration: none;
  margin-left: 18px;

  &.active {
    & > span {
      color: ${colors.CORAL[5]};
    }
    border-bottom: 4px solid ${colors.CORAL[5]};
  }

  &.navbar-item-btn {
    border-radius: 0;
    height: 100%;
    padding: 0;
    border-bottom: 4px solid transparent;
    border-top: 4px solid transparent;
    text-decoration: none;

    :hover,
    :focus,
    :active {
      text-decoration: none;
      background-color: transparent;
      color: ${colors.CORAL[5]};
      box-shadow: none;
      border-bottom: 4px solid ${colors.CORAL[5]};
    }
  }

  &.navbar-item-btn:hover {
    background-color: transparent;
    box-shadow: none;
  }

  &.navbar-item-btn:focus {
    border: none;
  }

  &.active:focus {
    border-bottom: 4px solid ${colors.CORAL[5]};
    background-color: transparent;
    box-shadow: none;
  }

  &:hover {
    & > span {
      color: ${colors.CORAL[5]};
    }
    border-bottom: 4px solid ${colors.CORAL[5]};
  }
`;

export const LoginButton = styled(Button)`
  width: 104px;
`;
