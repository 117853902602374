import api from "api";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { addToast } from "components/common/HIHHToast";
import { handleError } from "api/responseHandlers";
import { fireUserLikedAProviderEvent } from "utils/analytics";
import { User } from "hooks/useUserInfo";
import { useUserContext } from "components/UserContextProvider";
import { AxiosError } from "axios";

type State = {
  isAppointmentModalOpen: boolean;
  isLoading: boolean;
  provider: User;
  setIsAppointmentModalOpen: (open: boolean) => void;
  fetchProviderProfile: () => void;
  toggleProviderFavorited: (user: User) => void;
  providerFavorited: boolean;
};

const ProfileContext = createContext<State>({} as State);

export const ProfileContextProvider: React.FC = ({ children }) => {
  const { user, refreshUser } = useUserContext();
  const [provider, setProvider] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const { providerId } = useParams();
  const navigate = useNavigate();
  const [isAppointmentModalOpen, setIsAppointmentModalOpen] = useState(false);

  const isCurrentUserProviderProfile = user
    ? providerId === user.id?.toString()
    : false;
  const providerFavorited = provider?.favorited_by_user;

  const fetchProviderProfile = async () => {
    if (isCurrentUserProviderProfile) {
      const data = await refreshUser();
      setProvider(data);
    } else {
      const { data } = await api.get(`/providers/${providerId}`);
      setProvider(data);
    }
  };

  const toggleProviderFavorited = async (favoriteUser: User) => {
    const params = { favorite_provider: { provider_id: provider?.id } };

    if (!providerFavorited) {
      try {
        await api.post("/favorite_providers", params);
        addToast("This provider has been saved to your favorites.", "success");
        fireUserLikedAProviderEvent(provider, favoriteUser);
        fetchProviderProfile();
      } catch (err: any) {
        handleError(err);
      }
    } else {
      try {
        await api.delete(
          `/favorite_providers/${provider?.favorite_provider_id}`,
          { data: params }
        );
        addToast(
          "This provider has been removed from your favorites.",
          "success"
        );
        fetchProviderProfile();
      } catch (err: any) {
        handleError(err);
      }
    }
  };

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        await fetchProviderProfile();
        setIsLoading(false);
      } catch (error: any) {
        const axiosError = error as AxiosError;
        const is404 = axiosError?.response?.status === 404;
        if (is404) navigate("/404");
      }
    })();
  }, [providerId]);

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value = {
    isAppointmentModalOpen,
    isLoading,
    provider,
    providerFavorited,
    setIsAppointmentModalOpen,
    fetchProviderProfile,
    toggleProviderFavorited,
  };

  return (
    <ProfileContext.Provider value={value}>{children}</ProfileContext.Provider>
  );
};

export const useProfileContext = () => {
  const context = useContext(ProfileContext);
  if (typeof context === "undefined") {
    throw new Error("useSession must be used within a SessionContext");
  }
  return context;
};
