/* eslint-disable jsx-a11y/no-static-element-interactions */
import api from "api";
import { parseProfilePictureRequest } from "components/ProfilePictureModal/profilePictureUtils";
import { useUserContext } from "components/UserContextProvider";
import { FormikValues } from "formik";
import { User } from "hooks/useUserInfo";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { MEMBERSHIP_REDIRECTED_KEY } from "routes/Membership/Plans/PlanCard";
import { fireUserCompletedOnboardingProcessEvent } from "utils/analytics";

import { MEMBER_ONBOARDING_STEPS, PROVIDER_ONBOARDING_STEPS } from ".";
import parseMemberPersonalInfoRequest from "./Member/PersonalInfo/utils";
import { parseContactInfoRequest } from "./Provider/ContactInfo/utils";
import parsePersonalInfoRequest from "./Provider/PersonalInfo/utils";
import { parseProviderInfoRequest } from "./Provider/ProviderInfo/utils";

const ONBOARDING_PREMIUM_MEMBERSHIP = "true";

export const ONBOARDING_STEPS = [
  { label: "Personal", pathName: "personal-info" },
  { label: "Provider", pathName: "provider-info" },
  { label: "Contact", pathName: "contact-info" },
  { label: "Picture", pathName: "profile-picture" },
  { label: "Pledge", pathName: "pledge" },
];

type State = {
  isLoading: boolean;
  isSubmitLoading: boolean;
  onboardingMembership: string;
  profilePictureModalOpen: boolean;
  step: number;
  stepFieldsOptions: any[];
  user: User | null;
  updateProfilePicture: (image: any) => void;
  goToPrevStep: () => void;
  setIsProfilePictureModalOpen: (value: boolean) => void;
  setOnboardingMembership: (premium: string) => void;
  submitForm: (formValues: FormikValues) => void;
};

const OnboardingContext = React.createContext<State>({} as State);

export const OnboardingContextProvider: React.FC = ({ children }) => {
  const [isLoading, setisLoading] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [stepFieldsOptions, setStepFieldsOptions] = useState([]);
  const [profilePictureModalOpen, setIsProfilePictureModalOpen] =
    useState(false);
  const navigate = useNavigate();
  const { stepNumber } = useParams();
  const { user, refreshUser, redirectUser } = useUserContext();

  const [onboardingMembership, setOnboardingMembership] = useState(
    ONBOARDING_PREMIUM_MEMBERSHIP
  );

  const step = stepNumber ? parseInt(stepNumber, 10) : 1;

  const isProvider = user?.role === "provider";
  const isMember = user?.role === "member";

  const lastOnboardingStep =
    (isProvider && step === 4) || (isMember && step === 3);

  const onboardingRoutes = isProvider
    ? PROVIDER_ONBOARDING_STEPS
    : MEMBER_ONBOARDING_STEPS;

  // On every Page change / step change, we grab the users information to feed the fields of each step.
  useEffect(() => {
    refreshUser();
  }, [stepNumber]);

  const fetchStepFieldsOptions = async () => {
    setisLoading(true);
    try {
      const { data } = await api.get(`/users/sign_up_steps/${step}`);
      setStepFieldsOptions(data.step_fields);
      setisLoading(false);
      return data;
    } catch (error) {
      setisLoading(false);
      return [];
    }
  };

  useEffect(() => {
    fetchStepFieldsOptions();
  }, [stepNumber]);

  const goToNextStep = () => {
    if (user) {
      navigate(
        `/${user.role}/onboarding/${step + 1}/${
          onboardingRoutes[step].pathName
        }`
      );
    }
  };

  const goToPrevStep = () => {
    if (user) {
      navigate(
        `/${user.role}/onboarding/${step - 1}/${
          onboardingRoutes[step - 2].pathName
        }`
      );
    }
  };

  const updateProfilePicture = async (values) => {
    const parsedInfo = parseProfilePictureRequest(values, user);

    if (!profilePictureModalOpen && !values.profile_picture) return;

    if (profilePictureModalOpen) {
      setisLoading(true);
      await api.put("/users", parsedInfo);
      setIsProfilePictureModalOpen(false);
      await refreshUser();
      setisLoading(false);
    }
  };

  const parseUserPayload = (formValues) => {
    let parsedInfo;

    if (step === 1) {
      parsedInfo = parsePersonalInfoRequest(formValues, step, user);
    }

    if (step === 2) {
      if (isProvider) {
        parsedInfo = parseProviderInfoRequest(formValues, step, user);
      } else {
        parsedInfo = parseMemberPersonalInfoRequest(formValues, step, user);
      }
    }

    if (step === 3) {
      if (isProvider) {
        parsedInfo = parseContactInfoRequest(formValues, step);
      }
    } else if (formValues.profile_picture) {
      parsedInfo = {
        user: {
          sign_up_stage: step + 1,
        },
      };
    }

    if (isMember && step === 3) {
      parsedInfo = {
        user: {
          sign_up_stage: step + 2,
        },
      };
      localStorage.setItem(MEMBERSHIP_REDIRECTED_KEY, onboardingMembership);
    }

    if (isProvider && step === 4) {
      parsedInfo = {
        user: {
          sign_up_stage: step + 2,
          ...formValues,
        },
      };
    }

    return parsedInfo;
  };

  const userLastStep = async () => {
    if (lastOnboardingStep) {
      const currentUser = await refreshUser();

      if (currentUser) {
        fireUserCompletedOnboardingProcessEvent(currentUser);
        redirectUser(currentUser);
      }
    }
  };

  const updateUser = async (formValues) => {
    const parsedInfo = parseUserPayload(formValues);

    try {
      setIsSubmitLoading(true);
      await api.put("/users", parsedInfo);
      setIsSubmitLoading(false);
    } catch (err) {
      setIsSubmitLoading(false);
    }
  };

  const submitForm = async (values: FormikValues) => {
    if (isProvider && (!values || Object.keys(values).length === 0)) return;

    await updateUser(values);

    if (!lastOnboardingStep) {
      goToNextStep();
    } else {
      await userLastStep();
    }
  };

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value = {
    isLoading,
    isSubmitLoading,
    onboardingMembership,
    profilePictureModalOpen,
    step,
    stepFieldsOptions,
    user,
    updateProfilePicture,
    goToPrevStep,
    setIsProfilePictureModalOpen,
    setOnboardingMembership,
    submitForm,
  };

  return (
    <OnboardingContext.Provider value={value}>
      {children}
    </OnboardingContext.Provider>
  );
};

export const useOnboardingContext = () => {
  const context = useContext(OnboardingContext);
  if (typeof context === "undefined") {
    throw new Error("useSession must be used within a SessionContext");
  }
  return context;
};
