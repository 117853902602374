import React from "react";
import { Button } from "@mui/material";
import { useFormikContext } from "formik";

type SubmitButtonProps = {
  disabled?: boolean;
  disabledOnDirty?: boolean;
  children: any;
  disableOnErrors?: boolean;
  className?: string;
  variant?: "text" | "outlined" | "contained";
  style?: any;
};

const FormikSubmitButton = ({
  disabled = false,
  disabledOnDirty = true,
  children,
  disableOnErrors = false,
  variant = "contained",
  ...props
}: SubmitButtonProps): JSX.Element => {
  const { submitForm, errors, dirty } = useFormikContext();
  const hasErrors = Object.keys(errors).length > 0;

  return (
    <Button
      {...props}
      disabled={
        (hasErrors && disableOnErrors) ||
        disabled ||
        (disabledOnDirty && !dirty)
      }
      onClick={submitForm}
      variant={variant}
      fullWidth
    >
      {children}
    </Button>
  );
};

export default FormikSubmitButton;
