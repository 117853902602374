import { addToast } from "components/common/HIHHToast";

const copyProfileUrlToClipboard = () => {
  if (navigator.share) {
    navigator
      .share({
        title: "Provider Profile",
        text: "",
        url: window.location.href,
      })
      .then(() => {
        addToast(
          "The link to the provider profile was copied to your clipboard",
          "success"
        );
      })
      .catch(() => addToast("Error sharing", "error"));
  } else {
    navigator.clipboard.writeText(window.location.href);
    addToast(
      "The link to the provider profile was copied to your clipboard",
      "success"
    );
  }
};

export const openUrlInNewTab = (url: string) => {
  const validUrl = url.match(/^https?:/) ? url : `//${url}`;

  const newWindow = window.open(validUrl, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};

export default copyProfileUrlToClipboard;
