import {
  yupGoogleAutocompleteRequired,
  yupRequiredArrayOfObjects,
  yupRequired,
  yupRequiredState,
} from "components/Form/validations";
import states from "data/states";

type TextFieldType = "text" | "autocomplete";

export type TextFieldProps = {
  fieldPosition?: number;
  initialValue?: string | null;
  label: string;
  multiline?: boolean;
  name: string;
  type?: TextFieldType;
  validation?: any;
};
export interface AutocompleteFieldProps extends TextFieldProps {
  multiple?: boolean;
  options: any[];
}

export const insuranceField = {
  label: "Current Insurance",
  name: "users_insurances_attributes",
  options: [],
  multiple: true,
  type: "autocomplete" as TextFieldType,
  validation: yupRequiredArrayOfObjects,
};

export const memberAddressField = {
  label: "Address",
  name: "member_address_attributes",
  initialValue: "",
  validation: yupGoogleAutocompleteRequired,
  autocomplete: true,
};

export const fields = [
  {
    label: "Apartment, Suite, etc",
    name: "apartment",
    initialValue: "",
  },
  {
    label: "City",
    name: "info_city",
    initialValue: "",
    validation: yupRequired,
  },
  {
    label: "State",
    name: "info_state",
    initialValue: null,
    autocomplete: true,
    multiple: false,
    options: states,
    validation: yupRequiredState,
  },
];
