import { Typography } from "@mui/material";
import PageLoader from "components/common/PageLoader";
import { Container } from "components/styled";
import { useUserContext } from "components/UserContextProvider";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

const EmailConfirmationRoute = () => {
  const { confirmationToken } = useParams<any>();
  const { confirmUserEmail } = useUserContext();

  useEffect(() => {
    if (confirmationToken) {
      (async () => {
        confirmUserEmail(confirmationToken);
      })();
    }
  }, [confirmationToken]);

  return (
    <Container direction="column">
      <div>
        <Typography variant="h3">
          We are confirming your email, please stand by.
        </Typography>
      </div>
      <PageLoader isBlockLoader />
    </Container>
  );
};

export default EmailConfirmationRoute;
