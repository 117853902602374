import styled from "styled-components";
import { Container } from "components/styled";
import colors from "styles/colors";
import { Divider, Typography } from "@mui/material";

export const BannerLogo = styled.img`
  width: 221px;
  height: 20px;
`;

export const MainFooterContainer = styled(Container)`
  display: flex;
  flex-direction: row;
  margin: auto;
  margin-bottom: 64px;
  max-width: 1200px;
  width: 82%;
  padding: 84px 0 0 0;
  row-gap: 64px;

  & > .logo-container {
    row-gap: 32px;
    max-width: 488px;
    align-self: start;
  }

  & .links-container {
    width: 150px;
  }

  @media all and (max-width: 1372px) {
    & .links-container {
      width: 110px;
    }
  }

  @media all and (max-width: 1258px) {
    & .links-container {
      width: 90px;
    }
  }

  @media all and (max-width: 1200px) {
    margin-top: 20px;
    width: 92%;
  }

  @media all and (max-width: 1030px) {
    flex-direction: column;
    align-items: start;

    & > .logo-container {
      max-width: max-content;
    }

    & .links-container {
      width: 150px;
    }
  }

  @media all and (max-width: 750px) {
    justify-content: center;
    flex-direction: column;
  }

  @media all and (max-width: 710px) {
    & .links-container {
      width: 110px;
    }
  }

  @media all and (max-width: 556px) {
    & .footer-navigation-container {
      display: grid;
      grid-template-columns: 150px 150px;
      grid-template-rows: 1fr 1fr;
      gap: 32px;
    }

    & .links-container span {
      font-size: 1rem;
    }
  }

  @media all and (max-width: 400px) {
    width: 96%;
  }
`;

export const SecondLineFooterContainer = styled(Container)`
  gap: 32px;
  width: 82%;
  padding: 18px 0 48px 0;
  max-width: 1200px;
  justify-content: space-between;

  @media all and (max-width: 1304px) {
    align-items: start;
    flex-direction: column;
  }

  @media all and (max-width: 1200px) {
    width: 92%;
    margin-top: 20px;
  }

  @media all and (max-width: 400px) {
    width: 96%;
  }
`;

export const FooterContainer = styled.div`
  background: ${colors.SKIN[2]};
  display: flex;
  flex-direction: column;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const FooterDivider = styled(Divider)`
  background-color: rgba(0, 0, 0, 0.07);
  margin: 0 auto;
  width: 82%;

  &.footer-divider {
    margin: 0 auto;
    max-width: 1200px;
  }

  @media all and (max-width: 1200px) {
    margin-top: 20px;
    width: 92%;
  }

  @media all and (max-width: 400px) {
    width: 96%;
  }
`;

export const FooterTypography = styled(Typography)`
  &.text-typography {
    margin-right: 24px;
  }

  &.link-typography {
    margin-right: 8px;
  }

  @media all and (max-width: 750px) {
    &.text-typography {
      margin-right: 0;
    }

    &.link-typography {
      margin-right: 0;
    }
  }
`;

export const FooterTypographyLink = styled(Typography)`
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export const IconStyle = {
  color: colors.CORAL[5],
  marginRight: "14px",
  cursor: "pointer",
};
