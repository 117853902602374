import { useUserContext } from "components/UserContextProvider";
import React from "react";
import { Navigate } from "react-router-dom";
import { APP_ROUTES } from "routes";

const OnboardingWardRoute = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  const { user } = useUserContext();

  if (user && user.onboarding_completed) {
    return <Navigate to={APP_ROUTES.home} replace />;
  }

  return children;
};

export default OnboardingWardRoute;
