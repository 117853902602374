import { Button, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "routes";
import styled from "styled-components";
import colors from "styles/colors";

import HIHHModal from "./common/HIHHModal";
import { Container } from "./styled";
import { useUserContext } from "./UserContextProvider";

const ModalContent = styled(Container)`
  gap: 16px;
  justify-content: flex-start;
  padding: 40px;
  width: 563px;
  @media all and (min-width: 480px) and (max-width: 900px) {
    padding: 24px;
    width: 400px;
  }
  @media all and (min-width: 390px) and (max-width: 480px) {
    padding: 24;
    width: 310px;
  }
  @media all and (max-width: 390px) {
    padding: 24px;
    width: 260px;
  }
`;

const ButtonsContainer = styled(Container)`
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  gap: 16px;
  padding: 24px 0;
`;

const BirthdateReminderModal = () => {
  const { displayBirthdayReminder, setDisplayBirthdayReminder } =
    useUserContext();
  const isDesktop = useMediaQuery("(min-width: 1024px)");

  const navigate = useNavigate();

  const handleAddDateClick = () => {
    setDisplayBirthdayReminder(false);
    navigate(APP_ROUTES.accountSettings);
  };

  return (
    <HIHHModal
      open={displayBirthdayReminder}
      onClose={() => setDisplayBirthdayReminder(false)}
    >
      <ModalContent>
        <Typography
          style={{
            fontFamily: "Nunito",
            fontSize: isDesktop ? "2rem" : "1.25rem",
            fontWeight: 700,
            lineHeight: isDesktop ? "44px" : "28px",
          }}
          color={colors.NUDE[9]}
        >
          Add your date of birth
        </Typography>
        <Typography variant="body1" color={colors.GREY[6]}>
          Hey there! As a member of the Health in Her HUE community, we would
          like to send you birthday love. We won&apos;t publicly display or
          share your date of birth.
        </Typography>
      </ModalContent>
      <ButtonsContainer>
        <Button onClick={() => setDisplayBirthdayReminder(false)}>
          Dismiss
        </Button>
        <Button onClick={handleAddDateClick}>Add date of birth</Button>
      </ButtonsContainer>
    </HIHHModal>
  );
};

export default BirthdateReminderModal;
