import { Link, Typography } from "@mui/material";
import api from "api";
import { handleError } from "api/responseHandlers";
import Form from "components/Form";
import {
  FormikTextInput,
  FormikToggleButtonGroup,
} from "components/Form/FormikFields";
import FormikSubmitButton from "components/Form/FormikSubmitButton";
import { Container, Line } from "components/styled";
import { useUserContext } from "components/UserContextProvider";
import { FormikValues, useFormikContext } from "formik";
import React, { useState } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { APP_ROUTES } from "routes";
import { SESSION_ROUTES } from "routes/Session";
import styled from "styled-components";
import colors from "styles/colors";
import { fireUserRegisteredEvent } from "utils/analytics";
import { v4 as uuidv4 } from "uuid";

import AppleAuthButton from "../AppleAuthButton";
import GoogleAuthButton from "../GoogleAuthButton";
import signUpFields, { roleGroupField, textFields } from "./fields";

const SignUpForm = styled(Form)`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const NameFields = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
`;


const NameFieldWrapper = styled.div`
  flex: 1;
  min-width: 45%;
`;


const textFieldsUI = (
  <>
    <NameFields>
      <NameFieldWrapper>
        <FormikTextInput
          key={`first_name ${uuidv4()}`}
          type={textFields[0]?.inputProps?.type || "text"}
          {...textFields[0]}
        />
      </NameFieldWrapper>
      <NameFieldWrapper>
        <FormikTextInput
          key={`last_name ${uuidv4()}`}
          type={textFields[1]?.inputProps?.type || "text"}
          {...textFields[1]}
        />
      </NameFieldWrapper>
    </NameFields>
    {textFields.slice(2).map((field) => (
      <FormikTextInput
        key={`${field.name} ${uuidv4()}`}
        type={field?.inputProps?.type || "text"}
        {...field}
      />
    ))}
  </>
);

const TermUI = styled(Typography)`
  color: ${colors.GREY[6]};
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
`;
const roleFieldsUI = <FormikToggleButtonGroup {...roleGroupField} />;

const OAuthButtons = (): JSX.Element => {
  const [googleButtonVisible, setGoogleButtonVisible] = useState(true);
  const { oauthSignIn } = useUserContext();
  const {
    values: { role }
  } = useFormikContext<FormikValues>();

  const handleGoogleAuth = async ({ profileObj }: any) => {
    oauthSignIn({ ...profileObj, from_sign_up: true, role });
  };

  const handleAppleAuth = async (response) => {
    const userPayload = {
      id_token: response?.authorization?.id_token,
      email: response?.user?.email || null,
      first_name: response?.user?.name?.firstName || null,
      last_name: response?.user?.name?.lastName || null,
      from_sign_up: true,
      role,
    };
    oauthSignIn({ ...userPayload });
  };

  // if third party cookies are not enabled then
  // google login won't work via JS
  // we should likely move this integration to the backend
  const handleGoogleError = (e: any) => {
    if (e.error === "idpiframe_initialization_failed") {
      setGoogleButtonVisible(false);
      return;
    }
    handleError(e);
  };

  return (
    <Container direction="column" gap="20px 0">
      <Container maxWidth="340px" justifyContent="space-around">
        <Line />
        <Typography variant="body1" color={colors.GREY[7]}>
          Or
        </Typography>
        <Line />
      </Container>
      <Container justifyContent="space-around" maxWidth="340px">
        {googleButtonVisible && (
          <Container maxWidth="166px">
            <GoogleAuthButton
              onSuccess={handleGoogleAuth}
              onFailure={handleGoogleError}
              role={role}
              isSignUp
            />
          </Container>
        )}

        <Container maxWidth="166px">
          <AppleAuthButton
            onSuccess={handleAppleAuth}
            onFailure={handleError}
            role={role}
            isSignUp
          />
        </Container>
      </Container>
    </Container>
  );
};

const SignUp = () => {
  const { state } = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const { signInAfterSignUp } = useUserContext();

  const handleSignIn = async (email, password) => {
    setIsLoading(true);
    try {
      await signInAfterSignUp(email, password);
      setIsLoading(false);
    } catch (err: any) {
      setIsLoading(false);
      handleError(err);
    }
  };

  const onSubmit = async (values) => {
    setIsLoading(true);
    try {
      const userData = {
        email: values.email,
        first_name: values.first_name,
        last_name: values.last_name,
        password: values.password,
        role: values.role,
        from_care_point: state?.from === "/carepoint",
      };

      const createUserResponse = await api.post("/users", { user: userData });
      const createdUser = createUserResponse.data;

      fireUserRegisteredEvent(createdUser);

      setIsLoading(false);
      
      handleSignIn(values.email, values.password);
    } catch (error) {
      setIsLoading(false);
      handleError(error);
    }
  };

  return (
    <Container direction="column" justifyContent="space-between">
      <SignUpForm fields={signUpFields} onSubmit={onSubmit}>
        <Container width="100%" direction="column" gap="24px 0">
          <Container maxWidth="340px" gap="28px">
            <Container justifyContent="space-between">{roleFieldsUI}</Container>
            {textFieldsUI}
            <TermUI variant="link1" color={colors.GREY[6]}>
              When you sign up, you are agreeing to the following{" "}
              <Link
                variant="link2"
                to={APP_ROUTES.terms}
                target="_blank"
                component={RouterLink}
                sx={{ color: '#a55e5f', textDecoration: 'underline' }}
              >
                Terms & Conditions
              </Link>
              {" and "}
              <Link
                variant="link2"
                to={APP_ROUTES.privacy}
                target="_blank"
                component={RouterLink}
                sx={{ color: '#a55e5f', textDecoration: 'underline' }}
              >
                Privacy Policy
              </Link>
            </TermUI>
            <FormikSubmitButton disableOnErrors disabled={isLoading}>
              <Typography variant="subtitle1">Sign Up</Typography>
            </FormikSubmitButton>
          </Container>
          <OAuthButtons />
          <Container
            direction="column"
            justifyContent="space-around"
            maxWidth="340px"
            gap="4px"
          >
            <Typography variant="body2" style={{ color: colors.GREY[9] }}>
            Already have an account?{" "}
            </Typography>

            <Container justifyContent="space-around">
              <Link
                variant="link2"
                component={RouterLink}
                to={`/users${SESSION_ROUTES.SIGN_IN}`}
                sx={{ color: '#a55e5f', textDecoration: 'underline' }}
              >
                Login here
              </Link>
            </Container>
          </Container>
        </Container>
      </SignUpForm>
    </Container>
  );
};

export default SignUp;
