import { addToast } from "components/common/HIHHToast";
import PageLoader from "components/common/PageLoader";
import { Container } from "components/styled";
import useMembershipPlans from "hooks/Onboarding/useMembershiPlans";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import PlanAccordion from "./PlanAccordion";

const SHARED_PLAN_BENEFITS =
  "Unlimited access to thousands of medical providers across 60 specialties, Unlimited access to our Community where you can interact with peers and medical professionals, ";

const PremiumPlanBenefits = SHARED_PLAN_BENEFITS.concat(
  "Unlimited access to our Content that centers you and your lived experiences, Unlimited access to our event series that covers a multitude of health and wellness topics, Priority sign up for Virtual Care Squads ($70/month)"
);

const FreePlanBenefits = SHARED_PLAN_BENEFITS.concat(
  "Access to 3 free pieces of content that centers you and your lived experiences, Option to sign up for Virtual Care Squads ($70/month)"
);

export type PlanExtra = {
  planLabel: string;
  planInterval?: string[];
  planBenefits: string;
};

const uiPlans: PlanExtra[] = [
  {
    planLabel: "Limited access to our content",
    planBenefits: FreePlanBenefits,
  },
  {
    planLabel: "Unlimited access to all our content",
    planInterval: ["Monthly", "Yearly"],
    planBenefits: PremiumPlanBenefits,
  },
];

type PlansMemberProps = {
  setOnboardingMembership: (planId: string) => void;
};

const PlansMember = ({ setOnboardingMembership }: PlansMemberProps) => {
  const { data, isLoading, error } = useMembershipPlans();

  const membershipPlans = data ?? [];

  const premiumPlan = useMemo(
    () =>
      membershipPlans.find((plan) =>
        plan.name?.toLowerCase().startsWith("premium")
      ),
    [data]
  );

  const freePlan = useMemo(
    () =>
      membershipPlans.find((plan) =>
        plan.name?.toLowerCase().startsWith("free")
      ),
    [data]
  );

  const [selectedPlan, setSelectedPlan] = useState<number>();

  const infoPlans = useMemo(
    () =>
      membershipPlans
        .map((plan, index) => ({
          ...plan,
          ...uiPlans[index],
        }))
        .reverse(),
    [membershipPlans]
  );

  useEffect(() => {
    if (!selectedPlan && premiumPlan) {
      setSelectedPlan(premiumPlan.id);
    }
  }, [data, selectedPlan]);

  const handleSelectedPlanChange = useCallback(
    (selected: number) => {
      if (selected === freePlan?.id) setOnboardingMembership("false");
      else setOnboardingMembership("true");

      setSelectedPlan(selected);
    },
    [setOnboardingMembership, freePlan]
  );

  if (isLoading) {
    return <PageLoader isBlockLoader />;
  }

  if (error) {
    addToast(error.message, "error");
    return null;
  }

  return (
    <Container gap="24px">
      {infoPlans.map((plan) => (
        <PlanAccordion
          plan={plan}
          selectedPlan={selectedPlan}
          onPlanSelect={handleSelectedPlanChange}
          key={`membership-accordion-${plan.name}`}
        />
      ))}
    </Container>
  );
};

export default PlansMember;
