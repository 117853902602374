import api from "api";
import { useQuery } from "react-query";

export type VirtualCareSquad = {
  created_at: string;
  id: number;
  title: string;
  categories: { id: number; label: string; value: string }[];
  description: string;
  form_link: string;
  sponsor_image_url: string;
  is_active: boolean;
};

const fetchVirtualCareSquads = async (): Promise<VirtualCareSquad[]> => {
  const response = await api.get(`/virtual_care_squads`);
  return response.data.data;
};

const useVirtualCareSquads = () =>
  useQuery<VirtualCareSquad[], Error>(
    ["virtual_care_squads"],
    fetchVirtualCareSquads
  );

export default useVirtualCareSquads;
