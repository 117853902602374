import React from "react";
import { Link } from "react-router-dom";

const SmartLink = ({
  link,
  style,
  children,
}: {
  link: string;
  style?: any;
  children: any;
}) => {
  if (link.startsWith("http")) {
    return (
      <a
        href={link}
        target="_self"
        rel="noopener noreferrer"
        style={style || {}}
      >
        {children}
      </a>
    );
  }

  return (
    <Link style={style || {}} to={link}>
      {children}
    </Link>
  );
};

export default SmartLink;
