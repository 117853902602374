import React, { useMemo } from "react";
import { v4 as uuidv4 } from "uuid";
import { useLocation, useNavigate, Navigate } from "react-router-dom";
import { Typography } from "@mui/material";
import { floor } from "lodash";

import { CheckIcon, ChevronLeftIcon } from "components/common/icons";
import NavBar from "components/NavBar";
import { Container } from "components/styled";
import styled from "styled-components";
import colors from "styles/colors";
import { addToast } from "components/common/HIHHToast";
import PaymentsForm from "../../common/PaymentsForm";
import SinglePriceOption from "./SinglePriceOption";
import { useSubscriptionContext } from "../../SubscriptionContextProvider";
import { PriceTypes } from "../../types";

const SelectPlanContainer = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: 0 32px;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 1000;
  overflow-y: auto;

  @media all and (max-width: 480px) {
    flex-direction: column;
    height: 100vh;
    padding: 0;
  }
`;

const NavBarContainer = styled.div`
  width: 100%;
  height: 64px;
`;

const ContentContainer = styled(Container)`
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  margin-bottom: 70px;
  width: 100%;

  @media all and (max-width: 1030px) {
    flex-direction: column;
    gap: 28px 0;
    padding: 0;
  }
`;

const DetailsContainer = styled.div`
  align-items: start;
  direction: column;
  justify-content: flex-start;
  width: auto;
`;

const BackButton = styled(Typography)`
  && {
    align-items: center;
    cursor: pointer;
    display: flex;
    padding: 90px 0 40px 0;
  }
`;

const PlanName = styled(Typography)`
  && {
    @media all and (max-width: 480px) {
      font-weight: 500;
      font-size: 28px;
      line-height: 40px;
    }
  }
`;

const FormContainer = styled.div`
  position: relative;
  height: auto;
  flex: 1;
  margin-left: 20px;
  max-width: 670px;
  min-width: 650px;

  & form {
    margin-left: auto;
  }

  @media all and (max-width: 1030px) {
    margin: 0;
    max-width: none;
    min-width: auto;
    width: 100%;
  }
`;

type SelectPlanTypes = {
  description: string;
  name: string;
  priceSelected: PriceTypes;
  renew?: string;
};

const SubscriptionPayment = (): JSX.Element => {
  const { updateSubscriptionStatus } = useSubscriptionContext();
  const navigate = useNavigate();
  const { state: subscriptionPaymentProps } = useLocation();

  const isSubscriptionPaymentDisabled = !subscriptionPaymentProps;

  if (isSubscriptionPaymentDisabled)
    return <Navigate to="/account_settings?tabIndexValue=2" />;

  const { description, name, priceSelected, renew } =
    subscriptionPaymentProps as SelectPlanTypes;
  const { createSubscription, stopProcessLoading, coupon } =
    useSubscriptionContext();

  const goBack = () => {
    navigate("/account_settings?tabIndexValue=2");
  };

  const handleAfterSubmit = async () => {
    try {
      if (renew === "renew") {
        await updateSubscriptionStatus(renew);
      } else {
        await createSubscription(priceSelected.id, coupon?.code);
      }
    } catch {
      addToast(
        "Something went wrong trying to create your subscription.",
        "error"
      );
      stopProcessLoading();
    } finally {
      goBack();
    }
  };

  const planDescriptionUI = (
    <DetailsContainer>
      <PlanName variant="h2" color={colors.NUDE[9]}>
        {name}
      </PlanName>
      <Container direction="column" margin="28px 0 0 0" gap="8px 0">
        {description.split("-").map((e) => (
          <Container
            key={`${e.length} ${uuidv4()}`}
            flexWrap="nowrap"
            justifyContent="flex-start"
          >
            <CheckIcon />
            <Typography variant="body1" color={colors.GREY[6]}>
              {e}
            </Typography>
          </Container>
        ))}
      </Container>
      <Container margin="28px 0 0 0">
        <SinglePriceOption isFree={name === "Free"} price={priceSelected} />
      </Container>
    </DetailsContainer>
  );

  const priceToPay = useMemo(() => {
    let { price } = priceSelected;
    let discount = 0;

    if (coupon) {
      if (coupon.amount_off_cents) {
        discount = (priceSelected.price_cents - coupon.amount_off_cents) / 100;
        price = `${discount >= 0 ? floor(discount, 2) : 0.0}`;
      }
      if (coupon.percent_off) {
        const amount = priceSelected.price_cents * (coupon.percent_off / 100);
        discount = (priceSelected.price_cents - amount) / 100;
        price = `${discount >= 0 ? floor(discount, 2) : 0.0}`;
      }
    }

    return price;
  }, [priceSelected, coupon]);

  return (
    <SelectPlanContainer>
      <NavBarContainer>
        <NavBar />
      </NavBarContainer>
      <Container className="page-layout" direction="column" margin="0 0 90px 0">
        <Container
          alignItems="start"
          direction="column"
          gap="0"
          className="page-layout"
        >
          <BackButton variant="link1" color={colors.CORAL[5]} onClick={goBack}>
            <ChevronLeftIcon />
            Go Back
          </BackButton>
        </Container>
        <ContentContainer className="page-layout">
          {planDescriptionUI}
          <FormContainer>
            <PaymentsForm
              includeCouponField
              afterSubmit={handleAfterSubmit}
              closeForm={goBack}
              creationForm={!renew}
              customButtonLabel={`Pay $${priceToPay}`}
              hideTitle
              selectPlan
            />
          </FormContainer>
        </ContentContainer>
      </Container>
    </SelectPlanContainer>
  );
};

export default SubscriptionPayment;
