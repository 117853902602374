import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { Container } from "components/styled";
import React, { useState } from "react";
import colors from "styles/colors";

export type PlanPrice = {
  amount: number;
  interval: string;
  id: string;
};

type PriceProps = {
  planPrice: PlanPrice[];
};

const PlanValue = ({ amount }: { amount: number }) => (
  <Container justifyContent="flex-start">
    <Typography fontSize="40px" lineHeight="44px" fontWeight={700}>
      ${amount}
    </Typography>
  </Container>
);

const DEFAULT_PREMIUM_PLAN = "monthly-12.99";

const Price = ({ planPrice }: PriceProps) => {
  const [selectedPlan, setSelectedPlan] =
    useState<string>(DEFAULT_PREMIUM_PLAN);

  return (
    <div>
      {planPrice.length === 1 ? (
        <PlanValue amount={0} />
      ) : (
        <FormControl>
          <RadioGroup
            aria-labelledby="plan-prices-radio-buttons-group"
            name="plan-prices-radio-buttons-group"
            row
            value={selectedPlan}
            onChange={(_, newValue) => setSelectedPlan(newValue)}
          >
            <PlanValue
              amount={
                planPrice.filter((price) => price.id === selectedPlan)[0].amount
              }
            />
            {planPrice.map((price) => (
              <FormControlLabel
                key={price.id}
                value={price.id}
                control={<Radio />}
                label={
                  <Typography variant="link1" color={colors.GREY[6]}>
                    {price.interval}
                  </Typography>
                }
              />
            ))}
          </RadioGroup>
        </FormControl>
      )}
    </div>
  );
};

export default Price;
