import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import AuthenticationLayout from "./Authentication/AuthenticationLayout";
import SignIn from "./Authentication/SignIn";
import SignUp from "./Authentication/SignUp";
import EmailConfirmation from "./EmailConfirmation";
import UserGoals from "./UserGoals"
import ForgotPassword from "./Authentication/ForgotPassword";
import ResetPassword from "./Authentication/ResetPassword";
import SessionLayout from "./SessionLayout";
import ConfirmationMessage from "./Authentication/ConfirmationMessage";

export const SESSION_ROUTES = {
  SIGN_UP: "/sign-up",
  SIGN_IN: "/sign-in",
  ACCOUNT_CONFIRMATION: "/email_confirmations/:confirmationToken",
  CONFIRM_EMAIL_LANDING: "/confirm_email",
  FORGOT_PASSWORD: "/forgot-password",
  RESET_PASSWORD: "/reset_password/:passwordToken",
  USER_GOALS: "/goals",
};

export const SESSION_VIEW_PATH = "/users/*";

const SessionRoutes = () => (
  <Routes>
    <Route element={<SessionLayout />}>
      <Route element={<AuthenticationLayout />}>
        <Route path={SESSION_ROUTES.SIGN_IN} element={<SignIn />} />
        <Route path={SESSION_ROUTES.SIGN_UP} element={<SignUp />} />
        <Route path={SESSION_ROUTES.SIGN_IN} element={<SignIn />} />
      </Route>
      <Route
        path={SESSION_ROUTES.ACCOUNT_CONFIRMATION}
        element={<EmailConfirmation />}
      />
      <Route
        path={SESSION_ROUTES.USER_GOALS}
        element={<UserGoals />}
      />
      <Route
        path={SESSION_ROUTES.CONFIRM_EMAIL_LANDING}
        element={<ConfirmationMessage />}
      />
      <Route
        path={SESSION_ROUTES.FORGOT_PASSWORD}
        element={<ForgotPassword />}
      />
      <Route path={SESSION_ROUTES.RESET_PASSWORD} element={<ResetPassword />} />
      <Route path="*" element={<Navigate to="/404" />} />
    </Route>
  </Routes>
);

export default SessionRoutes;
