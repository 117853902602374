import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import styled from "styled-components";
import { Typography, Link } from "@mui/material";
import { v4 as uuidv4 } from "uuid";

import api from "api";
import Form from "components/Form";
import { FormikTextInput } from "components/Form/FormikFields";
import FormikSubmitButton from "components/Form/FormikSubmitButton";
import { Container } from "components/styled";
import { SESSION_ROUTES } from "routes/Session";
import colors from "styles/colors";
import forgotPasswordFields from "./fields";
import UserFormHeader from "../shared/UserFormHeader";

const ForgotPasswordForm = styled(Form)`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const ForgotPasswordContainer = styled(Container)`
  max-width: 500px;

  @media all and (max-width: 1500px) {
    padding-left: 80px;
    padding-right: 80px;
  }

  @media all and (max-width: 480px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const textFieldsUI = (
  <>
    {forgotPasswordFields.map((field) => (
      <FormikTextInput
        key={`${field.name} ${uuidv4()}`}
        type="text"
        {...field}
      />
    ))}
  </>
);

const ResendBtn = styled.button`
  background: white;
  border: 0;
  color: ${colors.CORAL[5]};
  cursor: pointer;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  outline: none;
`;

const ForgotPassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [userEmail, setUserEmail] = useState(null);
  const [formSubmittedSuccessfully, setFormSubmittedSuccessfully] =
    useState(false);

  const onSubmit = async (values) => {
    setIsLoading(true);
    try {
      setUserEmail(values.email);
      await api.post("/users/password", {
        user: {
          email: values.email,
        },
      });
      setFormSubmittedSuccessfully(true);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  return (
    <ForgotPasswordContainer
      direction="column"
      justifyContent="space-between"
      gap="20px"
    >
      {!formSubmittedSuccessfully && (
        <>
          <UserFormHeader
            title="Reset Password"
            subtitle="If you have an account with Health in Her HUE we will send you an email with a link to reset your password"
          />

          <Container maxWidth="340px" gap="28px">
            <ForgotPasswordForm
              fields={forgotPasswordFields}
              onSubmit={onSubmit}
            >
              <Container maxWidth="340px" gap="28px">
                {textFieldsUI}
                <FormikSubmitButton disableOnErrors disabled={isLoading}>
                  <Typography variant="subtitle1">
                    Send Verification Email
                  </Typography>
                </FormikSubmitButton>
              </Container>
            </ForgotPasswordForm>
          </Container>

          <Container
            direction="column"
            justifyContent="space-around"
            maxWidth="340px"
            margin="12px 0 0 0"
          >
            <Link
              variant="link2"
              to={`/users${SESSION_ROUTES.SIGN_IN}`}
              component={RouterLink}
            >
              Go Back
            </Link>
          </Container>
        </>
      )}

      {formSubmittedSuccessfully && (
        <>
          <UserFormHeader
            title="Email has been sent!"
            subtitle={`We have sent an email to ${userEmail} with a link to reset your password.`}
          />

          <Container
            direction="column"
            justifyContent="space-around"
            maxWidth="340px"
            margin="12px 0 0 0"
          >
            <Typography variant="body2" color={colors.GREY[9]}>
              Didn’t receive your email?
              <ResendBtn onClick={() => onSubmit({ email: userEmail })}>
                Resend
              </ResendBtn>
            </Typography>
          </Container>
        </>
      )}
    </ForgotPasswordContainer>
  );
};

export default ForgotPassword;
