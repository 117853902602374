import { ToggleButton as MaterialToggleButton } from "@mui/material";
import { Container } from "components/styled";
import React from "react";
import styled from "styled-components";

const ToggleButton = styled(MaterialToggleButton)`
  &.toggle-button {
    font-family: Nunito Sans, sans-serif;
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 20px;
    text-transform: none;
    width: 166px;
  }
`;

type HIHHToggleButtonProps = {
  icon?: any;
  label: string;
  selected: boolean;
  value: any;
  onChange: any;
};

const HIHHToggleButton: React.FC<HIHHToggleButtonProps> = ({
  icon,
  label,
  value,
  selected,
  onChange,
}) => (
  <Container direction="column" maxWidth="166px">
    <ToggleButton className="toggle-button" value={value} selected={selected} onChange={onChange}>
      <icon.StartIcon />
      {label}
    </ToggleButton>
  </Container>
);

export default HIHHToggleButton;
