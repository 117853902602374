import {
  Typography,
  ToggleButton as MaterialToggleButton,
} from "@mui/material";
import React from "react";
import styled from "styled-components";
import colors from "styles/colors";
import { RoundedCheckIcon } from "./icons";

const Label = styled<any>(Typography)`
  font-weight: ${(props) => (props.selected ? 700 : 400)};

  &.toggle-button-label {
    text-transform: capitalize;

    @media all and (max-width: 480px) {
      font-size: 0.75rem;
    }
  }
`;

const CheckContainer = styled.div`
  position: absolute;
  right: 4px;
  top: 4px;
`;

const ToggleButton = styled(MaterialToggleButton)`
  &.toggle-button {
    border: 1px solid
      ${(props) => (props.selected ? colors.CORAL[5] : colors.GREY[2])};
    border-radius: 4px;
    cursor: pointer;
    color: ${(props) => (props.selected ? colors.CORAL[5] : colors.GREY[7])};
    flex-direction: column;
    height: 96px;
    justify-content: space-around;
    position: relative;
    width: 158px;

    :hover {
      border: 1px solid ${colors.NUDE[5]};
      color: ${(props) => (props.selected ? colors.CORAL[5] : colors.GREY[7])};
    }

    @media all and (max-width: 480px) {
      width: 100px;
      height: 92px;
    }
  }
`;

type HIHHIconToggleButtonProps = {
  active: boolean;
  icon: any;
  label: string;
  value: any;
  onChange: any;
};

const HIHHIconToggleButton = ({
  icon,
  active = false,
  label,
  value,
  onChange,
}: HIHHIconToggleButtonProps) => (
  <ToggleButton
    className="toggle-button"
    value={value}
    selected={active}
    onChange={onChange}
  >
    <CheckContainer>{active && <RoundedCheckIcon />}</CheckContainer>
    <div>
      <icon.Icon color={active ? colors.CORAL[5] : colors.GREY[7]} />
    </div>
    <Label className="toggle-button-label" variant="body2" selected={active}>
      {label}
    </Label>
  </ToggleButton>
);

export default HIHHIconToggleButton;
