import { Typography } from "@mui/material";
import api from "api";
import { addToast } from "components/common/HIHHToast";
import { Container } from "components/styled";
import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import colors from "styles/colors";

import UserFormHeader from "./shared/UserFormHeader";

type SubtitleMessageProps = {
  email: string;
};

const SubtitleMessage: React.FC<SubtitleMessageProps> = ({ email }) => (
  <Container maxWidth="380px">
    <Typography variant="body2">We have sent an email to</Typography>
    <Typography variant="link2" sx={{ margin: "0 3px" }}>
      {email}
    </Typography>
    <Typography variant="body2">
      with a link to confirm your account.
    </Typography>
  </Container>
);

const ConfirmationMessage = () => {
  const location: any = useLocation();

  const onSubmit = async (values) => {
    try {
      await api.post("/users/confirmation", {
        user: {
          email: values.email,
        },
      });
      addToast("The confirmation email has been sent", "success");
    } catch (err: any) {
      addToast(err, "error");
    }
  };

  if (!location.state || !location.state.email) {
    return <Navigate to="/404" />;
  }

  return (
    <Container
      maxWidth="510px"
      direction="column"
      justifyContent="space-between"
      gap="28px"
    >
      <UserFormHeader
        title="Confirm your account"
        subtitle={<SubtitleMessage email={location.state.email} />}
        margin="48px 0 0 0"
      />

      <Container
        direction="column"
        justifyContent="space-around"
        maxWidth="340px"
      >
        <Typography variant="body2" color={colors.GREY[9]}>
          Didn&apos;t receive your email?
          <Typography
            variant="link2"
            color={colors.CORAL[5]}
            onClick={() => onSubmit({ email: location.state.email })}
            sx={{ marginLeft: "5px", cursor: "pointer" }}
          >
            Resend
          </Typography>
        </Typography>
      </Container>
    </Container>
  );
};

export default ConfirmationMessage;
