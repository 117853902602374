import React from "react";

import { LockIcon } from "components/common/icons";
import {
  yupPassword,
  yupPasswordConfirmation,
} from "components/Form/validations";

const resetPasswordFields = [
  {
    label: "New Password",
    name: "password",
    initialValue: "",
    inputProps: {
      type: "password",
    },
    validation: yupPassword,
    icon: {
      StartIcon: (props) => <LockIcon {...props} />,
    },
  },
  {
    label: "Confirm New Password",
    name: "password_confirmation",
    initialValue: "",
    inputProps: {
      type: "password",
    },
    validation: yupPasswordConfirmation,
    icon: {
      StartIcon: (props) => <LockIcon {...props} />,
    },
  },
];

export default resetPasswordFields;
