import {
  currentOnboardingPath,
  useUserContext,
} from "components/UserContextProvider";
import React from "react";
import { Navigate, useLocation } from "react-router-dom";

const RequiresOnboardingRoute: React.FC = ({ children }) => {
  const { user } = useUserContext();
  const location = useLocation();

  if (user && !user.onboarding_completed) {
    return (
      <Navigate
        replace
        to={currentOnboardingPath(user)}
        state={{ from: location }}
      />
    );
  }

  return <div>{children}</div>;
};

export default RequiresOnboardingRoute;
