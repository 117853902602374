import React from "react";
import { Autocomplete, Chip, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { Container } from "components/styled";
import colors from "styles/colors";

export type AutocompleteOption = {
  label: string;
  id: number | string;
  value?: string | boolean;
  created_at?: string;
  updated_at?: string;
};

type HIHHAutocompleteProps = {
  dirty?: boolean;
  touched: boolean;
  errorMsg?: string;
  hasError: boolean;
  label: string;
  multiple?: boolean;
  name: string;
  options?: AutocompleteOption[]; // TODO: options are required
  placeholder?: string;
  value: any | any[];
  onBlur?: (event: any) => void;
  onChange: (event: any) => void;
};

const useStyles = makeStyles(() => ({
  inputRoot: {
    "&.MuiAutocomplete-inputRoot": {
      padding: "6px 39px 6px 9px",
    },
  },
  rootInput: {
    paddingLeft: "8px",
  },
  focusedLabel: {
    transform: "translate(16px, -9px) scale(0.75)",
  },
}));

const HIHHAutocomplete: React.FC<HIHHAutocompleteProps> = ({
  touched,
  errorMsg,
  hasError,
  label,
  multiple = false,
  name,
  options = [{ label: "", id: 0, value: "" }],
  placeholder,
  value = multiple ? [] : "",
  onBlur,
  onChange,
  ...props
}) => {
  const classes = useStyles();
  const hasValue = multiple
    ? (value || []).length
    : Object.keys(value || {}).length;

  return (
    <Container direction="column" maxWidth="auto">
      <Autocomplete
        {...props}
        id="hihh-auto-complete"
        autoComplete
        classes={classes}
        disableClearable
        forcePopupIcon
        freeSolo
        fullWidth
        includeInputInList
        isOptionEqualToValue={(option, val) => option.id === val.id}
        multiple={multiple}
        onChange={(event, newValue) => onChange(newValue)}
        getOptionLabel={(option) => option.label || ""}
        options={options}
        value={value}
        renderTags={(v, getTagProps) =>
          v.map((option, index) => (
            <Chip
              style={{
                backgroundColor: colors.NUDE[1],
                color: colors.NUDE[8],
                fontSize: 14,
                lineHeight: "20px",
              }}
              variant="outlined"
              label={option.label}
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              autoComplete: "new-password",
            }}
            InputLabelProps={{
              shrink: !!hasValue,
              classes: {
                root: hasValue ? classes.rootInput : "",
                focused: hasValue ? classes.focusedLabel : "",
              },
            }}
            error={hasError}
            helperText={touched ? errorMsg : undefined}
            label={label}
            name={name}
            onBlur={onBlur}
            placeholder={placeholder}
            variant="outlined"
          />
        )}
      />
    </Container>
  );
};

export default HIHHAutocomplete;
