import { v4 as uuidv4 } from "uuid";

import ForPharmaceuticalCompanies from "../assets/ForPharmaceuticalCompanies.svg";
import ForHealthPlans from "../assets/ForHealthPlans.svg";
import ForHealthSystemsProviders from "../assets/ForHealthSystemsProviders.svg";
import ForConsumerHealthWellnessBrands from "../assets/ForConsumerHealthWellnessBrands.svg";
import ForEmployersBenefitsConsultants from "../assets/ForEmployersBenefitsConsultants.svg";

export default [
  {
    key: uuidv4(),
    banner: ForPharmaceuticalCompanies,
    overline: "Pharmaceutical Companies",
    title: "Enhancing Clinical Trial & Consumer Research with Diversity",
    body: "Strengthen your clinical trial and consumer research by ensuring your studies represent diverse populations. And establish trust and connection with women of color by gaining heightened brand visibility.",
  },
  {
    key: uuidv4(),
    banner: ForConsumerHealthWellnessBrands,
    overline: "Consumer Health and Wellness Brands",
    inverted: true,
    title: "Amplify Your Wellness Brand with Targeted Engagement",
    body: "Partner with us to connect your brand with highly engaged women of color, fostering deeper consumer insights and relationships. Utilize our platform's data-driven approach to tailor customer interactions, enhancing brand resonance and loyalty.",
  },
  {
    key: uuidv4(),
    banner: ForHealthPlans,
    overline: "Health Plans",
    title: "Increase Preventive Care and Member Satisfaction",
    body: "Increase preventive care and improve how your members manage their chronic conditions. Enhance your HEDIS score and lower healthcare costs while improving member satisfaction through informed health decision-making.",
  },
  {
    key: uuidv4(),
    banner: ForEmployersBenefitsConsultants,
    overline: "Employers & Benefits Consultants",
    inverted: true,
    title: "Build a Diverse and Healthy Workforce",
    body: "Improve employee health with our personalized content and preventive care strategies. Help your employees navigate their health benefits and connect them with culturally sensitive providers, driving better health outcomes and a more productive and engaged workplace.",
  },
  {
    key: uuidv4(),
    banner: ForHealthSystemsProviders,
    overline: "Health Systems & Providers",
    title:
      "Provide a Culturally Tailored Solution to Engage and Retain Patients",
    body: "Learn more about how Health in Her HUE can help your health system or practice attract and retain patients, while supporting your providers with evidence-based content and training to improve patient satisfaction and outcomes.",
  },
];
