import { Drawer, Typography } from "@mui/material";
import React from "react";
import styled from "styled-components";
import colors from "styles/colors";

import { Container } from "./styled";

type DrawerMenuProps = {
  id?: string;
  anchor?: "bottom" | "left" | "right" | "top";
  icon: React.ReactNode;
  label?: string;
  width?: string;
  maxWidth?: string;
  open: boolean;
  secondaryAction?: React.ReactNode;
  secondaryIcon?: React.ReactNode;
  isDisabled?: boolean;
  setOpen: (open: boolean) => void;
};

const DrawerContainer = styled<any>(Drawer)`
  .MuiDrawer-paper {
    width: ${(props) => (props.width ? props.width : "initial")};
    max-width: ${(props) => (props.maxWidth ? props.maxWidth : "initial")};
    overflow-x: hidden;
  }
`;

const Button = styled.button`
  background: white;
  border: 0;
  cursor: pointer;
  font-size: 14px;
  font-style: normal;
  font-weight: bold;
  line-height: 20px;
  outline: none;
  padding: 0;
`;

const DrawerMenu: React.FC<DrawerMenuProps> = ({
  id = "drawer-menu",
  anchor = "left",
  children,
  icon,
  label,
  width,
  maxWidth,
  open,
  secondaryIcon,
  secondaryAction,
  isDisabled,
  setOpen,
}) => (
  <Container justifyContent="space-between">
    <Button disabled={isDisabled} onClick={() => setOpen(true)} id={id} aria-label={label ? `Open ${label}` : "Open menu"}>
      <Container gap="8px">
        {icon}
        {label && (
          <Typography variant="link1" color={colors.CORAL[5]}>
            {label}
          </Typography>
        )}
        {secondaryIcon}
      </Container>
    </Button>
    {secondaryAction}
    <DrawerContainer
      anchor={anchor}
      open={open}
      onClose={() => setOpen(false)}
      width={width}
      maxWidth={maxWidth}
    >
      {children}
    </DrawerContainer>
  </Container>
);

export default DrawerMenu;
