import { Slider, Stack, useMediaQuery } from "@mui/material";
import { handleError } from "api/responseHandlers";
import React, { useCallback, useMemo, useState } from "react";
import Cropper from "react-easy-crop";
import { Area, Point } from "react-easy-crop/types";
import styled from "styled-components";
import { getCroppedImg } from "utils";

import { MinusIcon, PlusIcon } from "./common/icons";
import { ImageSizeType } from "./ProfilePictureModal";
import { Container } from "./styled";

const SliderControl = styled.div`
  cursor: pointer;
`;

type CropImageProps = {
  imageSrc: string;
  imageSize: ImageSizeType;
  setCroppedImage: (image: any) => void;
};

const step = 0.1;

const CropImage: React.FC<CropImageProps> = ({
  imageSrc,
  imageSize,
  setCroppedImage,
}) => {
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);

  const isMobile = useMediaQuery("(max-width:767px)");

  const dimensions = useMemo(
    () => ({
      desktop: {
        width:
          imageSize.width > 450 || imageSize.width === 0
            ? 450
            : imageSize.width,
        height:
          imageSize.height > 250 || imageSize.height === 0
            ? 250
            : imageSize.height,
      },
      mobile: {
        width: 280,
        height: 280,
      },
    }),
    [imageSize]
  );

  const getCroppedImage = useCallback(
    async (_, croppedAreaPixels: Area) => {
      if (
        Number.isNaN(croppedAreaPixels.width) ||
        croppedAreaPixels.width === 0
      ) {
        return;
      }

      try {
        const image = await getCroppedImg(imageSrc, croppedAreaPixels);
        setCroppedImage(image);
      } catch (err) {
        handleError(err);
      }
    },
    [imageSrc]
  );

  const decreaseZoom = useCallback(() => setZoom((z) => z - step), []);
  const increaseZoom = useCallback(() => setZoom((z) => z + step), []);

  return (
    <Container direction="column" gap="12px">
      <Cropper
        image={imageSrc}
        crop={crop}
        zoom={zoom}
        aspect={1}
        cropShape="round"
        showGrid={false}
        onCropChange={setCrop}
        onCropComplete={getCroppedImage}
        onZoomChange={setZoom}
        zoomWithScroll={false}
        style={{
          containerStyle: {
            position: "relative",
            width: isMobile
              ? dimensions.mobile.width
              : dimensions.desktop.width,
            height: isMobile
              ? dimensions.mobile.height
              : dimensions.desktop.height,
            borderRadius: 6,
          },
          cropAreaStyle: {
            color: "rgba(255,255,255,0.5)",
          },
        }}
      />
      <Stack
        spacing={2}
        direction="row"
        sx={{ marginBottom: "12px" }}
        alignItems="center"
      >
        <SliderControl onClick={decreaseZoom}>
          <MinusIcon />
        </SliderControl>
        <Slider
          value={zoom}
          min={1}
          max={3}
          step={step}
          aria-labelledby="Zoom"
          onChange={(e, zoomValue) => setZoom(Number(zoomValue))}
          sx={{ width: 280 }}
        />
        <SliderControl onClick={increaseZoom}>
          <PlusIcon />
        </SliderControl>
      </Stack>
    </Container>
  );
};

export default CropImage;
