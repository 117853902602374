import { CircularProgress } from "@mui/material";
import OnboardingLogo from "assets/logos/onboarding-logo.svg";
import { Container, OnboardingMobileLogo } from "components/styled";
import React, { useMemo } from "react";
import FormHeader from "routes/Onboarding/FormHeader";
import FormLayout from "routes/Onboarding/FormLayout";

import { useOnboardingContext } from "../../OnboardingContextProvider";
import { practiceFields as PFields, TextFieldProps } from "./fields";
import PracticeFields from "./PracticeFields";
import { addInitialValueToFields } from "./utils";

const ContactInfo = () => {
  const { isLoading, user } = useOnboardingContext();

  const fieldsWithValues: TextFieldProps[] = useMemo(() => {
    if (!isLoading && user) return addInitialValueToFields(user, PFields);
    return [];
  }, [user, isLoading]);

  return (
    <FormLayout fields={fieldsWithValues}>
      <Container gap="41px 0" direction="column" padding="0 18px">
        <OnboardingMobileLogo src={OnboardingLogo} alt="app-bar-logo" />
        <FormHeader title="Contact Information" margin="0" />
        {isLoading ? <CircularProgress /> : <PracticeFields />}
      </Container>
    </FormLayout>
  );
};

export default ContactInfo;
