/* eslint-disable no-shadow */
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { CloseEyeIcon, OpenEyeIcon } from "components/common/icons";
import useDebounce from "hooks/useDebounce";
import React, { useMemo, useState } from "react";
import colors from "styles/colors";

type HIHHTextFieldProps = {
  disabled?: boolean;
  endAdornment?: React.ReactNode;
  error?: boolean;
  helperText?: string;
  hideNumberCaret?: boolean;
  icon?: any;
  iconColor?: string;
  inputRef?: any;
  label?: string;
  maxChar?: number;
  multiline?: boolean;
  name?: string;
  placeholder?: string;
  type?: string;
  value: string;
  onBlur?: (event: any) => void;
  onChange: (event: any) => void;
  autoComplete?: string;
  className?: string;
  style?: any;
};

type StylesProps = {
  value: string;
  hideNumberCaret?: boolean;
};

const useStyles = makeStyles(() => ({
  rootLabel: {
    transform: (props: StylesProps) =>
      props.value?.length > 0
        ? "translate(16px, -9px) scale(0.75)"
        : "translate(36px, 16px) scale(1)",
  },
  rootInput: {
    paddingLeft: "8px",
  },
  iconInput: {
    padding: "14px 12px 14px 0",
  },
  input: {
    "&[type=number]": {
      "-moz-appearance": ({ hideNumberCaret }) =>
        hideNumberCaret && "textfield",
    },
    "&::-webkit-outer-spin-button": {
      "-webkit-appearance": ({ hideNumberCaret }) => hideNumberCaret && "none",
      margin: ({ hideNumberCaret }) => hideNumberCaret && 0,
    },
    "&::-webkit-inner-spin-button": {
      "-webkit-appearance": ({ hideNumberCaret }) => hideNumberCaret && "none",
      margin: ({ hideNumberCaret }) => hideNumberCaret && 0,
    },
  },
  focusedLabel: {
    transform: "translate(16px, -9px) scale(0.75)",
  },
}));

const HIHHTextField: React.FC<HIHHTextFieldProps> = ({
  disabled = false,
  endAdornment = null,
  error,
  helperText,
  hideNumberCaret,
  icon,
  iconColor = colors.NUDE[5],
  inputRef,
  label = "",
  maxChar,
  multiline = false,
  name,
  placeholder = "",
  type = "text",
  value,
  autoComplete = "on",
  onBlur,
  onChange,
  style,
  className,
}) => {
  const { _value, set_value } = useDebounce(value, onChange, 400);

  const [showPassword, setShowPassword] = useState(false);
  const classes = useStyles({ value: _value, hideNumberCaret });

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const typeValidation = useMemo(() => {
    if (type === "password") {
      return showPassword ? "text" : "password";
    }

    return type;
  }, [type, showPassword]);

  return (
    <TextField
      style={style}
      className={className}
      name={name || "input-field"}
      error={error}
      fullWidth
      helperText={
        multiline
          ? `${_value?.length || "0"} / ${maxChar} ${helperText || ""}`
          : helperText
      }
      onBlur={() => onBlur && onBlur(_value)}
      value={_value || ""}
      onChange={({ target: { value } }) => set_value(value)}
      type={typeValidation}
      multiline={multiline}
      minRows={3}
      maxRows={5}
      InputLabelProps={{
        classes: {
          root: icon ? classes.rootLabel : "",
          focused: icon ? classes.focusedLabel : "",
        },
        shrink: value?.length > 0,
      }}
      InputProps={{
        autoComplete,
        classes: {
          root: icon ? classes.rootInput : "",
          input: icon ? classes.iconInput : classes.input,
        },
        startAdornment: icon && (
          <InputAdornment position="start" style={{ marginRight: 0 }}>
            <icon.StartIcon color={disabled ? colors.GREY[2] : iconColor} />
          </InputAdornment>
        ),
        endAdornment:
          type === "password" ? (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? (
                  <CloseEyeIcon color={colors.GREY[2]} />
                ) : (
                  <OpenEyeIcon />
                )}
              </IconButton>
            </InputAdornment>
          ) : (
            endAdornment
          ),
      }}
      {...{
        placeholder,
        label,
        disabled,
        inputRef,
      }}
    />
  );
};

export default HIHHTextField;
