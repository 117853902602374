import {
  yupEmail,
  yupPhoneNullable,
  yupRequired,
} from "components/Form/validations";

const cardHolderField = {
  label: "Card Holder*",
  name: "cardHolder",
  placeholder: "Enter the card holder",
  autoFocus: true,
  initialValue: "",
  validation: yupRequired,
};

const emailField = {
  label: "Email*",
  name: "email",
  placeholder: "Enter your email",
  initialValue: "",
  validation: yupEmail,
};

const phoneField = {
  label: "Phone number",
  name: "phone",
  type: "tel",
  placeholder: "Enter your phone number",
  initialValue: "",
  validation: yupPhoneNullable,
};

export const couponField = {
  label: "Promo Code",
  name: "coupon",
  placeholder: "Promo Code",
  initialValue: "",
};

const fields = [cardHolderField, emailField, phoneField];

export default fields;
