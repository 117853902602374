import { DoctorIcon, LockIcon, UserIcon } from "components/common/icons";
import {
  yupRole,
  yupName,
  yupEmailWithTrailingWhitespace,
  yupPassword,
  yupPasswordConfirmation,
} from "components/Form/validations";
import React from "react";

export const textFields = [
  {
    label: "First Name",
    name: "first_name",
    initialValue: "",
    validation: yupName,
    autofocus: true,
    icon: {
      StartIcon: (props) => <UserIcon {...props} />,
    },
  },
  {
    label: "Last Name",
    name: "last_name",
    initialValue: "",
    validation: yupName,
    autofocus: true,
    icon: {
      StartIcon: (props) => <UserIcon {...props} />,
    },
  },
  {
    label: "Email",
    name: "email",
    initialValue: "",
    validation: yupEmailWithTrailingWhitespace,
    autofocus: true,
    icon: {
      StartIcon: (props) => <UserIcon {...props} />,
    },
  },
  {
    label: "Password",
    name: "password",
    initialValue: "",
    inputProps: {
      type: "password",
    },
    validation: yupPassword,
    icon: {
      StartIcon: (props) => <LockIcon {...props} />,
    },
  },
  {
    label: "Confirm Password",
    name: "confirm_password",
    initialValue: "",
    inputProps: {
      type: "password",
    },
    validation: yupPasswordConfirmation,
    icon: {
      StartIcon: (props) => <LockIcon {...props} />,
    },
  },
];

const roleOptions = [
  {
    label: "Individual",
    value: "member",
    icon: {
      StartIcon: (props) => <UserIcon {...props} />,
    },
  },
  {
    label: "Provider",
    value: "provider",
    icon: {
      StartIcon: (props) => <DoctorIcon {...props} />,
    },
  },
];

export const roleGroupField = {
  name: "role",
  validation: yupRole,
  options: roleOptions,
  initialValue: "",
};

const signUpFields = [roleGroupField, ...textFields];

export default signUpFields;
