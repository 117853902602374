import { CircularProgress, Typography, Link } from "@mui/material";
import OnboardingLogo from "assets/logos/onboarding-logo.svg";
import { FormikCheckbox } from "components/Form/FormikFields";
import { Container, OnboardingMobileLogo } from "components/styled";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { APP_ROUTES } from "routes";
import FormHeader from "routes/Onboarding/FormHeader";
import FormLayout from "routes/Onboarding/FormLayout";
import OnboardingTooltip from "routes/Onboarding/OnboardingTooltip";
import styled from "styled-components";
import colors from "styles/colors";
import { v4 as uuidv4 } from "uuid";

import { useOnboardingContext } from "../../OnboardingContextProvider";
import { pledgeField } from "./fields";

const PledgeCopyBulletPoints = [
  "Address my biases and to challenge the dominant narratives that I consciously and unconsciously repeat, which have the potential to cause harm",
  "Build trust and value the insights gained through community engagement",
  "Learn and implement cultural humility and cultural safety into practice",
  "Be held accountable to use the information and access of this network to improve experiences and health outcomes for Black women and women of color",
  "Partner with Health in Her HUE to make health equity for Black women and women of color a reality.",
];

const TOOLTIP_MESSAGE =
  "The concept of cultural respect has a positive effect on patient care delivery by enabling providers to deliver services that are respectful of and responsive to the health beliefs, practices, cultural and linguistic needs of diverse patients.";

const CheckboxWrapper = styled.div`
  background-color: ${colors.SKIN[1]};
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 4px;
  padding: 14px 20px;
  width: 100%;
`;

const Tooltip = styled<any>(ReactTooltip)`
  font-family: Nunito Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 20px;
  max-width: 257px;
  text-align: left;
`;

const ListContainer = styled.div`
  li {
    margin-bottom: 8px;
  }

  li p {
    position: relative;
    right: -12px;
  }

  li::marker {
    color: ${colors.NUDE[5]};
    font-size: 23px;
  }

  ul {
    padding-inline-start: 20px;
  }
`;

const TooltipText = styled.span`
  color: ${colors.CORAL[5]};
  margin: 0 3px;
`;

const addInitialValueToFields = (initialValues, fields): any[] => {
  if (!initialValues) {
    return fields;
  }

  const fieldsWithInitialValues = fields.map((field) => ({
    ...field,
    initialValue: initialValues.agree_to_pledge,
  }));

  return fieldsWithInitialValues;
};

const Pledge = () => {
  const { isLoading, user } = useOnboardingContext();
  let pledgeFields: any[] = [];

  if (isLoading)
    return (
      <Container>
        <CircularProgress />
      </Container>
    );

  if (user) {
    pledgeFields = addInitialValueToFields(user, [pledgeField]);
  }

  return (
    <FormLayout fields={pledgeFields}>
      <OnboardingMobileLogo
        alt="app-bar-logo"
        src={OnboardingLogo}
        style={{ marginBottom: "31px" }}
      />

      <FormHeader title="Pledge" margin="0" />
      <Container
        direction="column"
        flexWrap="nowrap"
        gap="25px"
        maxWidth="500px"
        padding="40px 20px"
      >
        <Container gap="20px">
          <Tooltip textColor={colors.SKIN[1]} place="left" />
          <div>
            <Typography variant="body2" color={colors.GREY[9]}>
              As a listed provider, I am committed to providing care that is
              <TooltipText data-tip={TOOLTIP_MESSAGE}>
                culturally respectful
              </TooltipText>
              to Black women and women of color. I do so in agreement with the
              vision, mission, and values of the Health in her HUE platform.
            </Typography>
          </div>
          <div>
            <Typography variant="body2" color={colors.GREY[9]}>
              In alignment with Health in Her HUE’s health equity call to
              action, I acknowledge my commitment to the following expectations:
            </Typography>
          </div>
          <ListContainer>
            <ul>
              {PledgeCopyBulletPoints.map((point) => (
                <li key={uuidv4()}>
                  <Typography variant="body2" color={colors.GREY[9]}>
                    {point}
                  </Typography>
                </li>
              ))}
            </ul>
          </ListContainer>
        </Container>
        <Container justifyContent="flex-start" gap="12px">
          <CheckboxWrapper>
            <FormikCheckbox
              name="agree_to_pledge"
              label="I agree"
              checkedColor={colors.CORAL[5]}
            />
          </CheckboxWrapper>

          <Typography variant="body2" color={colors.GREY[6]}>
            For more information on our approach to health equity,{" "}
            <Link
              target="_blank"
              variant="link2"
              to={APP_ROUTES.healthEquityPledge}
              component={RouterLink}
              color={colors.CORAL[5]}
            >
              click here
            </Link>
          </Typography>

          <OnboardingTooltip
            title="It is important that you are aligned with our mission and our commitment to health equity."
            message="Why are we asking this information?"
            padding="30px 0 0 0"
          />
        </Container>
      </Container>
    </FormLayout>
  );
};

export default Pledge;
