import { BriefCaseIcon, DesktopIcon, HomeIcon } from "components/common/icons";
import React from "react";
import { isObjectEmpty } from "utils";

import { filterDuplicatedValues, findkeysToDelete } from "../../utils";
import { AutocompleteFieldProps, TextFieldProps } from "./fields";

export const CARE_DELIVERY_ICONS = [
  {
    icon: {
      Icon: (props) => <BriefCaseIcon {...props} />,
    },
  },
  {
    icon: {
      Icon: (props) => <HomeIcon {...props} />,
    },
  },
  {
    icon: {
      Icon: (props) => <DesktopIcon {...props} />,
    },
  },
];

export const addOptionsToFields = (fields, fieldOptions): any[] => {
  const options = Object.keys(fieldOptions);

  if (options.length === 0) {
    return fields;
  }

  return fields.map((field) => {
    if (field.name === "users_insurances_attributes") {
      return { ...field, options: fieldOptions.insurances };
    }

    if (field.name === "users_specialties_attributes") {
      return { ...field, options: fieldOptions.specialties };
    }

    if (field.name === "credential_id") {
      return { ...field, options: fieldOptions.credentials };
    }

    if (field.name === "users_attention_places_attributes") {
      const optionsWithIcons = fieldOptions.attention_places.map(
        (place, index) => ({
          ...place,
          icon: CARE_DELIVERY_ICONS[index].icon,
          value: place.id,
        })
      );
      return { ...field, options: optionsWithIcons };
    }

    return field;
  });
};

export const addInitialValueToFields = (
  initialValues,
  fields
): TextFieldProps[] | AutocompleteFieldProps[] => {
  if (!initialValues) {
    return fields;
  }

  const fieldsWithInitialValues = fields.map((field) => {
    if (field.name === "users_insurances_attributes") {
      return {
        ...field,
        initialValue: initialValues.insurances,
      };
    }

    if (field.name === "users_specialties_attributes") {
      return {
        ...field,
        initialValue:
          field.options
            ?.filter((option) => option.id === initialValues.specialties[0]?.id)
            .pop() || null,
      };
    }

    if (field.name === "credential_id") {
      return {
        ...field,
        initialValue:
          field.options
            ?.filter((option) => option.id === initialValues[field.name])
            .pop() || null,
      };
    }

    if (field.name === "hospital_affiliation_attributes") {
      return { ...field, initialValue: initialValues.hospital_affiliation };
    }

    if (field.name === "second_hospital_affiliation_attributes") {
      return {
        ...field,
        initialValue: initialValues.second_hospital_affiliation,
      };
    }

    if (field.name === "users_attention_places_attributes") {
      const hasAttentionPlaces = initialValues.attention_places.length > 0;

      return {
        ...field,
        initialValue: hasAttentionPlaces
          ? initialValues.attention_places.map((place) => place.id)
          : [field.options[0].id],
      };
    }

    return {
      ...field,
      initialValue: initialValues[field.name] || field.initialValue,
    };
  });

  return fieldsWithInitialValues;
};

export const parseProviderInfoRequest = (formValues, step, user) => {
  const payloadKeys = Object.keys(formValues);
  const {
    license_number,
    npi_number,
    about_me,
    credential_id,
    hospital_affiliation_attributes,
    second_hospital_affiliation_attributes,
    users_insurances_attributes,
    users_specialties_attributes,
    users_attention_places_attributes,
  } = formValues;

  if (payloadKeys.length === 0) {
    return formValues;
  }

  const selectedInsurances = users_insurances_attributes?.map((insurance) => ({
    insurance_id: insurance.id,
  }));

  const insurancesToDelete = findkeysToDelete(
    selectedInsurances,
    user.users_insurances,
    "insurance_id"
  );

  const insurances = filterDuplicatedValues(
    user.insurances,
    selectedInsurances,
    "insurance_id"
  );

  const selectedSpecialty = [
    { specialty_id: users_specialties_attributes?.id },
  ];

  const specialtyToDelete = findkeysToDelete(
    selectedSpecialty,
    user.users_specialties,
    "specialty_id"
  );

  const specialties = filterDuplicatedValues(
    user.specialties,
    selectedSpecialty,
    "specialty_id"
  );

  const selectedCareAttentionPlaces = users_attention_places_attributes?.map(
    (attentionPlace) => ({
      attention_place_id: attentionPlace,
    })
  );

  const careAttentionPlacesToDelete = findkeysToDelete(
    selectedCareAttentionPlaces,
    user.users_attention_places,
    "attention_place_id"
  );

  const careAttentionPlaces = filterDuplicatedValues(
    user.attention_places,
    selectedCareAttentionPlaces,
    "attention_place_id"
  );

  const parsedPayload: any = {
    user: {
      sign_up_stage: step + 1,
      license_number,
      npi_number,
      about_me,
      credential_id: credential_id?.id,
      users_specialties_attributes: [...specialties, ...specialtyToDelete],
      users_insurances_attributes: [...insurances, ...insurancesToDelete],
      users_attention_places_attributes: [
        ...careAttentionPlaces,
        ...careAttentionPlacesToDelete,
      ],
    },
  };

  if (
    hospital_affiliation_attributes &&
    !isObjectEmpty(hospital_affiliation_attributes)
  ) {
    parsedPayload.user.hospital_affiliation_attributes =
      hospital_affiliation_attributes;
  }

  if (
    hospital_affiliation_attributes &&
    !isObjectEmpty(second_hospital_affiliation_attributes)
  ) {
    parsedPayload.user.second_hospital_affiliation_attributes =
      second_hospital_affiliation_attributes;
  }

  return parsedPayload;
};
