import {
  FormikGoogleMapsAutocomplete,
  FormikTextInput,
} from "components/Form/FormikFields";
import {
  yupGoogleAutocomplete,
  yupNoEmptyArray,
  yupNumber,
  yupNumberRequired,
  yupRequiredArrayOfObjects,
  yupRequiredObject,
  yupTextAreaRequired,
} from "components/Form/validations";
import React from "react";

type TextFieldType = "text" | "autocomplete" | "googleAutocomplete";

export type TextFieldProps = {
  label: string;
  name: string;
  initialValue?: string;
  multiline?: boolean;
  type: TextFieldType;
  fieldPosition?: number;
  googleAutocomplete?: boolean;
};

export interface AutocompleteFieldProps extends TextFieldProps {
  multiple?: boolean;
  options: any[];
}

export const licenseField = {
  label: "License Or Certification Number",
  name: "license_number",
  initialValue: "",
  type: "text" as TextFieldType,
};

export const hospitalAfiliationsField = {
  label: "Hospital Affiliation (Optional)",
  name: "hospital_affiliation_attributes",
  initialValue: "",
  type: "googleAutocomplete" as TextFieldType,
  validation: yupGoogleAutocomplete,
};

export const secondHospitalAfiliationsField = {
  label: "Second Hospital Affiliation (Optional)",
  name: "second_hospital_affiliation_attributes",
  initialValue: "",
  type: "googleAutocomplete" as TextFieldType,
  validation: yupGoogleAutocomplete,
};

export const npiField = {
  label: "NPI Number",
  name: "npi_number",
  initialValue: "",
  type: "text" as TextFieldType,
  validation: yupNumber,
};

export const npiFieldRequired = {
  label: "NPI Number",
  name: "npi_number",
  initialValue: "",
  type: "text" as TextFieldType,
  validation: yupNumberRequired,
};

export const aboutMeField = {
  label: "Tell us about yourself",
  name: "about_me",
  initialValue: "",
  multiline: true,
  type: "text" as TextFieldType,
  validation: yupTextAreaRequired(),
};

export const insuranceField = {
  label: "Insurances",
  name: "users_insurances_attributes",
  options: [],
  multiple: true,
  type: "autocomplete" as TextFieldType,
  validation: yupRequiredArrayOfObjects,
};

export const specialtyField = {
  label: "Specialty",
  name: "users_specialties_attributes",
  options: [],
  type: "autocomplete" as TextFieldType,
  validation: yupRequiredObject,
};

export const credentialsField = {
  label: "Credentials",
  name: "credential_id",
  options: [],
  type: "autocomplete" as TextFieldType,
  validation: yupRequiredObject,
};

export const hospitalAffiliationFieldUI = (
  <FormikGoogleMapsAutocomplete
    name={hospitalAfiliationsField.name}
    label={hospitalAfiliationsField.label}
    types={["establishment"]}
  />
);

export const secondHospitalAffiliationFieldUI = (
  <FormikGoogleMapsAutocomplete
    name={secondHospitalAfiliationsField.name}
    label={secondHospitalAfiliationsField.label}
    types={["establishment"]}
  />
);

export const careDeliveryField = {
  name: "users_attention_places_attributes",
  options: [],
  validation: yupNoEmptyArray,
};

export const aboutMeFieldUI = <FormikTextInput {...aboutMeField} />;
