import { Box, CircularProgress, Grid } from "@mui/material";
import OnboardingLogo from "assets/logos/onboarding-logo.svg";
import HIHHStepper from "components/common/HIHHStepper";
import Form from "components/Form";
import { Container, OnboardingMobileLogo } from "components/styled";
import React from "react";
import {
  MEMBER_ONBOARDING_STEPS,
  PROVIDER_ONBOARDING_STEPS,
} from "routes/Onboarding";
import FormHeader from "routes/Onboarding/FormHeader";
import { FormContainer } from "routes/Onboarding/FormLayout";
import NavigationControls from "routes/Onboarding/NavigationControls";
import { useOnboardingContext } from "routes/Onboarding/OnboardingContextProvider";

import PlansMember from "./PlansMember";

const Membership = () => {
  const { step, user, submitForm, isLoading, setOnboardingMembership } =
    useOnboardingContext();

  const onboardingRoutes =
    user?.role === "provider"
      ? PROVIDER_ONBOARDING_STEPS
      : MEMBER_ONBOARDING_STEPS;

  return (
    <Box component={Grid} item md={6} xs={12} sx={{ paddingTop: "18px" }}>
      <Form fields={[]} onSubmit={submitForm}>
        <>
          <HIHHStepper steps={onboardingRoutes} activeStep={step - 1} />

          <FormContainer id="middle" alignItems="start">
            <Container gap="31px">
              <OnboardingMobileLogo src={OnboardingLogo} alt="app-bar-logo" />

              <FormHeader
                title="Choose your Membership"
                subtitle="Choose the type of membership you want to join the Health in Her Hue community."
              />
              {isLoading ? (
                <CircularProgress />
              ) : (
                <Container maxWidth="500px" gap="28px" padding="0 18px">
                  <PlansMember
                    setOnboardingMembership={setOnboardingMembership}
                  />
                </Container>
              )}
              <Container height="40px" />
            </Container>
          </FormContainer>

          <NavigationControls />
        </>
      </Form>
    </Box>
  );
};

export default Membership;
