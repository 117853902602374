import { Button } from "@mui/material";
import React from "react";
import colors from "styles/colors";

type PlanCTAProps = {
  isDisabled: boolean;
  label: string;
  variant: "outlined" | "contained";
  onClick: () => void;
};

const PlanCTA = ({ isDisabled, label, variant, onClick }: PlanCTAProps) => (
  <Button
    disabled={isDisabled}
    fullWidth
    onClick={onClick}
    sx={{
      mt: "22px",
      "&.Mui-disabled": { border: `1px solid ${colors.CORAL[3]}` },
    }}
    variant={variant}
  >
    {label}
  </Button>
);

export default PlanCTA;
