import React from "react";

const bcbsMnPage = () => (
  <>{
    window.location.replace('https://content.healthinherhue.com/mn')
  }</>
  
);

export default bcbsMnPage;
