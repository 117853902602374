import React from "react";

import { Tooltip, TooltipProps, tooltipClasses } from "@mui/material";
import styled from "styled-components";
import colors from "styles/colors";

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    classes={{ popper: className }}
    style={{ marginTop: "7px" }}
  />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    background: colors.GREY[7],
    borderRadius: "8px",
    color: colors.SKIN[1],
    fontSize: "11px",
    padding: "4px 12px",
  },
}));

const HIHHTooltip = (props: TooltipProps) => <CustomTooltip {...props} />;

export default HIHHTooltip;
