import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { APP_ROUTES } from "routes";
import {
  fireUserViewedPageEvent,
  fireUserVisitedCarePointEvent,
  fireUserVisitedNewPageAfterCarePointEvent,
} from "utils/analytics";

const allowedVisitsCount = process.env
  .REACT_APP_ALLOWED_VISITED_PAGES_AFTER_CARE_POINT_COUNT
  ? parseInt(
      process.env.REACT_APP_ALLOWED_VISITED_PAGES_AFTER_CARE_POINT_COUNT,
      10
    )
  : 3;

const usePageViews = (user) => {
  const location = useLocation();
  const prevLocation = useRef<string>("");
  const visitedPagesAfterCarePoint = useRef<number>(0);

  useEffect(() => {
    const isPrevLocationCarePoint =
      prevLocation.current === APP_ROUTES.carePoint;
    const isFirstVisitAfterCarePoint = visitedPagesAfterCarePoint.current === 0;
    const isWithinAllowedVisitsAfterCarePoint =
      visitedPagesAfterCarePoint.current < allowedVisitsCount;

    if (
      (isPrevLocationCarePoint && isFirstVisitAfterCarePoint) ||
      (!isFirstVisitAfterCarePoint && isWithinAllowedVisitsAfterCarePoint)
    ) {
      visitedPagesAfterCarePoint.current += 1;
      fireUserVisitedNewPageAfterCarePointEvent(
        location.pathname,
        visitedPagesAfterCarePoint.current,
        user
      );
    } else {
      visitedPagesAfterCarePoint.current = 0;
    }

    if (location.pathname === APP_ROUTES.carePoint && user) {
      fireUserVisitedCarePointEvent(user);
    }

    window.analytics.page(location.pathname);
    prevLocation.current = location.pathname;
    fireUserViewedPageEvent(user, location.pathname);
  }, [location.pathname]);
};

export default usePageViews;
