import { Checkbox, FormControlLabel, Switch } from "@mui/material";
import GoogleMapsAutocomplete from "components/common/GoogleAutocompleteField";
import HIHHAutocomplete, {
  AutocompleteOption,
} from "components/common/HIHHAutocomplete";
import HIHHDateField from "components/common/HIHHDateField";
import HIHHIconToggleButtonGroup from "components/common/HIHHIconToggleButtonGroup";
import HIHHTextField from "components/common/HIHHTextField";
import HIHHToggleButtonGroup from "components/common/HIHHToggleButtonGroup";
import { CheckedCheckbox, DefaultCheckbox } from "components/common/icons";
import CropImage from "components/CropImage";
import { ImageSizeType } from "components/ProfilePictureModal";
import { useFormikContext } from "formik";
import useDebounce from "hooks/useDebounce";
import React from "react";
import colors from "styles/colors";

import useFormikField from "./hooks/useFormikField";

type FormikTextInputProps = {
  disabled?: boolean;
  endAdornment?: React.ReactNode;
  hideNumberCaret?: boolean;
  icon?: React.ReactNode;
  label?: string;
  maxChar?: number;
  minRows?: number;
  multiline?: boolean;
  name: string;
  placeholder?: string;
  type?: string;
  onErrors?: (boolean, string) => void;
  autoComplete?: string;
};

export const FormikTextInput: React.FC<FormikTextInputProps> = ({
  disabled = false,
  hideNumberCaret = false,
  icon = null,
  label = "",
  minRows,
  multiline = false,
  name,
  placeholder = "",
  type = "text",
  onErrors = null,
  maxChar = 700,
  autoComplete = "on",
  endAdornment = null,
  ...props
}) => {
  const { value, setValue, touched, setTouched, hasError, errorMsg } =
    useFormikField(name);

  const onChange = (fieldValue: string) => {
    setTouched(true);
    setValue(fieldValue);
  };

  if (onErrors) {
    onErrors(hasError, value);
  }

  return (
    <HIHHTextField
      onBlur={() => setTouched(true)}
      onChange={(inputValue) => onChange(inputValue)}
      error={hasError}
      helperText={touched ? errorMsg : undefined}
      endAdornment={endAdornment}
      {...{
        disabled,
        hideNumberCaret,
        icon,
        label,
        maxChar,
        minRows,
        multiline,
        name,
        placeholder,
        type,
        value,
        autoComplete,
        ...props,
      }}
    />
  );
};

type FormikAutocompleteProps = {
  label: string;
  name: string;
  options: AutocompleteOption[];
  placeholder?: string;
  multiple?: boolean;
  disabled?: boolean;
};

export const FormikAutocomplete: React.FC<FormikAutocompleteProps> = ({
  name,
  options,
  placeholder,
  label = "",
  multiple = false,
  disabled = false,
  ...props
}) => {
  const { value, hasError, setValue, errorMsg, touched, setTouched } =
    useFormikField(name);
  const { dirty } = useFormikContext();

  return (
    <HIHHAutocomplete
      {...props}
      {...{
        dirty,
        errorMsg,
        hasError,
        label,
        multiple,
        name,
        options,
        placeholder,
        value,
        touched,
        disabled,
      }}
      onChange={(newValue) => {
        if (multiple) {
          const mappedValue = newValue
            ? newValue.filter((v) => typeof v !== "string")
            : newValue;
          setValue(mappedValue);
        } else {
          setValue(typeof newValue !== "string" ? newValue : null);
        }
      }}
      onBlur={() => setTouched(true)}
    />
  );
};

type FormikToggleButtonGroupProps = {
  name: string;
  options: any[];
};

export const FormikToggleButtonGroup: React.FC<
  FormikToggleButtonGroupProps
> = ({ name, options }) => {
  const { value, setValue, errorMsg } = useFormikField(name);
  const { dirty } = useFormikContext();

  const handleChange = (event, newValue) => {
    if (newValue) {
      setValue(newValue);
    }
  };

  const props = {
    dirty,
    errorMsg,
    value,
    options,
  };

  return <HIHHToggleButtonGroup onChange={handleChange} {...props} />;
};

export const FormikIconToggleButtonGroup: React.FC<
  FormikToggleButtonGroupProps
> = ({ name, options }) => {
  const { value, setValue, errorMsg } = useFormikField(name);

  const { dirty } = useFormikContext();

  const handleChange = (event, newValue) => {
    if (newValue) {
      setValue(newValue);
    }
  };

  const props = {
    dirty,
    errorMsg,
    value,
    options,
  };

  return <HIHHIconToggleButtonGroup onChange={handleChange} {...props} />;
};

type FormikCheckboxProps = {
  name: string;
  label?: string;
  checkedColor?: string;
  callback?: (value: boolean) => void;
};

export const FormikCheckbox: React.FC<FormikCheckboxProps> = ({
  name,
  label = "",
  checkedColor = colors.NUDE[5],
  callback,
}) => {
  const { value, setValue, setTouched } = useFormikField(name);

  const handleChange = () => {
    setTouched(true);
    setValue(!value);
    if (callback) callback(value);
  };

  return (
    <FormControlLabel
      control={
        <Checkbox
          onChange={handleChange}
          name={name}
          icon={<DefaultCheckbox color={colors.GREY[4]} />}
          checkedIcon={<CheckedCheckbox color={checkedColor} />}
          checked={!!value}
        />
      }
      label={label}
    />
  );
};

type FormikGoogleMapsAutocompleteProps = {
  label: string;
  name: string;
  types?: string[];
};

export const FormikGoogleMapsAutocomplete: React.FC<
  FormikGoogleMapsAutocompleteProps
> = ({ name, label = "", types = [] }) => {
  const { value, setValue, touched, hasError, errorMsg, setTouched } =
    useFormikField(name);

  const onChange = (fieldValue: any) => {
    setTouched(true);
    setValue(fieldValue);
  };

  const { _value, set_value } = useDebounce(value, onChange, 400);

  return (
    <GoogleMapsAutocomplete
      errorMsg={errorMsg}
      onBlur={() => setTouched(true)}
      onChange={set_value}
      value={_value}
      {...{ name, label, touched, hasError, types }}
    />
  );
};

type FormikSwitchProps = {
  label: string;
  name: string;
  noMargin?: boolean;
};

export const FormikSwitch: React.FC<FormikSwitchProps> = ({
  name,
  label,
  noMargin = false,
}) => {
  const { value, setValue } = useFormikField(name);

  const handleChange = () => setValue(!value);

  return (
    <FormControlLabel
      control={<Switch name={name} checked={!!value} onChange={handleChange} />}
      label={label}
      sx={!noMargin ? { marginTop: "20px" } : null}
    />
  );
};

type FormikUploadCropImageProps = {
  name: string;
  imageSrc: string;
  imageSize: ImageSizeType;
};

export const FormikUploadCropImage: React.FC<FormikUploadCropImageProps> = ({
  name,
  imageSrc,
  imageSize,
}) => {
  const { setValue } = useFormikField(name);

  const props = { imageSrc, imageSize };

  return (
    <div>{imageSrc && <CropImage setCroppedImage={setValue} {...props} />}</div>
  );
};

type FormikDateInputProps = {
  name: string;
  label?: string;
  placeholder?: string;
  onErrors?: (boolean, string) => void;
};
export const FormikDateInput: React.FC<FormikDateInputProps> = ({
  label,
  name,
  placeholder,
  onErrors = null,
}) => {
  const { value, setValue, touched, setTouched, hasError, errorMsg } =
    useFormikField(name);

  if (onErrors) {
    onErrors(hasError, value);
  }

  return (
    <HIHHDateField
      error={hasError}
      helperText={touched ? errorMsg : undefined}
      onChange={setValue}
      onBlur={() => setTouched(true)}
      {...{ label, name, value, placeholder }}
    />
  );
};
