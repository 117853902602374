import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Typography } from "@mui/material";

import api from "api";
import Footer from "components/Footer";
import NavBar from "components/NavBar";
import styled from "styled-components";
import { Container } from "components/styled";
import PaymentsForm from "routes/AccountSettings/Member/Subscription/common/PaymentsForm";
import { useSubscriptionContext } from "routes/AccountSettings/Member/Subscription/SubscriptionContextProvider";
import { APP_ROUTES } from "routes";
import { addToast } from "components/common/HIHHToast";
import colors from "styles/colors";
import PageLoader from "components/common/PageLoader";
import Pricing from "./Pricing";
import SuccessPaymentModal from "./SuccessPaymentModal";

const ContentContainer = styled(Container)`
  align-items: flex-start;
  display: flex;
  justify-content: center;
  max-width: 1200px;
  margin-bottom: 70px;
  width: 100%;

  @media all and (max-width: 1030px) {
    flex-direction: column;
    gap: 28px 0;
    padding: 0;
  }
`;

const FormContainer = styled.div`
  position: relative;
  height: auto;
  flex: 1;
  margin-left: 20px;
  max-width: 670px;
  min-width: 650px;

  & form {
    margin-left: auto;
  }

  @media all and (max-width: 1030px) {
    margin: 0;
    max-width: none;
    min-width: auto;
    width: 100%;
  }
`;

const NavBarContainer = styled.div`
  width: 100%;
  height: 64px;
`;

const VCSPayment = () => {
  const { virtualCareSquadId } = useParams<any>();
  const [virtualCareSquad, setVirtualCareSquad] = useState<any>(null);
  const [selectedPriceId, setSelectedPriceId] = useState<any>();
  const [isLoading, setisLoading] = useState(false);
  const [isSuccessPaymentModalOpen, setIsSuccessPaymentModalOpen] =
    useState(false);
  const navigate = useNavigate();
  const {
    createVCSSubscription,
    stopProcessLoading,
    getCurrentVCSSubscriptions,
    currentVCSSubscription,
    currentVCSSubscriptions,
    setCurrentVCSSubscription,
  } = useSubscriptionContext();

  const fetchVirtualCareSquad = async () => {
    setisLoading(true);
    const { data } = await api.get(
      `/virtual_care_squads/${virtualCareSquadId}`
    );
    if (data && data.prices.length > 0) {
      setSelectedPriceId(data.prices[0].id.toString());
      setVirtualCareSquad(data);
    } else {
      addToast("This Virtual Care Squad is not completed", "error");
    }
    setisLoading(false);
  };

  const onSuccessPaymentModalClose = useCallback(() => {
    navigate(`${APP_ROUTES.accountSettings}?tabIndexValue=3`);
  }, []);

  useEffect(() => {
    fetchVirtualCareSquad();
    getCurrentVCSSubscriptions();
  }, [virtualCareSquadId]);

  if (currentVCSSubscriptions && currentVCSSubscriptions.length > 0) {
    const currentVCSSub = currentVCSSubscriptions.find(
      (vcs) => vcs.virtual_care_squad_id.toString() === virtualCareSquadId
    );

    if (currentVCSSub) {
      setCurrentVCSSubscription(currentVCSSub);
    }
  }

  const handleAfterSubmit = async () => {
    try {
      await createVCSSubscription(virtualCareSquadId, selectedPriceId);
      setIsSuccessPaymentModalOpen(true);
    } catch {
      stopProcessLoading();
    }
  };

  const selectedPrice = virtualCareSquad?.prices.find(
    (price) => price.id.toString() === selectedPriceId
  ).price;

  const planDescriptionUI = (
    <Container
      alignItems="start"
      direction="column"
      gap="3px"
      width="auto"
      padding="0 16px"
    >
      <Typography variant="subtitle2" color={colors.BRONZE[5]}>
        VIRTUAL CARE SQUADS
      </Typography>

      <Typography
        variant="h2"
        color={colors.NUDE[9]}
        style={{ marginBottom: "20px" }}
      >
        {virtualCareSquad?.title}
      </Typography>

      {virtualCareSquad && (
        <Pricing
          virtualCareSquadPrices={virtualCareSquad?.prices}
          selectedPriceId={selectedPriceId}
          setSelectedPriceId={setSelectedPriceId}
        />
      )}
    </Container>
  );

  if (isLoading) return <PageLoader />;

  return (
    <Container
      justifyContent="space-between"
      direction="column"
      height="100vh"
      overflowY="scroll"
      flexWrap="nowrap"
    >
      <Container>
        <NavBarContainer>
          <NavBar />
        </NavBarContainer>

        <ContentContainer margin="64px 0">
          {planDescriptionUI}
          <FormContainer>
            <PaymentsForm
              afterSubmit={handleAfterSubmit}
              creationForm
              hideTitle
              customButtonLabel={`Pay $${selectedPrice}`}
              disabledButton={!!currentVCSSubscription}
            />
          </FormContainer>
        </ContentContainer>
      </Container>

      <Footer />
      <SuccessPaymentModal
        virtualCareSquad={virtualCareSquad?.description}
        isOpen={isSuccessPaymentModalOpen}
        onClose={onSuccessPaymentModalClose}
      />
    </Container>
  );
};

export default VCSPayment;
