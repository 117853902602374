/* eslint-disable react/jsx-no-useless-fragment */
import { Typography } from "@mui/material";
import OnboardingLogo from "assets/logos/onboarding-logo.svg";
import { addToast } from "components/common/HIHHToast";
import { PlusIcon } from "components/common/icons";
import PageLoader from "components/common/PageLoader";
import {
  FormikAutocomplete,
  FormikIconToggleButtonGroup,
  FormikTextInput,
} from "components/Form/FormikFields";
import { ButtonLink, Container, OnboardingMobileLogo } from "components/styled";
import { FormikValues, useFormikContext } from "formik";
import useStepFieldOptions from "hooks/Onboarding/useStepFieldOptions";
import React, { useEffect, useRef, useState } from "react";
import colors from "styles/colors";
import { isObjectEmpty } from "utils";

import FormHeader from "routes/Onboarding/FormHeader";
import FormLayout from "routes/Onboarding/FormLayout";
import { useOnboardingContext } from "routes/Onboarding/OnboardingContextProvider";
import OnboardingTooltip from "routes/Onboarding/OnboardingTooltip";
import {
  aboutMeField,
  aboutMeFieldUI,
  AutocompleteFieldProps,
  careDeliveryField,
  credentialsField,
  hospitalAffiliationFieldUI,
  hospitalAfiliationsField,
  insuranceField,
  licenseField,
  npiField,
  npiFieldRequired,
  secondHospitalAffiliationFieldUI,
  secondHospitalAfiliationsField,
  specialtyField,
} from "./fields";
import { addInitialValueToFields, addOptionsToFields } from "./utils";

const NpiField = ({ field, setField }: any) => {
  const { values, setFieldValue } = useFormikContext<FormikValues>();
  const { users_specialties_attributes, npi_number } = values;
  const [label, setLabel] = useState(field.label);
  const [requiresNpiNumber, setRequiresNpiNumber] = useState<any>(null);
  const [showField, setShowField] = useState(true);

  useEffect(() => {
    setRequiresNpiNumber(users_specialties_attributes?.requires_npi_number);
  }, [users_specialties_attributes]);

  useEffect(() => {
    if (!requiresNpiNumber) return;

    const isOptional = requiresNpiNumber === "optional";
    const isRequired = requiresNpiNumber === "true";

    setField(requiresNpiNumber === "true" ? npiFieldRequired : npiField);
    setShowField(
      requiresNpiNumber === "true" || requiresNpiNumber === "optional"
    );
    setLabel(
      requiresNpiNumber === "optional"
        ? `${field.label} (Optional)`
        : field.label
    );
    setFieldValue(
      "npi_number",
      isRequired || isOptional ? npi_number || "" : ""
    );
  }, [requiresNpiNumber]);

  return <>{showField && <FormikTextInput {...field} label={label} />}</>;
};

const ProviderInfo = () => {
  const { user, step } = useOnboardingContext();
  const hospitalButton = useRef<any>(null);
  const hospitalFields = useRef<any>(null);
  const [dynamicNpiField, setDynamicNpiField] = useState<any>(npiField);

  const { isLoading, data, error } = useStepFieldOptions(step);

  if (isLoading) {
    return <PageLoader isBlockLoader />;
  }

  if (error) {
    addToast(error.message, "error");
    return null;
  }

  const stepFieldsOptions = data?.step_fields ?? [];

  const fieldsWithOptions: AutocompleteFieldProps[] = addOptionsToFields(
    [insuranceField, specialtyField, credentialsField, careDeliveryField],
    stepFieldsOptions
  );

  const insuranceFieldWithOptions = fieldsWithOptions.find(
    (field) => field.name === "users_insurances_attributes"
  );
  const specialtyFieldWithOptions = fieldsWithOptions.find(
    (field) => field.name === "users_specialties_attributes"
  );
  const credentialsFieldWithOptions = fieldsWithOptions.find(
    (field) => field.name === "credential_id"
  );

  const careDeliveryFieldWithOptions = fieldsWithOptions.find(
    (field) => field.name === "users_attention_places_attributes"
  );

  let providerInfoFields: any[] = [];

  if (user) {
    providerInfoFields = addInitialValueToFields(user, [
      licenseField,
      dynamicNpiField,
      aboutMeField,
      hospitalAfiliationsField,
      secondHospitalAfiliationsField,
      careDeliveryFieldWithOptions,
      ...fieldsWithOptions,
    ]);
  }

  if (user) {
    if (
      hospitalFields.current &&
      !isObjectEmpty(user.second_hospital_affiliation)
    ) {
      hospitalFields.current.style.display = "flex";
      hospitalButton.current.style.display = "none";
    }
  }

  const addSecondaryHospital = () => {
    hospitalButton.current.style.display = "none";
    hospitalFields.current.style.display = "flex";
  };

  return (
    <FormLayout fields={providerInfoFields}>
      <Container direction="column" padding="0 18px">
        <OnboardingMobileLogo
          src={OnboardingLogo}
          alt="app-bar-logo"
          style={{ marginBottom: "31px" }}
        />

        <FormHeader
          title="Provider Information"
          subtitle="Tell us about your practice."
          margin="0 0 40px 0"
        />

        <Container maxWidth="500px" gap="30px">
          {insuranceFieldWithOptions && (
            <FormikAutocomplete {...insuranceFieldWithOptions} />
          )}

          {specialtyFieldWithOptions && (
            <FormikAutocomplete {...specialtyFieldWithOptions} />
          )}

          <FormikTextInput {...licenseField} />

          {credentialsFieldWithOptions && (
            <FormikAutocomplete {...credentialsFieldWithOptions} />
          )}

          {careDeliveryFieldWithOptions && (
            <Container gap="8px" justifyContent="flex-start">
              <Typography variant="link3" color={colors.GREY[3]}>
                Care Delivery
              </Typography>
              <Container justifyContent="space-between">
                <FormikIconToggleButtonGroup
                  {...careDeliveryFieldWithOptions}
                />
              </Container>
            </Container>
          )}

          <NpiField field={dynamicNpiField} setField={setDynamicNpiField} />
          {hospitalAffiliationFieldUI}
          <Container
            direction="column"
            ref={hospitalFields}
            style={{ display: "none", marginBottom: "30px" }}
          >
            {secondHospitalAffiliationFieldUI}
          </Container>
        </Container>
        <Container
          maxWidth="500px"
          justifyContent="flex-start"
          ref={hospitalButton}
        >
          <ButtonLink variant="text" onClick={addSecondaryHospital}>
            <PlusIcon />
            <Typography variant="link1">Add secondary affiliation</Typography>
          </ButtonLink>
        </Container>
        <Container maxWidth="500px">
          {aboutMeFieldUI}
          <OnboardingTooltip
            title="We require this information so that users can better understand the care that you provide."
            message="Why are we asking this information?"
            padding="30px 0 0 0"
          />
        </Container>

        <Container height="40px" />
      </Container>
    </FormLayout>
  );
};

export default ProviderInfo;
