import api from "api";
import { useQuery } from "react-query";

type FieldOptions = {
  step_fields: any[];
};

const fetchStepFieldOptions = async (step: number): Promise<FieldOptions> => {
  const response = await api.get(`/users/sign_up_steps/${step}`);
  return response.data;
};

const useStepFieldOptions = (onboardingStep: number) =>
  useQuery<FieldOptions, Error>(["stepFieldOptions", onboardingStep], () =>
    fetchStepFieldOptions(onboardingStep)
  );

export default useStepFieldOptions;
