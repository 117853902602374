import React, { CSSProperties } from "react";
import toast, {
  ToastBar,
  Toaster,
  ToastPosition,
  ToastOptions,
  ToastType,
} from "react-hot-toast";

import HIHHAlert from "./HIHHAlert";

const defaultDuration = 5000;
const defaultPosition: ToastPosition = "bottom-center";
const toastBorderRadius = 4;
const toastMaxWidth = 600;

export const addToast = (
  msg: JSX.Element | string,
  type: ToastType = "blank",
  toastOptions: ToastOptions = {}
): void => {
  const options: ToastOptions = {
    duration: defaultDuration,
    position: defaultPosition,
    ...toastOptions,
  };
  const toastFn = type === "blank" ? toast?.loading : toast[type];
  toastFn(msg, options);
};

const toastContainerCss: CSSProperties = {
  padding: 0,
  borderRadius: toastBorderRadius,
  boxShadow: "none",
  maxWidth: toastMaxWidth,
};

const toastTypeStyles = {
  success: {
    severity: "success",
  },
  error: {
    severity: "error",
  },
  blank: {
    severity: "info",
  },
};

const HIHHToast = () => (
  <Toaster
    toastOptions={{
      style: toastContainerCss,
    }}
  >
    {(t) => (
      <ToastBar toast={t}>
        {({ message }) => {
          const { severity } = toastTypeStyles[t.type] || {};

          return (
            <HIHHAlert severity={severity} onClose={() => toast.dismiss(t.id)}>
              {message}
            </HIHHAlert>
          );
        }}
      </ToastBar>
    )}
  </Toaster>
);

export default HIHHToast;
