import { Typography } from "@mui/material";
import { PlusIcon } from "components/common/icons";
import { FormikSwitch } from "components/Form/FormikFields";
import { ButtonLink, Container } from "components/styled";
import React, { useCallback, useMemo, useState } from "react";
import { useOnboardingContext } from "routes/Onboarding/OnboardingContextProvider";
import OnboardingTooltip from "routes/Onboarding/OnboardingTooltip";
import { isObjectEmpty } from "utils";

import {
  practiceDigitalFieldsUI,
  practiceMainLocationFieldsUI,
  practiceSecondaryLocationFieldsUI,
  switchField,
} from "./fields";
import ContactFormHeader from "./FormHeader";

type PracticeFieldsProps = {};

const PracticeFields: React.FC<PracticeFieldsProps> = () => {
  const { user } = useOnboardingContext();

  const hasSecondaryLocationFilled = useMemo(
    () =>
      user &&
      user.practice_contact?.second_address &&
      !isObjectEmpty(user.practice_contact?.second_address),
    [user]
  );

  const [showSecondaryLocation, setShowSecondaryLocation] = useState(
    hasSecondaryLocationFilled
  );

  const addPracticeSecondaryLocation = useCallback(() => {
    setShowSecondaryLocation(true);
  }, []);

  return (
    <Container maxWidth="500px" direction="column" alignItems="start">
      <ContactFormHeader
        title="Practice Information"
        subtitle="Where is your practice located?"
      />
      <Container direction="column" gap="28px" alignItems="start">
        {practiceMainLocationFieldsUI}
      </Container>
      {showSecondaryLocation ? null : (
        <ButtonLink
          variant="text"
          onClick={addPracticeSecondaryLocation}
          id="practice-add-second-location"
        >
          <PlusIcon />
          <Typography variant="link1">Add second location</Typography>
        </ButtonLink>
      )}
      {showSecondaryLocation ? (
        <Container direction="column" gap="28px" margin="28px 0">
          {practiceSecondaryLocationFieldsUI}
        </Container>
      ) : null}
      <Container direction="column" gap="28px">
        {practiceDigitalFieldsUI}
      </Container>
      <FormikSwitch label={switchField.label} name={switchField.name} />

      <OnboardingTooltip
        title="We require this information so that users will know how to contact and find your practice."
        message="Why are we asking this information?"
        padding="30px 0 0 0"
      />
    </Container>
  );
};

export default PracticeFields;
