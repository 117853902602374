import api from "api";
import React, { createContext, useContext, useEffect, useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from "use-query-params";
import { stringArrayToIntArray } from "utils";
import { fireUserSearchedProvidersEvent } from "utils/analytics";

const DEFAULT_PAGE = 1;
const ITEMS_PER_PAGE = 20;
const DEFAULT_TAB = 0;

type LocationGeometry = {
  latitude: string;
  longitude: string;
  name: string;
};

type State = {
  careDeliveries: any[];
  careProficiencies: any[];
  insurances: any[];
  isLoading: boolean;
  languages: any[];
  location: LocationGeometry | null;
  pronouns: any[];
  races: any[];
  queryParams: any;
  searchGoogleTerm: any;
  searchTerm: string | null;
  selectedFilters: any;
  specialties: any[];
  handleClearAllFilters: (infoFiltersOnly?: boolean) => void;
  handleLocationChange: (location: any) => void;
  handleRemoveFilter: (params: any) => void;
  handleSearchChange: (event: any) => void;
  handleSearchClick: (
    isDirectoryPage: boolean,
    userData: any,
    externalFilters?: any
  ) => void;
  setSearchGoogleTerm: (place: any) => void;
  setSelectedFilters: (filter: any) => void;
  setSearchTerm: (term: any) => void;
};

const filtersInitialState = {
  specialties: [],
  insurances: [],
  languages: [],
  pronouns: [],
  careProficiencies: [],
  careDeliveries: [],
  races: [],
};

const selectedFiltersInitialState = {
  attention_place: [],
  care_proficiency: [],
  insurance: [],
  language: [],
  pronoun: [],
  specialty: [],
  race: [],
};

const ProvidersFilterContext = createContext<State>({} as State);

export const ProvidersFilterContextProvider: React.FC = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);

  const [providerFilters, setProviderFilters] = useState(filtersInitialState);

  const [searchGoogleTerm, setSearchGoogleTerm] = useState<string | null>(null);

  const [queryParams, setQueryParams] = useQueryParams({
    page: withDefault(NumberParam, DEFAULT_PAGE),
    items: withDefault(NumberParam, ITEMS_PER_PAGE),
    query: withDefault(StringParam, ""),
    filter_by: StringParam,
    specialty_ids: StringParam,
    insurance_ids: StringParam,
    language_ids: StringParam,
    pronoun_ids: StringParam,
    race_ids: StringParam,
    care_proficiency_ids: StringParam,
    attention_place_ids: StringParam,
    latitude: StringParam,
    longitude: StringParam,
    location: StringParam,
    tabIndexValue: withDefault(NumberParam, DEFAULT_TAB),
  });

  const [selectedFilters, setSelectedFilters] = useState(
    selectedFiltersInitialState
  );

  useEffect(() => {
    setSelectedFilters((filter) => ({
      ...filter,
      attention_place: stringArrayToIntArray(queryParams.attention_place_ids),
      care_proficiency: stringArrayToIntArray(queryParams.care_proficiency_ids),
      insurance: stringArrayToIntArray(queryParams.insurance_ids),
      language: stringArrayToIntArray(queryParams.language_ids),
      pronoun: stringArrayToIntArray(queryParams.pronoun_ids),
      race: stringArrayToIntArray(queryParams.race_ids),
      specialty: stringArrayToIntArray(queryParams.specialty_ids),
    }));
  }, [
    queryParams.attention_place_ids,
    queryParams.care_proficiency_ids,
    queryParams.insurance_ids,
    queryParams.language_ids,
    queryParams.pronoun_ids,
    queryParams.specialty_ids,
  ]);

  const [searchTerm, setSearchTerm] = useState(queryParams.query || null);

  const [location, setLocation] = useState<LocationGeometry | null>(null);

  const navigate = useNavigate();

  const fetchFilterOptions = async () => {
    setIsLoading(true);

    const {
      data: { data: specialties },
    } = await api.get(`/specialties`);

    const {
      data: { data: insurances },
    } = await api.get(`/insurances`);

    const {
      data: { data: languages },
    } = await api.get("/languages");

    const {
      data: { data: pronouns },
    } = await api.get("/pronouns");

    const {
      data: { data: races },
    } = await api.get("/races");

    const {
      data: { data: careProficiencies },
    } = await api.get("/care_proficiencies");

    const {
      data: { data: careDeliveries },
    } = await api.get("/attention_places");

    setProviderFilters((filters) => ({
      ...filters,
      careDeliveries,
      careProficiencies,
      insurances,
      languages,
      pronouns,
      specialties,
      races,
    }));

    setIsLoading(false);
  };

  useEffect(() => {
    fetchFilterOptions();
  }, []);

  const handleSearchChange = (providerName) => setSearchTerm(providerName);

  const handleRemoveFilter = (filteredParams) => setQueryParams(filteredParams);

  const handleLocationChange = (geometry) => setLocation(geometry);

  const handleSearchClick = (
    isDirectoryPage,
    userData,
    externalFilters: any = {}
  ) => {
    const internalSearchTerm = externalFilters.searchTerm ?? searchTerm;
    const internalSearchGoogleTerm =
      externalFilters.searchGoogleTerm ?? searchGoogleTerm;
    const internalSelectedFilters =
      externalFilters.selectedFilters ?? selectedFilters;

    let searchParams: { [key: string]: string } = {
      query: internalSearchTerm || "",
    };

    if (location?.name) {
      searchParams = {
        ...searchParams,
        latitude: location.latitude,
        longitude: location.longitude,
        location: location.name,
      };
    }

    const selectedFilterKeys = Object.keys(internalSelectedFilters);

    const filterBy: string[] = [];
    let filterParams = {};

    selectedFilterKeys.forEach((filterKey) => {
      if (internalSelectedFilters[filterKey].length > 0) {
        filterBy.push(`${filterKey}`);
        filterParams = {
          ...filterParams,
          [`${filterKey}_ids`]: `${internalSelectedFilters[filterKey].join()}`,
        };
      }
    });

    searchParams = {
      ...searchParams,
      ...filterParams,
      ...(filterBy.length > 0 ? { filter_by: filterBy.join() } : null),
    };

    const numberOfSearchParams = Object.keys(searchParams).length;
    if (
      numberOfSearchParams > 1 ||
      (numberOfSearchParams === 1 && searchParams.query.length > 0)
    ) {
      fireUserSearchedProvidersEvent(searchParams, providerFilters, userData);
    }

    if (isDirectoryPage) {
      setQueryParams(searchParams, "push");
      if (internalSearchTerm === "") setSearchTerm(null);
      if (internalSearchGoogleTerm === "") setSearchGoogleTerm(null);
    } else {
      navigate({
        pathname: "directory/providers",
        search: `?${createSearchParams(searchParams)}`,
      });
    }
  };

  const handleClearAllFilters = (infoFiltersOnly = false) => {
    if (infoFiltersOnly) {
      setSelectedFilters(selectedFiltersInitialState);
      setQueryParams({
        filter_by: null,
        specialty_ids: null,
        insurance_ids: null,
        language_ids: null,
        pronoun_ids: null,
        care_proficiency_ids: null,
        attention_place_ids: null,
        race_ids: null,
      });
      return;
    }

    setSelectedFilters(selectedFiltersInitialState);
    setSearchGoogleTerm("");
    handleSearchChange("");
    handleLocationChange("");
    setQueryParams({}, "push");
  };

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value = {
    careDeliveries: providerFilters.careDeliveries,
    careProficiencies: providerFilters.careProficiencies,
    insurances: providerFilters.insurances,
    isLoading,
    languages: providerFilters.languages,
    location,
    pronouns: providerFilters.pronouns,
    races: providerFilters.races,
    queryParams,
    searchGoogleTerm,
    searchTerm,
    selectedFilters,
    specialties: providerFilters.specialties,
    handleClearAllFilters,
    handleLocationChange,
    handleRemoveFilter,
    handleSearchChange,
    handleSearchClick,
    setSearchGoogleTerm,
    setSelectedFilters,
    setSearchTerm,
  };

  return (
    <ProvidersFilterContext.Provider value={value}>
      {children}
    </ProvidersFilterContext.Provider>
  );
};

export const useProvidersFilterContext = () => {
  const context = useContext(ProvidersFilterContext);
  if (typeof context === "undefined") {
    throw new Error("useSession must be used within a SessionContext");
  }
  return context;
};
