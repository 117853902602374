import { CircularProgress } from "@mui/material";
import React from "react";
import styled from "styled-components";
import useVirtualCareSquads from "./useVirtualCareSquads";
import VCSCard from "./VCSCard";

const Container = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 24px;
  margin: -450px auto auto auto;
  max-width: 1200px;
  padding: 0 20px 72px;

  @media all and (min-width: 1200px) {
    margin-top: -331px;
    padding: 0 112px 120px;
  }
`;

const LoadingState = styled.div`
  display: flex;
  justify-content: center;
  margin-top: -300px;
  width: 100%;
`;

const CareSquadCards = () => {
  const { isLoading, data } = useVirtualCareSquads();

  if (isLoading) {
    return (
      <LoadingState>
        <CircularProgress />
      </LoadingState>
    );
  }

  return (
    <Container>
      {data?.map((item) => (
        <VCSCard item={item} key={item.id} />
      ))}
    </Container>
  );
};

export default CareSquadCards;
