import { LockIcon, UserIcon } from "components/common/icons";
import React from "react";
import { yupEmail, yupPassword } from "components/Form/validations";

const signInFields = [
  {
    label: "Email",
    name: "email",
    initialValue: "",
    validation: yupEmail,
    icon: {
      StartIcon: (props) => <UserIcon {...props} />,
    },
  },
  {
    label: "Password",
    name: "password",
    initialValue: "",
    inputProps: {
      type: "password",
    },
    validation: yupPassword,
    icon: {
      StartIcon: (props) => <LockIcon {...props} />,
    },
  },
];

export default signInFields;
