import { statesById } from "data/states";
import { getPath, isObjectEmpty } from "utils";

type AddressAttributesType = {
  display_name: string;
  name: string;
  address_for: string;
  apartment: string;
  city: string;
  state: string;
  country: string;
  lonlat: string;
  info_city: string;
  info_state: string;
};

type ContactAttributesType = {
  email?: string;
  website?: string;
  contact_for?: string;
  main_address_attributes?: AddressAttributesType;
  second_address_attributes?: AddressAttributesType;
  practice_name?: string;
};

type UserType = {
  practice_contact_attributes: ContactAttributesType | {};
  patient_contact_attributes: ContactAttributesType;
  provider_accepts_new_patients: boolean;
  sign_up_stage: number;
};

type ContactInfoPayload = {
  user: Partial<UserType>;
};

const buildAddressAttributes = (contact_for, address_for, values) => {
  const suffix = {
    main: "",
    second: "_2",
  };

  const { same_as } = values;

  const {
    display_name,
    name,
    city,
    state,
    country,
    lonlat,
    id,
    _destroy,
    zip_code,
  } = values[`${contact_for}_address${suffix[address_for]}`] || {};

  const apartment = values[`${contact_for}_address_line${suffix[address_for]}`];
  const info_city = values[`${contact_for}_info_city${suffix[address_for]}`];
  const info_state = values[`${contact_for}_info_state${suffix[address_for]}`];

  let address: any = {
    address_for,
    apartment,
    city,
    country,
    display_name,
    info_city,
    info_state: info_state?.id,
    lonlat,
    name,
    same_as,
    state,
    zip_code,
  };

  if (display_name === "" && id && _destroy) {
    address = {
      id,
      _destroy,
    };
  }

  return address;
};

const buildContactAttributes = (contact_for, values) => {
  const contact = {
    contact_for,
    email: values[`${contact_for}_email`],
    website: values[`${contact_for}_website`],
    phone: values[`${contact_for}_phone_number`],
    id: values[`${contact_for}_address`]?.addressable_id,
  };

  return contact;
};

export const parseContactInfoRequest = (userInfo: any, step) => {
  const requestPayload: ContactInfoPayload = {
    user: {
      sign_up_stage: step + 1,
      provider_accepts_new_patients: userInfo.accept_new_patients,
      practice_contact_attributes: {
        ...buildContactAttributes("practice", userInfo),
        practice_name: userInfo.practice_name,
        main_address_attributes: {
          ...buildAddressAttributes("practice", "main", userInfo),
        },
      },
    },
  };

  const practiceAddress2 = userInfo.practice_address_2;

  if (requestPayload.user.practice_contact_attributes) {
    if (practiceAddress2 && !isObjectEmpty(practiceAddress2)) {
      requestPayload.user.practice_contact_attributes[
        // eslint-disable-next-line dot-notation
        "second_address_attributes"
      ] = buildAddressAttributes("practice", "second", userInfo);
    }
  }

  return requestPayload;
};

const INITIAL_VALUES_PATHS = {
  practice_website: "practice_contact.website",
  practice_name: "practice_contact.practice_name",
  practice_email: "practice_contact.email",
  practice_phone_number: "practice_contact.phone",
  practice_address: "practice_contact.main_address",
  practice_info_city: "practice_contact.main_address.info_city",
  practice_info_state: "practice_contact.main_address.info_state",
  practice_address_line: "practice_contact.main_address.apartment",
  practice_address_2: "practice_contact.second_address",
  practice_address_line_2: "practice_contact.second_address.apartment",
  accept_new_patients: "provider_accepts_new_patients",
};

export const addInitialValueToFields = (initialValues, fields): any[] => {
  if (!initialValues) {
    return fields;
  }

  const contactFields = fields.map((field) => {
    if (field.name === "practice_info_state") {
      const info_state = getPath(
        INITIAL_VALUES_PATHS[field.name],
        initialValues
      );
      return {
        ...field,
        initialValue: info_state ? statesById[info_state] : info_state,
      };
    }
    return {
      ...field,
      initialValue: getPath(INITIAL_VALUES_PATHS[field.name], initialValues),
    };
  });

  return contactFields;
};
