import React, { useEffect } from "react";
import HIHHModal from "components/common/HIHHModal";
import { Typography, useTheme } from "@mui/material";
import colors from "styles/colors";
import { close } from "./assets/icons";
import {
  ModalContainer,
  ImageContainer,
  ContentContainer,
  CloseButton,
} from "./NewsletterModal.styles";

interface NewsLetterModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const NewsLetterModal: React.FC<NewsLetterModalProps> = ({
  isOpen,
  onClose,
}) => {
  const theme = useTheme();

  useEffect(() => {
    if (isOpen) {
      const script = document.createElement("script");
      script.src = "//js.hsforms.net/forms/embed/v2.js";
      script.async = true;
      document.head.appendChild(script);
      script.onload = () => {
        // @ts-ignore
        window.hbspt.forms.create({
          region: "na1",
          portalId: process.env.REACT_APP_HUBSPOT_PORTAL_ID || "",
          formId: process.env.REACT_APP_HUBSPOT_NEWSLETTER_FORM_ID || "",
          target: "#hubspot-form",
        });
      };
    }
    return () => {
      const script = document.querySelector(
        "script[src='//js.hsforms.net/forms/embed/v2.js']"
      );
      if (script) {
        document.head.removeChild(script);
      }
      const hubspotFormContainer = document.getElementById("hubspot-form");
      if (hubspotFormContainer) {
        hubspotFormContainer.innerHTML = "";
      }
    };
  }, [isOpen, theme]);

  return (
    <HIHHModal open={isOpen} onClose={onClose}>
      <ModalContainer>
        <ContentContainer>
          <Typography
            variant="mk_h3"
            fontSize="32px"
            color={colors.CORAL[9]}
            marginBottom="16px"
            marginTop="16px"
            textAlign="center"
          >
            What’s Your Wellness Focus These Days?
          </Typography>
          <Typography
            marginBottom="14px"
            variant="mk_body2"
            color={colors.GREY[7]}
          >
            Let us know what health topics you care about, and we’ll make sure
            you get more of what matters to you!
          </Typography>
          <div id="hubspot-form" />
        </ContentContainer>
        <CloseButton onClick={onClose}>{close}</CloseButton>
        <ImageContainer />
      </ModalContainer>
    </HIHHModal>
  );
};

export default NewsLetterModal;
