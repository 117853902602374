import { TextField, useMediaQuery } from "@mui/material";
import { DesktopDatePicker, MobileDatePicker } from "@mui/x-date-pickers";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import React from "react";

type HIHHDateFieldProps = {
  error?: boolean;
  helperText?: string;
  label?: string;
  name: string;
  placeholder?: string;
  value: string;
  onChange: (event: any) => void;
  onBlur?: (event: any) => void;
};

const HIHHDateField: React.FC<HIHHDateFieldProps> = ({
  error,
  helperText,
  label = "Date",
  name,
  placeholder = "Date",
  value,
  onChange,
  onBlur,
}) => {
  const isMobile = useMediaQuery("(max-width:480px)");
  const DatePicker = isMobile ? MobileDatePicker : DesktopDatePicker;
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        disableFuture
        inputFormat="MM/dd/yyyy"
        label={label}
        onChange={onChange}
        value={value}
        renderInput={(params) => (
          <TextField
            fullWidth
            {...params}
            name={name}
            error={error}
            helperText={helperText}
            onBlur={() => onBlur && onBlur(value)}
            placeholder={placeholder}
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default HIHHDateField;
