import { Button, CircularProgress } from "@mui/material";
import { Container } from "components/styled";
import { FormikValues, useFormikContext } from "formik";
import React from "react";
import styled from "styled-components";
import colors from "styles/colors";

import { useOnboardingContext } from "./OnboardingContextProvider";

const NavigationContainer = styled.div`
  align-items: center;
  border-top: 1px solid ${colors.GREY[1]};
  background-color: ${colors.WHITE};
  display: flex;
  height: 96px;
  justify-content: center;
  width: 100%;
  z-index: 1;
  gap: 100px;
  overflow: hidden;

  @media all and (max-width: 480px) {
    height: 145px;
    gap: 0;
    flex-direction: column;
  }
`;

const ButtonsContainer = styled(Container)`
  max-width: 500px;
  justify-content: space-between;
  overflow: hidden;

  @media all and (max-width: 480px) {
    justify-content: center;
    gap: 8px;
  }
`;

const ControlButton = styled(Button)`
  width: 198px;

  @media all and (max-width: 480px) {
    width: 90%;
    max-width: 500px;

    &:last-child {
      order: -1;
    }
  }
`;

const NavigationControls = () => {
  const { goToPrevStep, step, user, isSubmitLoading } = useOnboardingContext();

  const { errors, submitForm, values } = useFormikContext<FormikValues>();

  let hasErrors = Object.keys(errors).length > 0;

  const providerOnPledgeScreen = user?.role === "provider" && step === 4;
  const memberOnMembershipScreen = user?.role === "member" && step === 3;

  if (providerOnPledgeScreen && !values?.agree_to_pledge) {
    hasErrors = true;
  }

  const onLastStep = providerOnPledgeScreen || memberOnMembershipScreen;
  const submitButtonText = onLastStep ? "Done" : "Save & Continue";

  return (
    <NavigationContainer>
      <ButtonsContainer>
        <ControlButton disabled={step === 1} onClick={goToPrevStep}>
          Go Back
        </ControlButton>
        <ControlButton
          disabled={hasErrors || isSubmitLoading}
          variant="contained"
          onClick={submitForm}
        >
          {isSubmitLoading ? (
            <CircularProgress size="20px" color="inherit" />
          ) : (
            submitButtonText
          )}
        </ControlButton>
      </ButtonsContainer>
    </NavigationContainer>
  );
};

export default NavigationControls;
