import { CONTENT_URL } from "routes/constant";

export const communityMenuItems = [
  { label: "Care Squads", route: "/virtual-care-squads" },
  { label: "Forum", route: "/community" },
];

export const contentMenuItems = [
  { label: "Carepoint", route: "/carepoint" },
  { label: "Library", route: CONTENT_URL },
  { label: "Events", route: `${CONTENT_URL}/events` },
];

export const PROVIDER_DIRECTORY_ROUTE = "/directory/providers";

export const connectionMenuItems = [
  { label: "Provider Directory", route: PROVIDER_DIRECTORY_ROUTE },
];
