import styled from "styled-components";

export const ModalContainer = styled.div`
  display: flex;
  width: 850px;
  height: 550px;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;

  @media all and (max-width: 950px) {
    flex-direction: column;
    padding: 10px;
    width: 450px;
    height: 550px;
  }

  @media all and (max-width: 500px) {
    flex-direction: column;
    width: 300px;
    height: 600px;
  }
`;

export const ImageContainer = styled.div`
  flex: 1;
  background-image: url("https://43841360.fs1.hubspotusercontent-na1.net/hubfs/43841360/2-3.png");
  background-size: cover;
  background-position: center;
  border-radius: 0 32px 32px 0;

  @media all and (max-width: 950px) {
    display: none;
  }
`;

export const ContentContainer = styled.div`
  flex: 1;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  @media all and (max-width: 950px) {
    justify-content: flex-start;
    overflow-y: auto;
    width: 80%;
  }

  @media all and (max-width: 500px) {
    justify-content: flex-start;
    overflow-y: auto;
    width: 70%;
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 16px;
  right: 16px;
  background-color: white;
  border-radius: 20px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    opacity: 0.8;
  }
`;
